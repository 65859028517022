<template>
  <el-container>
    <el-main
      class="text-center"
      @mouseover="$store.state.LeftBarContentShow = false"
    >
      <div class="container" style="padding: 0" id="top">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            :to="{ path: '/ChargeMeContactBook/ClassRoomCenter' }"
          >
            課程選單
          </el-breadcrumb-item>
          <el-breadcrumb-item>{{ classRoomName }}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="contactBookName != ''">
            {{ contactBookName }}</el-breadcrumb-item
          >
        </el-breadcrumb>
        <el-tabs
          v-model="$store.state.classRoomTabActiveName"
          @tab-click="handleClick"
        >
          <el-tab-pane name="zero">
            <template v-slot:label>
              <i class="fal fa-info-circle me-1"></i>
              <span class="text-dark">課程資訊</span>
            </template>
            <ClassRoomInfo :classRoomPermission="classRoomPermission" />
          </el-tab-pane>
          <el-tab-pane name="first">
            <template v-slot:label>
              <i class="fal fa-books me-1"></i>
              <span class="text-dark">聯絡簿</span>
            </template>
            <ClassRoomContactBook
              ref="classRoomContactBook"
              :classRoomPermission="classRoomPermission"
              @changeContactBookName="changeContactBookName"
            />
          </el-tab-pane>
          <el-tab-pane name="second">
            <template v-slot:label>
              <i class="fal fa-sticky-note me-1"></i>
              <span class="text-dark">記事本</span>
            </template>
            <ClassRoomArticle />
          </el-tab-pane>
          <el-tab-pane name="third">
            <template v-slot:label>
              <i class="fal fa-users me-1"></i>
              <span class="text-dark">成員</span>
            </template>
            <ClassRoomMember
              @rerenderClassRoom="initMemberData"
              :isPrimaryTeacher="isPrimaryTeacher"
            />
          </el-tab-pane>
          <el-tab-pane name="fourth">
            <template v-slot:label>
              <i class="fal fa-map-marker-check me-1"></i>
              <span class="text-dark">打卡與繳費</span>
            </template>
            <ClassRoomPayCheck :classRoomPermission="classRoomPermission" />
          </el-tab-pane>
          <el-tab-pane
            name="fifth"
            v-if="classRoomRole == 'Teacher' || classRoomRole == 'Owner'"
          >
            <template v-slot:label>
              <i class="fa-regular fa-gear me-1"></i>
              <span class="text-dark">設定</span>
            </template>
            <ClassRoomSetting
              :isPrimaryTeacher="isPrimaryTeacher"
              @changeIsContactTodoCompleteCheckedSettingOn="
                changeIsContactTodoCompleteCheckedSettingOn
              "
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import ClassRoomPayCheck from "../../../components/ChargeMeContactBook/ClassRoom/ClassRoomMainInfo/ClassRoomPayCheck.vue";
import ClassRoomSetting from "../../../components/ChargeMeContactBook/ClassRoom/ClassRoomMainInfo/ClassRoomSetting.vue";
import ClassRoomMember from "../../../components/ChargeMeContactBook/ClassRoom/ClassRoomMainInfo/ClassRoomMember.vue";
import ClassRoomInfo from "../../../components/ChargeMeContactBook/ClassRoom/ClassRoomMainInfo/ClassRoomInfo.vue";
import ClassRoomArticle from "../../../components/ChargeMeContactBook/ClassRoom/ClassRoomMainInfo/ClassRoomArticle.vue";
import ClassRoomContactBook from "../../../components/ChargeMeContactBook/ClassRoom/ClassRoomMainInfo/ClassRoomContactBook.vue";
import Storage from "../../../models/storage";

export default {
  name: "ClassRoom",
  components: {
    ClassRoomPayCheck,
    ClassRoomSetting,
    ClassRoomMember,
    ClassRoomInfo,
    ClassRoomArticle,
    ClassRoomContactBook,
  },

  computed: {
    breadCrumb() {
      if (Storage.get("breadcrumb_from").first == undefined)
        return "ClassRoomCenter";
      return Storage.get("breadcrumb_from").first;
    },
  },
  watch: {
    "$store.state.classRoomTabActiveName": function (newVal) {
      if (newVal != "first") this.contactBookName = "";
    },
    // $route: function () {
    //   let vm = this;
    //   if (vm.$route.name == "ChargeMeContactBook_ContactBookView") {
    //     vm.$store.state.classRoomTabActiveName = "first";
    //   }
    // },
  },
  data() {
    return {
      classRoomName: "",
      classRoomRole: "",
      isPrimaryTeacher: false,
      classRoomPermission: [],
      contactBookName: "",
      isContactTodoCompleteCheckedSettingOn: false,
    };
  },
  created() {
    const vm = this;
    vm.getClassRoomName();
    vm.initMemberData();
  },
  methods: {
    changeContactBookName(value) {
      this.contactBookName = value;
    },
    getClassRoomName() {
      const vm = this;
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/Info?classRoomId=${vm.$route.params.id}`
      )
        .then((res) => {
          vm.classRoomName = res.data.classRoomName;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    initMemberData() {
      const vm = this;
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/Member/${vm.$route.params.id}`
      )
        .then((res) => {
          var studentOptions = [];

          res.data.forEach((x) => {
            if (x.role == "Student") {
              studentOptions.push({
                label: vm.getMemberName(x.memberId, x.name),
                value: x.memberId,
                avatar: x.avatar,
              });
            }

            x.name = vm.getMemberName(x.memberId, x.name);
            if (x.parents != null) {
              x.parents.forEach((y) => {
                y.name = vm.getMemberName(y.memberId, y.name);
                if (y.memberId == vm.currentUser().id) {
                  Storage.set("classroom_role", {
                    role: y.role,
                    permission: y.permission,
                  });
                }
              });
            }

            if (x.memberId == vm.currentUser().id) {
              Storage.set("classroom_role", {
                role: x.role,
                permission: x.permission,
              });
              vm.classRoomPermission = x.permission;
              vm.classRoomRole = x.role;
              if (x.role == "Teacher") vm.isPrimaryTeacher = x.isPrimaryTeacher;
            }
          });
          vm.$store.state.workItem.studentOptions = studentOptions;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeIsContactTodoCompleteCheckedSettingOn(value) {
      this.$refs.classRoomContactBook.changeSetting(value);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  text-decoration-color: white;
}
body > .el-container {
  margin-bottom: 40px;
}
.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}
.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
