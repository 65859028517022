<template>
  <el-skeleton class="mt-2" v-if="!isLoaded" :rows="6" animated />
  <div v-else class="m-0">
    <el-row :gutter="20" class="mt-0">
      <el-col :span="24">
        <h5
          v-if="studentAssignee.length > 0 && $route.params.role != 'Student'"
          class="contactBookName"
        >
          <div class="bg-white left-button">
            <el-button class="m-0" link size="small" @click="handleTabLeft"
              ><i class="fa-solid fa-left"></i
            ></el-button>
          </div>
          <div class="bg-white right-button">
            <el-button class="m-0" link size="small" @click="handleTabRight"
              ><i class="fa-solid fa-right"></i
            ></el-button>
          </div>
          <div class="student-assignee-container" ref="container">
            <el-button
              size="small"
              class="mx-1 studentBtn"
              link
              type=""
              v-for="item in studentAssignee"
              :key="item.value"
              @click="scrollToCenter($event, item.value)"
            >
              <div class="d-flex flex-column align-items-center">
                <el-avatar
                  class="mb-1"
                  :class="{
                    'border border-primary border-2':
                      studentAssigneeId == item.value,
                  }"
                  :size="medium"
                  :src="item.avatar"
                ></el-avatar>
                <p>{{ item.name }}</p>
              </div>
            </el-button>
          </div>
        </h5>
        <h5
          v-else-if="
            ($route.params.role == 'Teacher' ||
              $route.params.role == 'Parent') &&
            studentAssignee.length == 0
          "
        >
          目前尚無分派作業給學生
        </h5>

        <div class="d-flex">
          <el-button
            v-if="$route.params.role == 'Teacher'"
            size="small"
            class="px-2"
            @click="toContactBook()"
            >回編輯</el-button
          >
        </div>
        <div
          class="d-flex mt-2"
          v-if="
            studentAssigneeId != 'all' &&
            (userRole == 'Teacher' || userRole == 'Owner')
          "
        >
          <el-checkbox
            class="me-2"
            size="small"
            @change="signContactBook(true)"
            :disabled="
              isTeacherSign || (userRole != 'Teacher' && userRole != 'Owner')
            "
            v-model="isTeacherSign"
            label="老師簽章"
            border
          />
          <el-checkbox
            class="me-2"
            size="small"
            @change="signContactBook(false)"
            :disabled="isParentSign || userRole != 'Parent'"
            v-model="isParentSign"
            label="家長簽章"
            border
          />
        </div>
        <div
          class="d-flex mt-2"
          v-if="
            userRole != 'Teacher' &&
            userRole != 'Owner' &&
            (filterCurrentTodo == undefined || filterCurrentTodo == '')
          "
        >
          <el-checkbox
            size="small"
            class="me-2"
            @change="signContactBook(true)"
            :disabled="
              isTeacherSign || (userRole != 'Teacher' && userRole != 'Owner')
            "
            v-model="isTeacherSign"
            label="老師簽章"
            border
          />
          <el-checkbox
            size="small"
            class="me-2"
            @change="signContactBook(false)"
            :disabled="isParentSign || userRole != 'Parent'"
            v-model="isParentSign"
            label="家長簽章"
            border
          />
        </div>

        <el-alert
          class="mt-2"
          v-if="displayData.length == 0"
          title="目前尚無聯絡事項...."
          type="warning"
        >
        </el-alert>
        <div v-else class="taskCardPage">
          <div v-for="item in displayData" :key="item.id" class="taskCard">
            <h3 class="taskTitle">
              <el-checkbox
                v-if="
                  studentAssigneeId != 'all' && $route.params.role == 'Teacher'
                "
                @change="changeIsDone(item)"
                v-model="
                  item.assigneeList.find((x) => x.memberId == studentAssigneeId)
                    .isDone
                "
                class="me-2"
              />
              <el-checkbox
                v-else-if="$route.params.role == 'Parent'"
                @change="changeIsDone(item)"
                v-model="
                  item.assigneeList.find((x) => x.memberId == studentAssigneeId)
                    .isDone
                "
                class="me-2"
              />
              <el-checkbox
                v-else-if="
                  $route.params.role != 'Teacher' && userRole != 'Parent'
                "
                @change="changeIsDone(item)"
                v-model="item.isDone"
                class="me-2"
              />
              <i v-if="item.isNecessary" class="fas fa-bookmark me-2" style="color: #96beab"></i> 

              <p class="todoName">{{ item.title }}</p>
            </h3>
            <span
              v-if="validDuedate(item.dueDate)"
              class="ms-auto d-block text-start"
              style="font-size: small; font-weight: normal"
            >
              <i class="far fa-bell me-1"></i>
              <span class="me-1">到期日:</span>
              <span> {{ validDuedate(item.dueDate) ? item.dueDate : "" }}</span>
            </span>
            <div class="taskContent">
              <el-descriptions
                style="padding: 0 0.5rem"
                v-if="item.assignee.length > 0"
                size="small"
                column="2"
                class="mt-2"
              >
                <el-descriptions-item
                  :span="2"
                  label="學生："
                  v-if="
                    $route.params.role == 'Teacher' &&
                    studentAssigneeId == 'all'
                  "
                  class="p-0"
                >
                  <el-tag
                    class="me-1"
                    size="small"
                    v-for="assignee in item.assignee"
                    :key="assignee.value"
                    >{{ assignee.name }}</el-tag
                  >
                </el-descriptions-item>
              </el-descriptions>
              <div
                style="padding: 0.5rem; overflow: auto"
                v-if="item.content != ''"
                v-html="item.content"
              ></div>
              <template v-if="item.fileList.length > 0">
                <div
                  v-for="fileList in item.fileList"
                  :key="fileList.id"
                  class="me-2 my-2 d-flex justify-content-between align-items-center"
                  style="padding: 0 0.5rem"
                >
                  <i
                    class="fad fa-file me-2"
                    v-if="
                      !IsImageFile(fileList.name) && !IsVideoFile(fileList.name)
                    "
                    style="
                      --fa-primary-color: #398d69;
                      --fa-secondary-color: #9cbdab;
                      --fa-secondary-opacity: 1;
                    "
                  ></i>
                  <el-image
                    class="me-auto"
                    v-if="IsImageFile(fileList.name)"
                    style="width: 200px"
                    :src="fileList.url"
                    :preview-src-list="[fileList.url]"
                  >
                  </el-image>

                  <VideoPlayer
                    class="me-auto"
                    v-else-if="IsVideoFile(fileList.name)"
                    :src="fileList.url"
                    controls
                    :loop="true"
                    width="200"
                  />

                  <el-link
                    v-else
                    class="me-auto"
                    @click="previewFile(fileList)"
                    >{{ fileList.name }}</el-link
                  >
                  <div>
                    <i
                      class="fa-regular fa-folder-arrow-down downloadFileIcon me-3"
                      @click="downloadFile(fileList)"
                    ></i>
                    <i
                      class="fa-regular fa-circle-xmark removeFileIcon"
                      @click="removeFile(fileList, item)"
                    ></i>
                  </div>
                </div>
              </template>
              <template v-if="item.linkList.length > 0">
                <div
                  class="my-2"
                  style="padding: 0 0.5rem"
                  v-for="linkList in item.linkList"
                  :key="linkList.id"
                >
                  <i
                    class="fad fa-external-link-square"
                    style="
                      --fa-primary-color: #2474ff;
                      --fa-secondary-color: #6ba1ff;
                      --fa-secondary-opacity: 0.4;
                    "
                  ></i>
                  <el-link
                    type="primary"
                    :href="linkList.url"
                    target="_blank"
                    >{{ linkList.title }}</el-link
                  >
                </div>
              </template>
            </div>
            <ClassRoomContactMessage
              :classRoomId="classRoomId"
              :memberOptions="memberOptions"
              :contactTodoId="item.id"
              :memberId="c_currentUser.id"
              :studentAssigneeId="
                studentAssigneeId == 'all' ? null : studentAssigneeId
              "
              :getAll="
                studentAssigneeId == 'all' &&
                (userRole == 'Teacher' || userRole == 'Owner')
                  ? true
                  : false
              "
              class="py-2"
              collapse=""
              type="ContactTodo"
            />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ClassRoomContactMessage from "../ClassRoomContactMessage.vue";
import Storage from "../../../../models/storage";
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";

export default {
  name: "ContactBookView",
  components: {
    ClassRoomContactMessage,
    VideoPlayer,
  },
  watch: {
    "$route.params.contactBookId": function (value) {
      let vm = this;
      if (value != null || value != undefined) {
        vm.initStudentData();
        //  vm.initContactTodoData();
      }
    },
  },
  data() {
    return {
      parentStudentIds: [],
      classRoomId: "",
      classRoomName: "",
      contactBookName: "",
      tasks: [],
      memberOptions: [],
      studentAssignee: [],
      studentAssigneeId: "all",
      teacherSignedList: [],
      parentSignedList: [],
      isTeacherSign: false,
      isParentSign: false,
      studentId: 0,
      isLoaded: false,
    };
  },
  mounted() {
    let vm = this;
    vm.initStudentData();
    vm.initData();
  },
  computed: {
    c_currentUser() {
      return this.currentUser();
    },
    visible() {
      return (
        this.$route.name == "ChargeMeContactBook_ContactBookViewById" ||
        this.$route.name == "ChargeMeContactBook_ContactBookView"
      );
    },

    studentAssigneeOption() {
      if (this.$route.params.role != "Parent") {
        return this.studentAssignee;
      } else {
        return this.studentAssignee.filter((x) =>
          this.parentStudentIds.includes(x.value)
        );
      }
    },
    breadCrumb() {
      if (Storage.get("breadcrumb_from").first == undefined)
        return "ClassRoomCenter";
      return Storage.get("breadcrumb_from").first;
    },
    displayData() {
      return this.tasks.filter((x) =>
        x.assigneeListId.includes(Number(this.studentAssigneeId))
      );
      // if (this.filterCurrentTodo != undefined && this.filterCurrentTodo != "") {
      //   return this.tasks.filter((x) => x.id == this.filterCurrentTodo);
      // }
      // else {
      // var result = this.tasks.filter((x) =>
      //   x.assigneeListId.includes(this.studentAssigneeId)
      // );
      // return result;
      // }
    },
    filterCurrentTodo() {
      return this.$route.params.todoId;
    },
    userRole() {
      return this.$route.params.role;
    },
    studentOptions() {
      return this.$store.state.workItem.studentOptions;
    },
  },
  methods: {
    IsVideoFile(fileName) {
      const videoExtensions = /\.(mp4|avi|mov|mkv|wmv|flv)$/i;
      if (videoExtensions.test(fileName)) {
        return true;
      } else {
        return false;
      }
    },
    IsImageFile(fileName) {
      const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i;
      if (imageExtensions.test(fileName)) {
        return true;
      } else {
        return false;
      }
    },
    scrollToCenter(event, value) {
      let vm = this;
      this.studentAssigneeId = value;
      const container = this.$refs.container;
      const button = event.currentTarget;
      const containerWidth = container.offsetWidth;
      const buttonWidth = button.offsetWidth;
      const buttonOffsetLeft = button.offsetLeft;
      const scrollPosition =
        buttonOffsetLeft - containerWidth / 2 + buttonWidth / 2;

      container.scroll({
        left: scrollPosition,
        behavior: "smooth",
      });

      if (vm.userRole == "Parent") {
        vm.studentId = vm.studentAssigneeId;
      }

      vm.isTeacherSign = false;
      vm.isParentSign = false;
      if (vm.teacherSignedList.includes(vm.studentAssigneeId))
        vm.isTeacherSign = true;
      if (vm.parentSignedList.includes(vm.studentAssigneeId))
        vm.isParentSign = true;
    },
    toContactBook() {
      const vm = this;
      vm.$router.push({
        name: "ChargeMeContactBook_ContactBook",
        params: {
          id: vm.$route.params.id,
          role: "Teacher",
          contactBookId: vm.$route.params.contactBookId,
        },
      });
    },
    previewFile(file) {
      if (file.response == undefined) {
        window.open(file.url);
      } else {
        window.open(file.response.url);
      }
    },
    removeFile(file, item) {
      let vm = this;
      //axios
      vm.fetchAPI(
        "delete",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/${item.id}/File?contactTodoFileId=${file.id}`
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "刪除成功",
          });
          var temp = vm.tasks.find((x) => x.id == item.id);
          temp.fileList = temp.fileList.filter((x) => x.id != file.id);
        })
        .catch((err) => {
          console.log(err);
          vm.notify("error", "刪除失敗！");
        });
    },
    downloadFile(file) {
      console.log(file);
      if (file["response"] != undefined) file = file.response;
      this.axios
        .get(file.url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: response.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    validDuedate(dateString) {
      if (dateString == null) return false;
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateRegex.test(dateString)) {
        return false;
      }
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return false;
      }
      return true;
    },
    signContactBook(isTeacher) {
      const vm = this;
      let data = {
        ContactBookId: vm.$route.params.contactBookId,
        StudentId:
          vm.userRole == "Student" || vm.userRole == "Parent"
            ? vm.studentId
            : vm.studentAssigneeId,
        IsTeacher: isTeacher,
      };
      vm.fetchAPI(
        "put",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/Sign`,
        data
      )
        .then((res) => {
          console.log(res.data);
          vm.notify("success", `簽名完成！`);
          if (isTeacher) vm.teacherSignedList.push(data.StudentId);
          else vm.parentSignedList.push(data.StudentId);
        })
        .catch((err) => {
          console.log(err);
          vm.notify("error", `簽名失敗！`);
        });
    },
    backToContactBook() {
      const vm = this;
      console.log(vm.$route.params);
      vm.$router.push({
        name: "ChargeMeContactBook_ContactBook",
        params: {
          contactBookId: vm.$route.params.contactBookId,
        },
      });
    },
    handleTabLeft() {
      let vm = this;
      let datas = this.studentAssignee;
      if (vm.$route.params.role == "Parent") {
        datas = this.studentAssignee.filter((x) =>
          vm.parentStudentIds.includes(x.value)
        );
      }

      var index = datas.findIndex((x) => x.value == this.studentAssigneeId) - 1;
      if (index < 0) {
        return;
      } else {
        this.studentAssigneeId = datas[index].value;
        let buttons = document.querySelectorAll(".studentBtn");
        let button = buttons[index];
        let event = { currentTarget: button };
        this.scrollToCenter(event, this.studentAssigneeId);
      }
    },
    handleTabRight() {
      let vm = this;

      let datas = this.studentAssignee;
      if (vm.$route.params.role == "Parent") {
        datas = this.studentAssignee.filter((x) =>
          vm.parentStudentIds.includes(x.value)
        );
      }
      var index = datas.findIndex((x) => x.value == this.studentAssigneeId) + 1;
      if (index == datas.length) return;
      else {
        this.studentAssigneeId = datas[index].value;
        let buttons = document.querySelectorAll(".studentBtn");
        let button = buttons[index];
        let event = { currentTarget: button };
        this.scrollToCenter(event, this.studentAssigneeId);
      }
    },
    handleTabClick(tab) {
      const vm = this;
      vm.isTeacherSign = false;
      vm.isTeacherSign = false;

      if (tab.props.name == "all") vm.studentAssigneeId = "all";
      else vm.studentAssigneeId = tab.props.name;

      if (vm.teacherSignedList.includes(vm.studentAssigneeId))
        vm.isTeacherSign = true;
      if (vm.parentSignedList.includes(vm.studentAssigneeId))
        vm.isParentSign = true;
    },
    // 路由名稱
    initData() {
      const vm = this;
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/RouteName/${vm.$route.params.contactBookId}`,
        {},
        false
      )
        .then((res) => {
          // console.log(res.data);
          vm.classRoomId = res.data.classRoomId;
          vm.classRoomName = res.data.classRoomName;
          vm.contactBookName = res.data.contactBookName;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 聯絡簿 - 待辦事項
    initContactTodoData() {
      const vm = this;
      this.isLoaded = false;
      vm.tasks = [];
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/${vm.$route.params.contactBookId}`,
        {},
        false
      )
        .then((res) => {
          vm.parentStudentIds = res.data.parentStudentIds;
          if (vm.userRole == "Parent") {
            res.data.contactTodoList = res.data.contactTodoList.filter((x) =>
              res.data.parentStudentIds.some((y) =>
                x.assigneeListId.includes(y)
              )
            );
          }
          vm.teacherSignedList = res.data.teacherSignedList;
          vm.parentSignedList = res.data.parentSignedList;
          if (vm.userRole == "Student") {
            if (vm.teacherSignedList.includes(Number(vm.currentUser().id)))
              vm.isTeacherSign = true;
            if (vm.parentSignedList.includes(Number(vm.currentUser().id)))
              vm.isParentSign = true;
            vm.studentId = vm.currentUser().id;
          } else if (vm.userRole == "Teacher") {
            if (vm.teacherSignedList.includes(vm.studentAssigneeId))
              vm.isTeacherSign = true;
            if (vm.parentSignedList.includes(vm.studentAssigneeId))
              vm.isParentSign = true;
          }
          if (res.data.length != 0) {
            // vm.studentAssignee.push({ value: "all", name: "全部", avatar: "" });

            res.data.contactTodoList.forEach((x) => {
              var assignee = x.assigneeList.find(
                (x) => x.memberId == vm.currentUser().id
              );
              if (vm.userRole == "Parent") {
                // console.log(vm.memberOptions);
                vm.memberOptions.forEach((y) => {
                  if (y.parents != null && y.parents.length != 0)
                    y.parents.forEach((z) => {
                      if (z.memberId == vm.currentUser().id) {
                        assignee = y;
                        return;
                      }
                    });
                });
                if (vm.teacherSignedList.includes(assignee.memberId))
                  vm.isTeacherSign = true;
                if (vm.parentSignedList.includes(assignee.memberId))
                  vm.isParentSign = true;
                vm.studentId = assignee.memberId;
              }

              if (vm.userRole == "Parent" && vm.parentStudentIds.length > 0) {
                vm.studentAssigneeId = vm.parentStudentIds[0];
              }
              if (vm.userRole != "Teacher" && !assignee) return;

              var temp = {
                id: x.id,
                title: x.title,
                order: x.order,
                content: x.content,
                checked: x.checked,
                startDate: x.startDate,
                dueDate: x.dueDate == null ? "" : x.dueDate.split("T")[0],
                createdUser: x.createdUser,
                linkList: x.linkList,
                fileList: x.fileList,
                assigneeList: x.assigneeList,
                assigneeListId: x.assigneeListId,
                isDone: assignee == undefined ? false : assignee.isDone,
                assigneeId: assignee == undefined ? "" : assignee.id,
                isNecessary :x.isNecessary
              };
              var assigneeListIdTemp = [];
              temp.assigneeListId.forEach((y) => {
                if (vm.studentOptions.find((z) => z.value == y) != undefined) {
                  var list = {
                    value: y,
                    name: vm.studentOptions.find((z) => z.value == y).label,
                    avatar: vm.studentOptions.find((z) => z.value == y).avatar,
                  };
                  if (
                    vm.studentAssignee.find((x) => x.value == list.value) ==
                    undefined
                  )
                    vm.studentAssignee.push(list);
                  if (
                    assigneeListIdTemp.find((x) => x.value == list.value) ==
                    undefined
                  )
                    assigneeListIdTemp.push(list);
                }
              });
              temp.assignee = assigneeListIdTemp;
              vm.tasks.push(temp);
            });

            if (vm.userRole == "Student") {
              vm.studentAssignee = vm.studentAssignee.filter(
                (x) => x.value == vm.currentUser().id
              );
              vm.studentAssigneeId = vm.currentUser().id;
            }
            if (vm.userRole == "Teacher" && vm.studentAssignee.length > 0) {
              vm.studentAssigneeId = vm.studentAssignee[0].value;
            }
            if (vm.userRole == "Parent" && vm.studentAssignee.length > 0) {
              vm.studentAssignee = vm.studentAssignee.filter((x) =>
                vm.parentStudentIds.includes(x.value)
              );
              vm.studentAssigneeId = vm.studentAssignee[0].value;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },
    // 學生
    initStudentData() {
      const vm = this;
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/Member/${vm.$route.params.id}`,
        {},
        false
      )
        .then((res) => {
          vm.memberOptions = res.data;
          vm.initContactTodoData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeIsDone(item) {
      const vm = this;
      var id = 0;
      var isDone = false;
      if (
        vm.$route.params.role == "Teacher" ||
        vm.$route.params.role == "Parent"
      ) {
        id = item.assigneeList.find(
          (x) => x.memberId == vm.studentAssigneeId
        ).id;
        isDone = item.assigneeList.find(
          (x) => x.memberId == vm.studentAssigneeId
        ).isDone;
      } else {
        id = item.assigneeId;
        isDone = item.isDone;
      }
      vm.fetchAPI(
        "put",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/IsDone?assigneeId=${id}&isDone=${isDone}`
      )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `更新失敗！`);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.left-button {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  // border-right: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 4px;
  padding-right: 4px;
}
.right-button {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  height: 100%;
  //border-left: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 4px;
  padding-right: 4px;
}
.student-assignee-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0 1.5rem 0 1.5rem;
  margin-top: 0.5rem;
}
.student-assignee-container ::v-deep .el-button {
  flex-shrink: 0; /* Prevent buttons from shrinking */
  margin-right: 8px; /* Optional: space between buttons */
}
.contactBookName {
  position: relative;

  text-align: left;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}
.el-tag {
  cursor: pointer;
}
:deep .el-tabs {
  overflow: auto;
  .el-tabs__item {
    &.is-active {
      background-color: #caeba1 !important;
    }
  }
}
.taskTitle {
  text-align: start;
  font-size: large;
  font-weight: 600;
  padding: 0.5rem;
  padding-left: 0;
  display: flex;
  align-items: center;

  .todoName {
    color: #6f6c63;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    word-wrap: break-word;
  }
}
.taskContent {
  text-align: start;
}
.taskCard {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.removeFileIcon,
.downloadFileIcon {
  cursor: pointer;
  &:hover {
    color: #2c2c2c;
  }
}
@media screen and (min-width: 768px) {
}
</style>
