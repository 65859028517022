<template>
  <context-menu v-model:show="show" :options="optionsComponent">
    <context-menu-item v-if="node.level != 4" label="新增" @click="appendItem" />
    <context-menu-item label="共享" />
    <context-menu-item label="移至" />
    <context-menu-item label="重新命名" @click="changeItemName" />
    <context-menu-sperator />
    <context-menu-item label="加入範本" @click="addTemplate" />
    <context-menu-item label="建立副本" @click="addCopy" />
    <context-menu-sperator />
    <context-menu-item label="封存" @click="saveItem" />
    <context-menu-item label="移除" @click="removeItem" />
  </context-menu>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      optionsComponent: {
        zIndex: 3,
        minWidth: 230,
        x: 0,
        y: 0,
      },
      node: null,
      tempData: null,
    };
  },
  methods: {
    onButtonClick(e, node, data) {
      // console.log(node);
      this.node = node;
      this.tempData = data;
      e.preventDefault();
      this.show = true;
      this.optionsComponent.x = e.x;
      this.optionsComponent.y = e.y;
    },
    changeItemName() {
      this.$emit("changeName", this.node, this.tempData);
    },
    appendItem() {
      this.$emit("append", this.node, this.tempData);
    },
    removeItem() {
      this.$emit("remove", this.node, this.tempData);
    },
  },
};
</script>

<style lang="scss" scoped>
.mx-context-menu-item-wrapper {
  cursor: pointer;
}
</style>
