<template>
  <div class="divBg" v-if="openArticle" @click="updateArticle()"></div>
  <div
    v-if="setEditArticleWin"
    class="EditWinOverlay"
    @click="
      resetArticleModel();
      addArticle = false;
      setEditArticleWin = false;
    "
  ></div>
  <el-row :gutter="20">
    <el-col :lg="20" :md="24" class="mx-auto">
      <el-card
        v-if="$store.state.selectTagId != 'trash'"
        class="AddArticleArea mb-2 main_card mx-2 overflow-hidden"
        :class="{
          EditWin: setEditArticleWin,
          focusWarning: focusWarning,
          'mx-auto': setEditArticleWin,
          'ms-auto': !setEditArticleWin,
        }"
        style="border-radius: 16px"
        shadow="always"
        :style="{
          'max-width': setEditArticleWin && $store.getters.mobileSize ? '90vw' : filteredData.length == 0 ? '' : '600px',
          overflow: 'inherit',
          border: !setEditArticleWin ? 'none' : '',
          'border-top': addArticleInfo.bgColor != '' && setEditArticleWin ? `5px  solid  ${addArticleInfo.bgColor}` : 'none',
          'box-shadow': !setEditArticleWin ? 'none' : '',
        }"
      >
        <div v-if="addArticle">
          <div class="text-start AddArticleTitle p-3">
            <input class="w-100 AddArticleTitleInput mb-4" type="text" placeholder="標題..." v-model="addArticleInfo.title" @blur="updateArticle" />
            <div style="max-height: 200px; overflow-y: auto">
              <template v-for="t in addArticleInfo.todoItems" :key="t">
                <div class="d-flex align-items-center pe-2 py-1">
                  <el-checkbox name="type" v-model="t.checked" @change="updateArticleTodo(t)" />
                  <input class="AddArticleTitleInput mx-2" :id="'todo_' + t.articleTodoId" v-model="t.title" type="text" @blur="updateArticleTodo(t)" />
                  <span v-if="t.file == null && t.link == null && t.content == null"></span>
                  <span v-else-if="(t.file != null && t.file.length > 0) || (t.link != null && t.link.length > 0) || t.content != ''" class="ms-auto mx-2">
                    <i class="fad fa-comment-alt-edit fa-lg" style="--fa-primary-color: rgb(62, 62, 224); --fa-secondary-color: #f5f5f5; --fa-secondary-opacity: 1"></i>
                  </span>
                  <div class="d-flex">
                    <span @click="deleteTodoItem(t)" class="ArticleBtn ArticleBtn-sm me-1">
                      <i class="fal fa-times"></i>
                    </span>
                    <span @click="setTodoItemDialog(t)" class="ArticleBtn ArticleBtn-sm">
                      <i class="fal fa-angle-right"></i>
                    </span>
                  </div>
                </div>
              </template>
            </div>
            <div class="d-flex align-items-center mb-2 ArticleCheckBox">
              <i class="fal fa-plus me-2"></i>
              <input class="w-100 AddArticleTitleInput" type="text" placeholder="清單項目" @keyup="inputfirst" id="createArticle" v-model="articleTitleInput" />
            </div>
            <div v-if="!assigneeArticle">
              <el-avatar class="me-1 border" v-for="a in addArticleInfo.assignee" :title="a.email" :key="a" size="mini" :src="a.avatar"></el-avatar>
            </div>
            <div class="tagsArea text-start" v-if="addArticleInfo.tags != null && addArticleInfo.tags != undefined">
              <el-tag v-for="item in addArticleInfo.tags" :key="item" size="small">
                {{ getUserTagsName(item) }}
              </el-tag>
            </div>
          </div>
          <div v-if="assigneeArticle" class="text-start px-2 pb-2">
            <div class="border p-2 rounded bg-light">
              <h3 class="mb-1 pb-1 border-bottom">協作者</h3>
              <ul class="list-group list-group-flush">
                <li v-for="item in addArticleInfo.assignee" :key="item" class="list-group-item text-start d-flex align-items-center justify-content-between px-1 bg-light">
                  <div class="d-flex align-items-center w-100">
                    <input type="hidden" :value="item.id" />
                    <el-avatar size="medium" :src="item.avatar" class="me-1"></el-avatar>
                    <div class="me-auto">
                      <p class="ms-3">{{ item.name }}</p>
                      <p class="ms-3">{{ item.email }}</p>
                    </div>
                    <span
                      @click="
                        deleteTodoAssignee(item);
                        updateArticle();
                      "
                      class="ArticleBtn ArticleBtn-sm"
                    >
                      <i class="fal fa-times"></i>
                    </span>
                  </div>
                </li>
              </ul>
              <div class="mt-1 d-flex align-items-center">
                <el-button class="me-1" @click="autocomplete_selectAll">全部加入</el-button>
                <el-autocomplete popper-class="my-autocomplete" :disabled="isReadOnlySelectedArticleAssignee" class="inline-input me-2" :fetch-suggestions="querySearchByClassMember" v-model="articleAssigneeInput" placeholder="請輸入帳號" :trigger-on-focus="true" @select="handleSelectArticleAssignee">
                  <template v-slot="{ item }">
                    <div class="w-100 d-flex align-items-center py-2">
                      <el-avatar size="small" :src="item.avatar" />
                      <div class="ms-2">
                        <div style="line-height: normal">
                          <span style="font-size: x-small" v-if="item.role.toLowerCase() == 'teacher'">老師</span>
                          <span style="font-size: x-small" v-if="item.role.toLowerCase() == 'student'">學生</span>
                          <span style="font-size: x-small" v-if="item.role.toLowerCase() == 'parent'">家長</span>
                          <span class="d-block">{{ item.name }}</span>

                          <span>{{ item.email }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-autocomplete>
                <el-button size="small" type="danger" plain @click="cancelArticleAutoComplete">取消</el-button>
                <el-button
                  :disabled="selectedArticleAssignee == null"
                  size="small"
                  type="primary"
                  plain
                  @click="
                    setArticleAssignee();
                    updateArticle();
                  "
                  >加入</el-button
                >
              </div>
            </div>
          </div>

          <div v-if="!assigneeArticle" class="d-flex align-items-center article_btn_area px-3">
            <div class="me-1">
              <div class="d-inline">
                <div v-if="openAssigned" class="position-absolute card" style="z-index: 30"></div>
              </div>
              <div class="d-inline">
                <span class="ArticleBtn ArticleBtn-sm" @click.stop="openArticle = !openArticle">
                  <i class="fal fa-palette"></i>
                </span>
                <div v-if="openArticle" class="position-absolute card" style="z-index: 200; bottom: 50px">
                  <div class="p-1 border-bottom d-flex">
                    <div
                      @click="
                        addArticleInfo.bgColor = c;
                        updateArticle();
                        openArticle = false;
                      "
                      v-for="c in predefineColors"
                      :key="c"
                      class="circleDiv me-1"
                      :style="{ 'background-color': c }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <span class="ArticleBtn ArticleBtn-sm me-1" @click="assigneeArticle = true">
              <i class="fal fa-user-plus"></i>
            </span>

            <span
              class="ArticleBtn ArticleBtn-sm me-1"
              @click="
                selectArticle = addArticleInfo;
                dialogAddTag = true;
              "
            >
              <i class="fal fa-tag"></i>
            </span>
            <span class="ArticleBtn ArticleBtn-sm" @click.stop="deleteArticle(addArticleInfo)">
              <i class="fal fa-trash"></i>
            </span>
          </div>
        </div>
        <div v-else-if="filteredData.length == 0" @click="addArticleFunc">
          <el-card
            class="m-1"
            :body-style="{
              padding: '40px 0',
              width: '100%',
            }"
            shadow="hover"
          >
            <i class="fad fa-plus-circle" style="--fa-primary-color: #ffffff; --fa-secondary-color: #f7e6b2; --fa-secondary-opacity: 1; font-size: 40px !important"></i>
            <p class="mt-3 fw-bold" style="color: rgb(81, 72, 50)">新增記事</p>
          </el-card>
        </div>
        <div v-else class="d-flex align-items-center" @click="addArticleFunc">
          <el-button class="ms-auto add_contact" round><i class="fal fa-plus-circle me-2"></i> <span>新增記事</span></el-button>
        </div>
      </el-card>
      <el-row :gutter="20" class="mt-3">
        <template v-for="o in filteredData" :key="o">
          <el-col :lg="8" :md="12" :xs="24">
            <el-card
              :style="{
                'border-top': o.backgroundColor != '' ? `5px solid ${o.backgroundColor} ` : '',
              }"
              style="border-radius: 16px"
              :body-style="{ padding: '0px' }"
              :id="'article_' + o.id"
              class="mb-3 mx-2 p-3"
              @click="$store.state.selectTagId != 'trash' ? setEditArticle(o) : null"
            >
              <div class="text-start">
                <div class="d-flex justify-content-between align-items-center">
                  <h3 class="py-3 px-2 fs-5" style="font-weight: bold">
                    {{ o.title }}
                  </h3>
                  <div v-if="!o.isTop" @click.stop="topArticle(o)">
                    <i class="fas fa-thumbtack"></i>
                  </div>
                  <div v-else @click.stop="topArticle(o)">
                    <i class="fal fa-thumbtack"></i>
                  </div>
                </div>
                <ul class="m-0 p-0">
                  <li class="border-top list-group-item p-2 d-flex align-items-center" v-for="i in o.todoItems" :key="i">
                    <el-checkbox name="type" class="me-2" v-model="i.checked" disabled />
                    <p>{{ i.title }}</p>
                    <span v-if="i.file == null && i.link == null && i.content == null"></span>
                    <span v-else-if="(i.file != null && i.file.length > 0) || (i.link != null && i.link.length > 0) || i.content != ''" class="ms-auto mx-2">
                      <i class="fad fa-comment-alt-edit fa-lg" style="--fa-primary-color: rgb(62, 62, 224); --fa-secondary-color: #f5f5f5; --fa-secondary-opacity: 1"></i>
                    </span>
                  </li>
                </ul>
              </div>
              <div v-if="o.assignees != undefined && o.assignees != null" class="d-flex justify-content-start">
                <el-avatar class="m-1 border" :title="a.email" v-for="a in o.assignees" :key="a" size="mini" :src="a.avatar"></el-avatar>
              </div>

              <div class="tagsArea text-start" v-if="o.tags != null && o.tags != undefined">
                <el-tag v-for="item in o.tags" :key="item" size="small">
                  {{ getUserTagsName(item) }}
                </el-tag>
              </div>

              <div v-if="$store.state.selectTagId == 'trash'">
                <div class="p-2 d-flex justify-content-end" style="font-size: xx-small">
                  <el-button circle title="復原" @click="undoArticle(o)"> <i class="fal fa-trash-undo"></i></el-button>
                  <el-button circle title="永久刪除" @click="deletePermanently(o)"> <i class="fal fa-trash"></i></el-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </template>
      </el-row>
    </el-col>
  </el-row>

  <el-dialog v-model="ArticleInfoDialog" :width="$store.getters.mobileSize ? '90%' : '600px'" :show-close="false" @close="saveTodoItemContentById(todoItemDialog.id)" @open="getTodoItemById(todoItemDialog.id)">
    <template v-slot:header>
      <div class="d-flex align-items-center justify-content-between">
        <span>{{ todoItemDialog.title }}</span>
        <el-button style="width: 30px; height: 30px" circle @click="ArticleInfoDialog = false"><i class="fal fa-times"></i></el-button>
      </div>
    </template>
    <div class="">
      <div class="edit_space mb-2">
        <Editor class="shadow" style="border-radius: 16px; overflow: hidden; border: 1px rgba(0, 0, 0, 0.15) solid" :value="todoItemDialog.content" @input="getEditorDataTodoContent" />
      </div>
      <el-upload style="border-radius: 16px; overflow: hidden; border: 1px rgba(0, 0, 0, 0.15) solid" class="upload-demo shadow" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" drag multiple :http-request="uploadFile" :on-preview="handleArticlePreview" :on-remove="handleArticleRemove" list-type="text">
        <i class="fa-duotone fa-cloud-arrow-up mb-1" style="--fa-primary-color: #ffffff; --fa-secondary-color: #0066ff; font-size: 4rem"></i>
        <div class="el-upload__text">將文件拖曳到此處，或<em>點擊上傳</em></div>
        <div class="el-upload__tip" :v-slot="tip">檔案不超過10MB</div>
      </el-upload>
      <div class="mt-2 py-1 px-2 d-flex justify-content-between align-items-center" v-for="item in todoItemDialog.fileList" :key="item">
        <div>
          <i class="fad fa-file me-2" style="--fa-primary-color: #398d69; --fa-secondary-color: #9cbdab; --fa-secondary-opacity: 1"></i>
          <el-link @click="handleArticlePreview(item)">{{ item.name }}</el-link>
        </div>
        <div>
          <i class="fa-regular fa-folder-arrow-down downloadFileIcon me-3" @click="handleArticleDownload(item)"></i>
          <i class="fa-regular fa-circle-xmark removeFileIcon" @click="handleArticleRemove(item)"></i>
        </div>
      </div>
    </div>
  </el-dialog>

  <el-dialog v-model="dialogAddTag" title="新增標籤" width="80%" @open="dialogAddTagOpen">
    <el-form :model="tagForm">
      <el-form-item label="名稱">
        <el-input v-model="tagForm.name" autocomplete="off" placeholder="請輸入標籤名稱" />
        <div class="mt-1">
          <el-button type="primary" size="small" @click="addTag">新增標籤</el-button>
        </div>
      </el-form-item>
      <el-form-item label="標籤">
        <el-checkbox-group v-model="tagForm.selected" @change="selectTag">
          <el-checkbox v-for="item in usersTags" :key="item.id" :label="item.id">{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Editor from "../../../Common/Editor.vue";

export default {
  name: "ClassRoomArticle",
  components: { Editor },
  data() {
    return {
      focusWarning: false,
      usersTags: [],
      selectArticle: "",
      dialogAddTag: false,
      tagForm: {
        name: "",
        selected: [],
      },
      assigneeArticle: false,
      selectedArticleAssignee: null,
      isReadOnlySelectedArticleAssignee: false,
      articleAssigneeInput: "",
      memberOptions: [],
      todoItemDialog: {
        title: "",
      },
      setEditArticleWin: false,
      articleList: [],
      ArticleInfoDialog: false,
      addArticleInfo: {
        id: "",
        title: "",
        bgImg: "",
        bgColor: "",
        tags: [],
        todoItems: [],
        assignee: [],
        file: [],
      },
      predefineColors: ["white", "#e6b8af", "#f4cccc", "#fce5cd", "#fff2cc", "#d9ead3", "#d0e0e3", "#c9daf8", "#cfe2f3", "#d9d2e9", "#ead1dc"],
      openAssigned: false,
      openArticle: false,
      articleTitleInput: "",
      addArticle: false,
    };
  },
  created() {
    const vm = this;
    vm.initArticleData();
    vm.initMemberData();
  },
  mounted() {
    // init usersTags
    this.$store.state.usersTags = [];
    this.$store.state.selectTagId = "All";
  },
  watch: {
    usersTags(val) {
      this.$store.state.usersTags = val;
    },
    dialogAddTag: function () {
      let vm = this;
      if (vm.dialogAddTag) {
        vm.tagForm.selected = vm.addArticleInfo.tags;
      }
    },
    "$store.state.selectTagId": function (newVal) {
      console.log(newVal);
      let vm = this;
      vm.initArticleData();
    },
  },
  computed: {
    filteredData() {
      return this.articleList.filter(x => x.title.includes(this.$store.state.searchTerm) || x.todoItems.some(todo => todo.title.includes(this.$store.state.searchTerm))).sort((a, b) => (a.isTop ? 1 : b.isTop ? -1 : 0));
    },
  },
  methods: {
    initMemberData() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoom/Member/${vm.$route.params.id}`)
        .then(res => {
          vm.memberOptions = [];
          res.data.forEach(x => {
            vm.memberOptions.push({
              value: vm.getMemberName(x.memberId, x.name),
              memberId: x.memberId,
              name: vm.getMemberName(x.memberId, x.name),
              nickName: x.nickName,
              avatar: x.avatar,
              role: x.role,
              email: x.email,
            });
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    uploadFile(param) {
      const vm = this;
      let formData = new FormData();
      formData.append("file", param.file);
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/files/file`, formData)
        .then(res => {
          console.log(res.data);
          const data = {
            articleTodoId: vm.todoItemDialog.id,
            fileName: param.file.name,
            filePath: res.data,
          };
          // axios;
          vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/Article/ArticleTodo/File`, data)
            .then(res => {
              var temp = {
                id: res.data,
                name: data.fileName,
                url: data.filePath,
              };
              param.onSuccess(temp);
              console.log(data.fileName, data.filePath);
              vm.$message({
                type: "success",
                message: "檔案上傳成功！",
              });

              let tTodo = vm.addArticleInfo.todoItems.find(x => x.articleTodoId == vm.todoItemDialog.id);
              console.log(tTodo);
              tTodo.file.push(res.data);
              console.log(vm.todoItemDialog);
              vm.todoItemDialog.fileList.push(temp);
            })
            .catch(err => {
              console.log(err.response);
              vm.notify("error", `檔案上傳失敗！`);
            });
        })
        .catch(err => {
          console.log(err);
          this.notify("error", "檔案上傳失敗！");
        });
    },
    topArticle(o) {
      let vm = this;
      o.isTop = !o.isTop;
      const articleId = o.id;
      vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/ClassRoom/Article/SetTop/${articleId}`).then(() => {});
    },
    showAllOptions() {
      // 在 focus 時顯示所有選項
      this.$refs.autocomplete.highlight = -1;
      this.$refs.autocomplete.suggestions = this.suggestions;
    },

    querySearchByClassMember(queryString, cb) {
      let vm = this;
      var results = queryString ? vm.memberOptions.filter(this.createFilter(queryString)) : vm.memberOptions;

      results = results.filter((item, index, array) => array.findIndex(element => element.memberId === item.memberId) === index);

      var classStudent = vm.addArticleInfo.assignee.map(y => y.memberId);
      var temp = results.filter(x => !classStudent.includes(x.memberId));

      cb(temp);
      //   console.log(vm.memberOptions)
      // let uniqueObjects = results.filter((obj, index, array) => {
      //   return array.findIndex((o) => o.id === obj.id) === index;
      // });
      // var temp = uniqueObjects.filter(
      //   (x) => x.memberId != vm.currentUser().id
      // );
    },
    autocomplete_selectAll() {
      let vm = this;
      vm.addArticleInfo.assignee = vm.memberOptions.filter((item, index, array) => array.findIndex(element => element.memberId === item.memberId) === index);
      vm.updateArticle();
    },
    createFilter(queryString) {
      return r => {
        return r.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0 || r.email.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    undoArticle(o) {
      let vm = this;
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/Article/Undo/${Number(o.id)}`)
        .then(res => {
          console.log(res);
          vm.articleList = vm.articleList.filter(x => x.id != o.id);
          if (res.data.status == 0) {
            vm.$message({
              type: "success",
              message: `已復原！`,
            });
          } else {
            vm.notify("error", `失敗！`);
          }
        })
        .catch(() => {
          vm.notify("error", `失敗！`);
        });
    },
    deletePermanently(o) {
      let vm = this;
      vm.$confirm("將永久刪除這個筆記, 是否要繼續?", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/ClassRoom/Article/DeletePermanently/${Number(o.id)}`)
            .then(res => {
              console.log(res);
              vm.articleList = vm.articleList.filter(x => x.id != o.id);
              if (res.data.status == 0) {
                vm.$message({
                  type: "success",
                  message: "删除成功!",
                });
              } else {
                vm.notify("error", `失敗！`);
              }
            })
            .catch(() => {
              vm.notify("error", `失敗！`);
            });
        })
        .catch(() => {
          vm.notify("error", `失敗！`);
        });
    },
    getEditorDataTodoContent(input) {
      let vm = this;
      vm.todoItemDialog.content = input;
    },
    handleArticlePreview(file) {
      if (file.response == undefined) {
        window.open(file.url);
      } else {
        window.open(file.response.url);
      }
    },
    handleArticleDownload(file) {
      if (file["response"] != undefined) file = file.response;
      this.axios
        .get(file.url, { responseType: "blob" })
        .then(response => {
          const blob = new Blob([response.data], { type: response.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    handleArticleRemove(file) {
      let fileId = "";
      if (file.response == undefined) {
        fileId = file.id;
      } else {
        fileId = file.response.id;
      }
      let vm = this;
      console.log(fileId);
      //axios
      vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/ClassRoom/Article/ArticleTodo/${vm.todoItemDialog.id}/File/${Number(fileId)}`)
        .then(() => {
          this.$message({
            type: "success",
            message: "刪除成功",
          });
          let tTodo = vm.addArticleInfo.todoItems.find(x => x.articleTodoId == vm.todoItemDialog.id);
          //拿掉一個
          tTodo.file.shift();
          vm.todoItemDialog.fileList = vm.todoItemDialog.fileList.filter(x => x.id != fileId);
        })
        .catch(err => {
          console.log(err);
          vm.notify("error", "刪除失敗！");
        });
    },
    deleteTodoAssignee(o) {
      let vm = this;
      vm.addArticleInfo.assignee = vm.addArticleInfo.assignee.filter(x => {
        return x.memberId != o.memberId;
      });
    },
    handleSelectArticleAssignee(item) {
      this.selectedArticleAssignee = item;
      this.isReadOnlySelectedArticleAssignee = true;
    },
    cancelArticleAutoComplete() {
      if (this.articleAssigneeInput == "") this.assigneeArticle = false;
      this.selectedArticleAssignee = null;
      this.isReadOnlySelectedArticleAssignee = false;
      this.articleAssigneeInput = "";
    },

    setArticleAssignee() {
      let vm = this;
      this.isReadOnlySelectedArticleAssignee = true;
      vm.addArticleInfo.assignee.push(vm.selectedArticleAssignee);
      vm.articleAssigneeInput = "";
    },
    saveTodoItemContentById(id) {
      let vm = this;
      console.log(vm.todoItemDialog.content);
      let data = {
        Id: id,
        Content: vm.todoItemDialog.content,
      };
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/Article/EditArticleTodoContent`, data).then(() => {
        let tTodo = vm.addArticleInfo.todoItems.find(x => x.articleTodoId == id);
        tTodo.content = vm.todoItemDialog.content;
      });
    },
    getTodoItemById(id) {
      let vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoom/Article/ArticleTodoById/${id}`).then(res => {
        console.log(res);
        vm.todoItemDialog.dueDate = res.data.dueDate;
        vm.todoItemDialog.content = res.data.content;
        vm.todoItemDialog.studentAssignList = res.data.assignee;
        vm.todoItemDialog.fileList = res.data.fileList;
      });
    },
    setTodoItemDialog(o) {
      let vm = this;
      vm.ArticleInfoDialog = true;
      vm.todoItemDialog.id = o.articleTodoId;
      vm.todoItemDialog.title = o.title;
      console.log(o);
    },
    deleteTodoItem(o) {
      let vm = this;
      vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/ClassRoom/Article/ArticleTodo/${o.articleTodoId}`).then(res => {
        console.log(res);
        vm.addArticleInfo.todoItems = vm.addArticleInfo.todoItems.filter(x => {
          return x != o;
        });
      });
    },
    setEditArticle(o) {
      let vm = this;
      if (vm.addArticle) {
        vm.focusWarning = true;
        return;
      }

      vm.setEditArticleWin = true;
      vm.assigneeArticle = false;
      vm.addArticleInfo.assignee = o.assignees;
      vm.addArticleInfo.id = o.id;
      vm.addArticleInfo.title = o.title;
      vm.addArticleInfo.bgColor = o.backgroundColor;
      vm.addArticleInfo.bgImg = o.backgroundImage;
      vm.addArticleInfo.todoItems = o.todoItems;
      vm.addArticleInfo.tags = o.tags;
      vm.addArticle = true;
    },
    resetArticleModel() {
      let vm = this;

      if (vm.articleList.length == 0) {
        vm.addArticleInfo = {
          id: "",
          title: "",
          bgImg: "",
          bgColor: "",
          todoItems: [],
          assignee: [],
        };
        vm.addArticle = false;
        vm.setEditArticleWin = false;
        return;
      }

      let article = vm.articleList.find(x => x.id == vm.addArticleInfo.id);
      article.id = vm.addArticleInfo.id;
      article.title = vm.addArticleInfo.title;
      article.backgroundColor = vm.addArticleInfo.bgColor;
      article.backgroundImage = vm.addArticleInfo.bgImg;
      article.todoItems = vm.addArticleInfo.todoItems;

      vm.addArticleInfo = {
        id: "",
        title: "",
        bgImg: "",
        bgColor: "",
        todoItems: [],
        assignee: [],
      };
      vm.articleTitleInput == "";
      vm.focusWarning = false;
    },
    async initArticleData() {
      const vm = this;
      let apiPath = `${process.env.VUE_APP_URL_API}/ClassRoom/Article/${vm.$route.params.id}`;
      if (vm.$store.state.selectTagId == "trash") {
        apiPath = `${process.env.VUE_APP_URL_API}/ClassRoom/Article/Deleted/${vm.$route.params.id}`;
      }

      vm.fetchAPI("get", apiPath).then(res => {
        vm.articleList = res.data.articleResponses;
        if (vm.$store.state.selectTagId != "trash" && vm.$store.state.selectTagId != "" && vm.$store.state.selectTagId != "All") vm.articleList = vm.articleList.filter(x => x.tags.indexOf(vm.$store.state.selectTagId) != -1);
      });

      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoom/UserTag`)
        .then(res => {
          vm.usersTags = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateArticle() {
      const vm = this;
      vm.openArticle = false;
      let data = {
        ArticleId: vm.addArticleInfo.id,
        ArticleBgImg: null,
        ArticleBgColor: vm.addArticleInfo.bgColor,
        Title: vm.addArticleInfo.title,
        ArticleAssignee: vm.addArticleInfo.assignee.map(x => {
          return x.memberId;
        }),
      };
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/Article/EditArticle`, data)
        .then(res => {
          console.log(res);
          let article = vm.articleList.find(x => x.id == vm.addArticleInfo.id);

          article.assignees = vm.addArticleInfo.assignee;
          vm.selectedArticleAssignee = null;
          vm.isReadOnlySelectedArticleAssignee = false;
          vm.articleAssigneeInput = "";
        })
        .catch(e => {
          console.log(e);
        });
    },
    deleteArticle(o) {
      console.log(o);
      let vm = this;
      vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/ClassRoom/Article/${o.id}`).then(res => {
        console.log(res);

        vm.articleList = vm.articleList.filter(x => {
          return x.id != o.id;
        });
        vm.addArticleInfo = {
          id: "",
          title: "",
          bgImg: "",
          bgColor: "",
          todoItems: [],
          assignee: [],
        };
        vm.$message({
          type: "success",
          message: "刪除成功！",
        });
        vm.addArticle = false;
        vm.setEditArticleWin = false;
      });
    },
    inputfirst() {
      let vm = this;
      if (vm.articleTitleInput == "") return;
      let data = {
        ArticleId: vm.addArticleInfo.id,
        Title: vm.articleTitleInput,
      };
      vm.articleTitleInput = "";
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/Article/ArticleTodo`, data)
        .then(res => {
          var item = res.data;
          console.log(item);
          vm.addArticleInfo.todoItems.push({
            articleTodoId: item.articleTodoId,
            title: data.Title,
            checked: item.checked,
            file: null,
            link: null,
            content: "",
          });
          setTimeout(() => {
            document.getElementById(`todo_${item.articleTodoId}`).focus();
          }, 10);
          vm.$message({
            type: "success",
            message: "新增成功!",
          });
        })
        .catch(err => {
          console.log(err.response);
          vm.notify("error", `新增失敗！`);
        });
    },
    addArticleFunc() {
      const vm = this;
      vm.setEditArticleWin = true;
      let convertedTagId = isNaN(Number(vm.$store.state.selectTagId)) ? 0 : Number(vm.$store.state.selectTagId);

      //axios
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/Article/${vm.$route.params.id}/${convertedTagId}`)
        .then(res => {
          var item = res.data;
          vm.addArticleInfo.id = item.articleId;
          vm.addArticle = true;

          var newArticle = {
            id: item.articleId,
            title: "",
            backgroundColor: "",
            backgroundImage: "",
            todoItems: [],
            assignees: [],
          }
          if (item.tagId != 0) {
            newArticle.tags = [item.tagId];
          } else {
            newArticle.tags = [];
          }
          vm.articleList.unshift(newArticle);

          setTimeout(() => {
            document.getElementById(`createArticle`).focus();
          }, 10);
          vm.$message({
            type: "success",
            message: "新增成功!",
          });
        })
        .catch(err => {
          console.log(err.response);
          vm.notify("error", `新增失敗！`);
        });
    },
    updateArticleTodo(o) {
      let vm = this;
      console.log(o);
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/Article/EditArticleTodo`, o)
        .then(res => {
          console.log(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    addTag() {
      let vm = this;
      let o = vm.selectArticle;

      if (vm.usersTags.find(x => x.name == vm.tagForm.name) != undefined) {
        vm.$message({
          type: "error",
          message: "標籤名稱重複！",
        });
        return;
      }

      if (vm.tagForm.name != "") {
        let tagName = vm.tagForm.name;
        const data = {
          articleId: o.id,
          tagName: tagName,
        };
        vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/ArticleTag`, data)
          .then(res => {
            vm.$message({
              type: "success",
              message: "新增成功",
            });
            let insertData = { id: res.data.tagId, name: tagName };
            vm.usersTags.unshift(insertData);
          })
          .catch(err => {
            console.log(err);
          });
        vm.tagForm.name = "";
      } else {
        vm.$message({
          type: "error",
          message: "請輸入標題！",
        });
      }
    },
    getUserTagsName(item) {
      if (this.usersTags.find(x => x.id == item) == undefined) return;
      return this.usersTags.length == 0 ? "" : this.usersTags.find(x => x.id == item).name;
    },
    selectTag() {
      let vm = this;
      let o = vm.selectArticle;

      const data = {
        articleId: o.id,
        tagIds: vm.tagForm.selected,
      };

      vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/ClassRoom/ArticleTag`, data)
        .then(() => {
          vm.articleList.find(x => x.id == o.id).tags = vm.tagForm.selected;
          vm.addArticleInfo.tags = vm.tagForm.selected;
          if (vm.$store.state.selectTagId != "trash" && vm.$store.state.selectTagId != "" && vm.$store.state.selectTagId != "All") vm.articleList = vm.articleList.filter(x => x.tags.indexOf(vm.$store.state.selectTagId) != -1);
          vm.$message({
            type: "success",
            message: "更新成功",
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.fa-thumbtack {
  text-shadow: 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.2);
}
.fa-thumbtack,
.fl-thumbtack {
  cursor: pointer;
  transform: rotate(45deg);
  border-radius: 99em;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: x-small;
  text-align: center;
  background-color: $main_color;
}
.focusWarning {
  transition: transform 0.3s ease;
  animation: scaleAnimation 0.2s forwards;
  border: 1px solid rgb(255, 168, 168);
}
.divBg {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 89;
}
.EditWin {
  z-index: 99;
  position: fixed;
  top: 30%;
  left: 0;
  right: 0;
}
.EditWinOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 80;
}
.circleDiv {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 99em;
  background-color: red;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.circleDiv:hover {
  border: 2px solid rgba(0, 0, 0, 0.55);
}
:deep .el-card__body {
  padding: 10px;
  min-height: 5rem;
  .title {
    min-height: 2.5rem;
  }
}
.AddArticleTitleInput {
  border: none;
  padding: 0.5em 0;
  width: 100%;
}
.ArticleCheckBox {
  padding: 0.25rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.article_btn_area {
  background-color: white;
  padding: 0.5rem;
}
input[type="text"],
textarea {
  background-color: rgba(0, 0, 0, 0);
}
.AddArticleTitleInput:focus {
  outline: none;
  background-color: #f4f4f4;
}
.AddArticleArea {
  cursor: text;
  background-size: cover;
  ::v-deep {
    .el-card__body {
      padding: 0;
    }
  }
}
.ArticleBtn {
  height: 30px;
  width: 30px;
  border-radius: 99em;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  color: #5f6368;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.ArticleBtn-sm {
  box-sizing: border-box;
  display: block;

  width: 30px !important;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.ArticleBtn:hover {
  background-color: #eaeaea;
}
.fa-user-plus {
  cursor: pointer;
  transition: 0.1s;
}
.fa-times {
  line-height: 1.8;
}
.add_contact {
  background-color: $main_color;
  color: #77724b;
  border: 1px solid #77724b;
}
.tagsArea {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  :deep .el-tag {
    border: none !important;
    background-color: #398d69 !important;
    color: #fff;
    margin-left: 0.5rem;
    margin-bottom: 0.25rem !important;
  }
}
.removeFileIcon,
.downloadFileIcon {
  cursor: pointer;
  &:hover {
    color: #2c2c2c;
  }
}
</style>
