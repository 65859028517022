<template>
  <div class="main_space mt-3" v-if="$store.state.workItem.task != null">
    <div class="top">
      <div class="up">
        <div class="taskTitleDiv ms-2">
          <i
            class="fal fa-arrow-left arrow"
            @click="$store.state.workItem.task = null"
          ></i>
          <p class="fw-bold">
            {{ $store.state.workItem.task.title }}
          </p>
        </div>
      </div>
      <div class="edit_group" :class="{ focus: !canNotEdit }">
        <div class="taskTitle">
          <span class="">資訊</span>
          <el-button v-if="canNotEdit" class="ms-auto" @click="editTask" link>
            <i class="fal fa-marker"></i>
          </el-button>
        </div>

        <el-form-item label="到期日" class="mb-0" v-if="!canNotEdit">
          <el-date-picker
            :disabled="canNotEdit"
            v-model="$store.state.workItem.task.dueDate"
            type="date"
            placeholder="選擇到期日"
            style="width: 190px"
          >
          </el-date-picker>
        </el-form-item>
        <div v-else class="pb-2">
          <span
            v-if="
              $store.state.workItem.task.dueDate != '' &&
              $store.state.workItem.task.dueDate != null
            "
            style="font-size: x-small"
            ><span class="me-1">到期日 :</span
            >{{ formatDate($store.state.workItem.task.dueDate, true) }}</span
          >
          <el-button v-else @click="canNotEdit = !canNotEdit"
            >未設定日期</el-button
          >
        </div>

        <div class="d-flex align-items-center mt-2">
          <el-form-item v-if="!canNotEdit" label="發派給" class="mb-0 my-1">
            <el-select
              :disabled="canNotEdit"
              v-model="$store.state.workItem.task.assigneeListId"
              multiple
              collapse-tags
              placeholder="無指定對象"
              @change="setAssignee"
              style="width: 190px"
            >
              <el-option
                v-for="item in studentOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div v-else class="pb-2">
            <template
              v-if="$store.state.workItem.task.assigneeListId.length != 0"
            >
              <span style="font-size: x-small" class="me-1">發派成員 :</span>

              <el-tag
                v-for="item in studentOptions.filter((x) =>
                  $store.state.workItem.task.assigneeListId.includes(x.value)
                )"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                type="success"
                class="me-1"
                >{{ item.label }}</el-tag
              >
            </template>
            <el-button v-else @click="canNotEdit = !canNotEdit">
              未設定成員</el-button
            >
          </div>
        </div>

        <div :class="{ editText: canNotEdit }" @click="editTask">
          <div v-if="$store.state.workItem.task.content == '' && canNotEdit">
            <el-button @click="canNotEdit = !canNotEdit">編輯描述</el-button>
          </div>
          <div
            v-else-if="!canNotEdit || $store.state.workItem.task.dueDate != ''"
            class="info w-100 d-flex justify-content-between my-1"
          ></div>
          <div class="edit_space" v-if="!canNotEdit">
            <Editor
              :value="$store.state.workItem.task.content"
              :canNotEdit="canNotEdit"
              @input="getEditorData"
            />
          </div>
          <div v-else>
            <div
              v-if="$store.state.workItem.task.content != ''"
              v-html="$store.state.workItem.task.content"
            ></div>
          </div>
        </div>
        <div v-if="!canNotEdit" class="d-flex mx-auto mt-2">
          <el-button size="small" @click="cancelUpdate">取消</el-button>
          <el-button color="#f5c945" size="small" @click="updateTask"
            >確認</el-button
          >
        </div>
      </div>
    </div>
    <div class="fileTitle">檔案</div>
    <div>
      <el-upload
        :http-request="uploadFile"
        :on-preview="previewFile"
        :before-remove="beforeFileRemove"
        multiple
        :show-file-list="false"
      >
        <el-button color="#b9d6f3" type="success" @click="submitUpload"
          >添加</el-button
        >
        <div :v-slot="tip" class="el-upload__tip ms-2">請上傳文件檔案</div>
      </el-upload>
      <div
        class="mt-2 py-1 px-2 d-flex justify-content-between align-items-center"
        v-for="item in $store.state.workItem.task.fileList"
        :key="item"
      >
        <i
          v-if="!IsImageFile(item.name) && !IsVideoFile(item.name)"
          class="fad fa-file me-2"
          style="
            --fa-primary-color: #398d69;
            --fa-secondary-color: #9cbdab;
            --fa-secondary-opacity: 1;
          "
        ></i>

        <el-image
          class="me-auto"
          v-if="IsImageFile(item.name)"
          style="width: 200px"
          :src="item.url"
          :preview-src-list="[item.url]"
        >
        </el-image>

        <VideoPlayer
          class="me-auto"
          v-else-if="IsVideoFile(item.name)"
          :src="item.url"
          controls
          :loop="true"
          width="200"
        />

        <el-link v-else class="me-auto" @click="previewFile(item)">{{
          item.name
        }}</el-link>
        <div>
          <i
            class="fa-regular fa-folder-arrow-down downloadFileIcon me-3"
            @click="downloadFile(item)"
          ></i>
          <i
            class="fa-regular fa-circle-xmark removeFileIcon"
            @click="removeFile(item.id)"
          ></i>
        </div>
      </div>
    </div>
    <div class="linkTitle">連結</div>
    <div>
      <div class="d-flex align-items-center">
        <el-button color="#b9d6f3" @click="addLink">新增連結</el-button>
        <el-input
          class="ms-2"
          v-model="linkInput.title"
          placeholder="議題名稱"
        ></el-input>
        <el-input
          class="ms-2"
          v-model="linkInput.url"
          placeholder="連結網址"
        ></el-input>
      </div>
      <div class="linkList pt-2 px-1">
        <div
          v-for="item in $store.state.workItem.task.linkList"
          :key="item"
          class="mb-2 d-flex justify-content-between"
        >
          <el-link type="primary" :href="item.url">{{ item.title }}</el-link>
          <i class="fas fa-times" @click="deleteLink(item.id)"></i>
        </div>
      </div>
    </div>
  </div>
  <template v-if="$store.state.workItem.task != null">
    <div class="showStudentView">
      <el-drawer
        title="功課檢視"
        :direction="direction"
        size="100%"
        modal-class="drawer_tabs"
        v-model="isViewShow"
        v-if="$store.getters.mobileSize"
      >
        <ClassRoomContactMessage
          type="ContactTodo"
          collapse="active"
          :memberOptions="memberOptions"
          :contactTodoId="$store.state.workItem.task.id"
          :memberId="currentUser().id"
          :studentAssigneeId="viewStudentId"
          :getAll="true"
        />
      </el-drawer>
      <el-dialog v-else title="功課檢視" width="60%" v-model="isViewShow">
        <ClassRoomContactMessage
          type="ContactTodo"
          collapse="active"
          :memberOptions="memberOptions"
          :contactTodoId="$store.state.workItem.task.id"
          :memberId="currentUser().id"
          :studentAssigneeId="viewStudentId"
          :getAll="true"
        />
      </el-dialog>
    </div>
  </template>
</template>

<script>
import Editor from "../../../Common/Editor.vue";
import ClassRoomContactMessage from "../ClassRoomContactMessage.vue";
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";

export default {
  components: {
    Editor,
    ClassRoomContactMessage,
    VideoPlayer,
  },
  data() {
    return {
      backup: {},
      canNotEdit: true,
      linkInput: {
        title: "",
        url: "",
      },
      fileList: [],
      isViewShow: false,
      viewStudentId: 0,
      memberOptions: [],
      selectAllStudent: false,
      assigneeListTemp: [],
    };
  },
  async mounted() {
    if (this.$store.state.workItem.task != null) {
      this.assigneeListTemp = this.$store.state.workItem.task.assigneeList;
    }
  },
  computed: {
    studentOptions() {
      return this.$store.state.workItem.studentOptions;
    },
  },
  watch: {
    "$store.state.workItem.task.assigneeListId"(newVal, oldVal) {
      const vm = this;

      if (vm.$store.state.workItem.task == null) return;

      if (!vm.$store.state.workItem.task.assigneeListId.include) return;
      if (newVal.length == vm.studentOptions.length) return;
      if (newVal.includes(0)) {
        if (newVal.length == 1 || !oldVal.includes(0)) {
          vm.$store.state.workItem.task.assigneeListId = vm.studentOptions.map(
            (x) => x.value
          );
          vm.setAssignee();
        } else if (newVal.length < vm.studentOptions.length)
          vm.$store.state.workItem.task.assigneeListId =
            vm.$store.state.workItem.task.assigneeListId.filter((x) => x != 0);
        else vm.$store.state.workItem.task.assigneeListId = [];
      } else {
        if (
          newVal.length == vm.studentOptions.length - 1 &&
          oldVal.length == vm.studentOptions.length
        ) {
          vm.$store.state.workItem.task.assigneeListId = [];
          vm.setAssignee();
        } else if (newVal.length == vm.studentOptions.length - 1) {
          vm.$store.state.workItem.task.assigneeListId.sort();
        } else vm.$store.state.workItem.task.assigneeListId = newVal;
      }
    },
  },
  methods: {
    IsVideoFile(fileName) {
      const videoExtensions = /\.(mp4|avi|mov|mkv|wmv|flv)$/i;
      if (videoExtensions.test(fileName)) {
        return true;
      } else {
        return false;
      }
    },
    IsImageFile(fileName) {
      const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i;
      if (imageExtensions.test(fileName)) {
        return true;
      } else {
        return false;
      }
    },
    addDays(date, days) {
      const result = new Date(date);
      result.setDate(date.getDate() + days);
      return result;
    },
    formatDate(inputDate, useISOString = true) {
      let vm = this;
      const date = new Date(inputDate);

      if (useISOString) {
        // 使用 toISOString 方法

        const numberOfDaysToAdd = 1; // 要添加的天数
        const newDate = vm.addDays(date, numberOfDaysToAdd);
        return newDate.toISOString().slice(0, 10);
      } else {
        // 使用年月日格式
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
    },
    setStudent() {
      if (this.selectAllStudent) {
        this.$store.state.workItem.task.assigneeListId =
          this.studentOptions.map((x) => x.value);
      } else {
        this.$store.state.workItem.task.assigneeListId = [];
        this.setAssignee();
      }
    },
    toContactBookView(o) {
      const vm = this;
      console.log(o);
      vm.isViewShow = true;
      vm.viewStudentId = o.memberId;
    },
    uploadFile(param) {
      const vm = this;
      let formData = new FormData();
      formData.append("file", param.file);
      // console.log(param);
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/files/file`, formData)
        .then((res) => {
          // console.log(res.data);
          const data = {
            contactTodoId: vm.$store.state.workItem.task.id,
            fileName: param.file.name,
            filePath: res.data,
          };
          //axios
          vm.fetchAPI(
            "post",
            `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/File`,
            data
          )
            .then((res) => {
              // console.log(res.data);
              if (vm.$store.state.workItem.task.fileList == undefined)
                vm.$store.state.workItem.task.fileList = [];
              vm.$store.state.workItem.task.fileList.push({
                id: res.data,
                name: data.fileName,
                url: data.filePath,
              });
              param.onSuccess({
                id: res.data,
                name: data.fileName,
                url: data.filePath,
              });
              vm.$message({
                type: "success",
                message: "檔案上傳成功！",
              });
            })
            .catch((err) => {
              console.log(err.response);
              vm.notify("error", `檔案上傳失敗！`);
            });
        })
        .catch((err) => {
          console.log(err);
          this.notify("error", "檔案上傳失敗！");
        });
    },
    removeFile(fileId) {
      let vm = this;
      //axios
      vm.fetchAPI(
        "delete",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/${vm.$store.state.workItem.task.id}/File?contactTodoFileId=${fileId}`
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "刪除成功",
          });
          vm.$store.state.workItem.task.fileList =
            vm.$store.state.workItem.task.fileList.filter(
              (x) => x.id != fileId
            );
        })
        .catch((err) => {
          console.log(err);
          vm.notify("error", "刪除失敗！");
        });
    },
    beforeFileRemove(file) {
      let vm = this;
      return vm
        .$confirm(`是否刪除 ${file.name}？`, "", {
          confirmButtonText: "確認",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          vm.removeFile(file);
        });
    },
    previewFile(file) {
      if (file.response == undefined) {
        window.open(file.url);
      } else {
        window.open(file.response.url);
      }
    },
    downloadFile(file) {
      console.log(file);
      if (file["response"] != undefined) file = file.response;
      this.axios
        .get(file.url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: response.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    editTask() {
      const vm = this;
      vm.canNotEdit = false;
      Object.assign(vm.backup, vm.$store.state.workItem.task);
    },
    cancelUpdate() {
      const vm = this;
      vm.canNotEdit = true;
      vm.$store.state.workItem.task.dueDate = vm.backup.dueDate;
      vm.$store.state.workItem.task.assigneeList = vm.backup.assigneeList;
      vm.$store.state.workItem.task.assigneeListId = vm.backup.assigneeListId;
      vm.$store.state.workItem.task.content = vm.backup.content;
      vm.setAssignee();
    },
    updateTask() {
      const vm = this;
      var allDoneCount = 0;
      var allStudentCount = 0;
      vm.$store.state.workItem.task.assigneeList.forEach((x) => {
        if (x.memberId == 0) return;
        x.isDone = vm.$store.state.workItem.tableData.find(
          (y) => y.memberId == x.memberId
        ).isDone;
        if (x.isDone) allDoneCount += 1;
        allStudentCount += 1;
      });
      const data = {
        ContactTodoId: vm.$store.state.workItem.task.id,
        Title: vm.$store.state.workItem.task.title,
        Content: vm.$store.state.workItem.task.content,
        Assignee: vm.$store.state.workItem.task.assigneeList.filter(
          (x) => x.memberId != 0
        ),
        StartDate: vm.$store.state.workItem.task.startDate,
        DueDate: vm.$store.state.workItem.task.dueDate,
        IsInformDueTask: vm.$store.state.workItem.task.isInformDueTask,
      };
      vm.fetchAPI(
        "put",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo`,
        data
      )
        .then((res) => {
          console.log(res.data);
          vm.canNotEdit = true;
          vm.$store.state.workItem.tableData.forEach((x) => {
            if (res.data.isDoneIdList.includes(x.memberId))
              x.finishDate = res.data.finishDate;
          });
          vm.$emit(
            "rerenderMiddleBar",
            vm.$store.state.workItem.task.id,
            allDoneCount,
            allStudentCount,
            allStudentCount
          );
          vm.notify("success", `更新成功！`);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `更新失敗！`);
        });
    },
    getEditorData(input) {
      this.$store.state.workItem.task.content = input;
    },
    setAssignee() {
      var vm = this;
      vm.$store.state.workItem.tableData = [];
      vm.$store.state.workItem.task.assigneeListId.forEach(function (x) {
        if (x == 0) return;
        var temp = vm.assigneeListTemp.find((y) => y.memberId == x);
        if (temp == undefined) {
          vm.$store.state.workItem.task.assigneeList.push({
            memberId: x,
            isDone: false,
          });
        }

        var student = vm.studentOptions.find((y) => y.value == x);
        vm.$store.state.workItem.tableData.push({
          memberId: x,
          avatar: student.avatar,
          finishDate: temp == undefined ? "" : temp.finishDateTime,
          fileCount: temp == undefined ? 0 : temp.fileCount,
          messageCount: temp == undefined ? 0 : temp.messageCount,
          name: student.label,
          isDone: temp == undefined ? false : temp.isDone,
        });
      });
      vm.$store.state.workItem.task.assigneeList =
        vm.$store.state.workItem.task.assigneeList.filter((x) =>
          vm.$store.state.workItem.task.assigneeListId.includes(x.memberId)
        );
    },
    isValidUrl(string) {
      try {
        new URL(string);
        return true;
      } catch (err) {
        return false;
      }
    },
    addLink() {
      var vm = this;
      let linkTitle = vm.linkInput.title;
      let linkUrl = vm.linkInput.url;
      if (linkTitle == "") {
        vm.$message.error("議題名稱不得為空");
        return;
      }
      if (!vm.isValidUrl(linkUrl)) {
        vm.$message.error("連結格式有誤或連結無效");
        return;
      }
      const data = {
        contactTodoId: vm.$store.state.workItem.task.id,
        title: linkTitle,
        url: linkUrl,
      };
      //axios
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/Link`,
        data
      )
        .then((res) => {
          // console.log(res.data);
          if (vm.$store.state.workItem.task.linkList == undefined)
            vm.$store.state.workItem.task.linkList = [];
          vm.$store.state.workItem.task.linkList.push({
            id: res.data,
            title: vm.linkInput.title,
            url: vm.linkInput.url,
          });
          vm.linkInput.title = "";
          vm.linkInput.url = "";
          vm.$message({
            type: "success",
            message: "新增成功!",
          });
        })
        .catch((err) => {
          console.log(err.response);
          vm.notify("error", `新增失敗！`);
        });
    },
    deleteLink(id) {
      let vm = this;
      //axios
      vm.fetchAPI(
        "delete",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/${vm.$store.state.workItem.task.id}/Link?contactTodoLinkId=${id}`
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "刪除成功",
          });
          vm.$store.state.workItem.task.linkList =
            vm.$store.state.workItem.task.linkList.filter((x) => x.id != id);
        })
        .catch((err) => {
          console.log(err);
          vm.notify("error", "刪除失敗！");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.editText {
  cursor: pointer;
  padding: 0.5rem 0;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.taskTitleDiv {
  display: flex;
  font-size: large;
  word-break: normal;
  white-space: pre-warp;
  word-wrap: break-word;
  // font-weight: 800;
  p {
    margin-left: 0.5rem;
    max-width: 80vw;
    overflow: hidden;
  }
}
.taskTitle {
  display: flex;
  align-items: center;
}
.main_space {
  text-align: start;
  i {
    margin-right: 0.3em;
  }
  .arrow {
    cursor: pointer;
  }
  .top {
    color: #77724b !important;
    padding-bottom: 0.5rem;
    // border-bottom: 1px solid $main_gray;
    .up {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      p {
        font-size: 1rem;
      }
    }
    .info {
      display: flex;

      justify-content: space-between;
      align-items: center;
      p {
        margin-right: 1rem;
        padding: 0.3rem 0.5rem;
        border-radius: 0.5rem;
      }

      .share,
      .date {
        display: flex;
        align-items: center;
      }
    }
  }
  .link,
  .assign,
  .file,
  .edit_space {
    // margin-top: 0.5rem;
    // border: 1px solid rgba(0, 0, 0, 0.15);
    // border-radius: 16px;
    // overflow: hidden;
    // box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
    // padding: 0.5rem;
    p {
      margin-bottom: 0.5rem;
    }
  }
  .assign,
  .file,
  .link {
    padding-bottom: 1rem;
    border-bottom: 1px solid $main_gray;
  }
  .taskTitle,
  .assignTitle,
  .fileTitle,
  .linkTitle {
    margin-bottom: 1rem;
    margin-top: 1rem;

    color: #453d26;
    font-weight: bolder;
    font-size: large !important;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  .link {
    border-bottom: none;
    .linkList {
      i {
        cursor: pointer;
      }
    }
  }
  .assignLink {
    cursor: pointer;
  }
  .edit_group {
    padding: 0rem;
    margin-top: 1rem;
    // box-shadow: 1px 1px 3px 1px $main_gray;
    transition: 0.2s;
    &.focus {
      // box-shadow: 3px 3px 5px 3px #a2a1a1;
    }
  }
}

.deliverStatus {
  zoom: 1.5;
}
.IsDone {
  color: green;
}
:deep .editor .tox {
  z-index: 0 !important;
}
.removeFileIcon,
.downloadFileIcon {
  cursor: pointer;
  &:hover {
    color: #2c2c2c;
  }
}
@media screen and (max-width: 768px) {
  .info {
    flex-direction: column;
    align-items: flex-start !important;
    .first {
      flex-direction: column;
      align-items: flex-start !important;
      & > div,
      label {
        margin: 0 !important;
        // margin-bottom: 0.5rem !important;
      }
    }
  }
}
</style>
