<template>
  <div class="message">
    <el-collapse
      :id="`${contactBookId}-${contactTodoId}`"
      v-model="activeName"
      @change="handleChange"
    >
      <el-collapse-item name="active" id="messageBox">
        <template #title>
          <div class="d-flex w-100 align-items-center justify-content-end">
            <span class="me-1" style="font-size: xx-small !important">
              <i
                class="fal fa-comment-dots"
                style="font-size: xx-small !important"
              ></i
              >：{{ displayData.filter((x) => x.message != "").length }} </span
            ><span class="ms-1" style="font-size: xx-small !important">
              <i class="fal fa-files" style="font-size: xx-small !important"></i
              >：{{ displayData.filter((x) => x.message == "").length }}</span
            >
          </div>
        </template>
        <div style="max-height: 300px">
          <div
            class="d-flex align-items-center justify-content-between my-3"
            :class="{ messageRow: showSelf(item) }"
            v-for="item in displayData"
            :key="item.id"
          >
            <div class="text-start">
              <div v-if="!showSelf(item)" class="d-flex align-items-center">
                <el-avatar
                  :src="getMemberInfo(item).avatar"
                  :size="30"
                ></el-avatar>
                <span class="ms-2">{{ getMemberInfo(item).name }}</span>
              </div>
              <div
                class="ms-5 d-flex align-items-end"
                :class="{ messageRow: showSelf(item) }"
              >
                <i
                  class="fal fa-trash-alt trash mx-2"
                  v-show="showSelf(item)"
                  @click="contactTodoDeleteMessage(item.id)"
                ></i>
                <span v-if="item.message != ''" class="msg">{{
                  item.message
                }}</span>
                <el-image
                  v-else-if="IsImageFile(item.fileName)"
                  style="width: 150px"
                  :src="item.fileUrl"
                  :preview-src-list="[item.fileUrl]"
                >
                </el-image>
                <VideoPlayer
                  v-else-if="IsVideoFile(item.fileName)"
                  :src="item.fileUrl"
                  controls
                  :loop="true"
                  width="150"
                  height="180"
                />
                <el-button
                  v-else
                  @click="previewFile(item.fileName, item.fileUrl)"
                  color="#dddddd"
                  >{{ item.fileName }}
                </el-button>
                <div class="ms-2 me-2 d-flex flex-column">
                  <el-tag v-if="item.memberId == 0" size="small" type="warning"
                    >全部</el-tag
                  >
                  <el-tag
                    v-else-if="
                      (userRole == 'Teacher' || userRole == 'Owner') &&
                      studentAssigneeId == null &&
                      item.teacherId != null &&
                      type != 'ContactBook'
                    "
                    size="small"
                    type="success"
                    >{{ getMemberInfoByTeacher(item).name }}</el-tag
                  >
                  <span class="datetime">{{
                    showDatetime(item.createdDateTime)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="send_group">
            <div
              v-if="
                studentAssigneeId == null &&
                (userRole == 'Teacher' || userRole == 'Owner') &&
                type != 'ContactBook'
              "
              class="d-flex align-items-center"
            >
              <span class="me-1">對</span>
              <el-select
                :style="{ 'min-width': '10%' }"
                clearable
                size="small"
                class="selectStudent"
                v-if="
                  studentAssigneeId == null &&
                  (userRole == 'Teacher' || userRole == 'Owner') &&
                  type != 'ContactBook'
                "
                @change="selectAll"
                v-model="selectStudentId"
                collapse-tags
                placeholder="請選擇留言對象"
              >
                <el-option
                  v-for="item in studentOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <span class="mx-1">說:</span>
            </div>

            <el-input
              :class="{
                sendMsgInput:
                  studentAssigneeId == null &&
                  (userRole == 'Teacher' || userRole == 'Owner') &&
                  type != 'ContactBook',
              }"
              class="me-1"
              style="max-width: 500px"
              size="small"
              @keydown.enter="sendMessage"
              v-model="message"
              placeholder="輸入新訊息"
            ></el-input>
            <el-button class="me-1 sendMessageBtn" @click="sendMessage">
              <i class="fas fa-paper-plane"></i>
            </el-button>
            <el-upload
              :http-request="uploadFileAndSendMessage"
              :show-file-list="false"
            >
              <el-button class="sendMessageBtn">
                <i
                  class="fad fa-folder-upload"
                  style="
                    font-size: large;
                    --fa-primary-color: #1391dd;
                    --fa-secondary-color: #fec938;
                    --fa-secondary-opacity: 1;
                  "
                ></i>
              </el-button>
            </el-upload>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import Storage from "../../../models/storage";
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";

export default {
  props: [
    "type",
    "contactBookId",
    "contactTodoId",
    "memberId",
    "studentAssigneeId",
    "getAll",
    "memberOptions",
    "collapse",
    "classRoomId",
  ],
  components: { VideoPlayer },
  data() {
    return {
      message: "",
      messageList: [],
      fileList: [],
      activeName: "active",
      parentList: [],
      selectAllStudent: true,
      selectStudentId: 0,
      studentOptions: [],
    };
  },
  async created() {
    const vm = this;
    vm.studentOptions.push({
      label: "全部人",
      value: 0,
    });
    vm.memberOptions.forEach((x) => {
      if (x.parents != null) {
        x.parents.forEach((y) => {
          y.childId = x.memberId;
          vm.parentList.push(y);
        });
      }

      if (x.role == "Student") {
        vm.studentOptions.push({
          label: vm.getMemberName(x.memberId, x.name),
          value: x.memberId,
        });
      }
    });

    if (vm.type == "ContactTodo") await vm.initContactTodoMessage();
    else if (vm.type == "ContactBook") await vm.initContactBookMessage();
    // vm.activeName = vm.collapse;
  },
  watch: {
    contactTodoId() {
      const vm = this;
      vm.messageList = [];
      vm.initContactTodoMessage();
    },
    contactBookId() {
      const vm = this;
      vm.messageList = [];
      vm.initContactBookMessage();
    },
  },
  computed: {
    userRole() {
      return Storage.get("classroom_role").role;
    },
    displayData() {
      const vm = this;
      var result;
      // ContactBook
      if (vm.type == "ContactBook") {
        result = vm.messageList;
      } else {
        // ContactTodo
        if (
          vm.studentAssigneeId != null &&
          (vm.userRole == "Teacher" || vm.userRole == "Owner")
        ) {
          var parent = vm.memberOptions
            .find((x) => x.memberId == Number(vm.studentAssigneeId))
            .parents.map((y) => y.memberId);
          result = this.messageList.filter(
            (x) =>
              x.memberId == Number(vm.studentAssigneeId) ||
              parent.includes(x.memberId) ||
              x.memberId == 0
          );
        } else result = vm.messageList;
      }
      return result;
    },
  },
  methods: {
    toMessageBottom() {
      this.$nextTick(() => {
        let scrollDom = document
          .getElementById(`${this.contactBookId}-${this.contactTodoId}`)
          .querySelector(".el-collapse-item__content");
        scrollDom.scrollTop = scrollDom.scrollHeight;
      });
    },
    selectAll(val) {
      if (val == 0) this.selectAllStudent = true;
      else this.selectAllStudent = false;
    },
    IsVideoFile(fileName) {
      const videoExtensions = /\.(mp4|avi|mov|mkv|wmv|flv)$/i;
      if (videoExtensions.test(fileName)) {
        return true;
      } else {
        return false;
      }
    },
    IsImageFile(fileName) {
      const imageExtensions = /\.(jpg|jpeg|png|gif|bmp)$/i;
      if (imageExtensions.test(fileName)) {
        return true;
      } else {
        return false;
      }
    },
    showDatetime(item) {
      if (this.$store.state.fullWidth < 500) return `${item.split("T")[0]}`;
      return `${item.split("T")[0]} ${
        item.split("T")[1].split(".")[0].split(":")[0]
      }:${item.split("T")[1].split(".")[0].split(":")[1]}`;
    },
    showSelf(item) {
      const vm = this;
      if (vm.userRole == "Teacher" || vm.userRole == "Owner") {
        if (item.teacherId == null) return false;
        else return true;
      } else {
        if (item.memberId == vm.memberId && item.teacherId == null) return true;
        else return false;
      }
    },
    sendMessage() {
      const vm = this;
      if (vm.type == "ContactTodo") vm.contactTodoCreateMessage("", "");
      else if (vm.type == "ContactBook") vm.contactBookCreateMessage("", "");
    },
    // 聯絡簿 - 訊息
    initContactBookMessage() {
      const vm = this;
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/Message?contactBookId=${vm.contactBookId}`
      )
        .then((res) => {
          // console.log(res.data);
          if (res.data.length != 0) {
            vm.messageList = res.data;
          }
          vm.toMessageBottom();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    contactBookCreateMessage(docName, docUrl) {
      const vm = this;
      //axios
      if (vm.message == "" && docName == "") return;
      const data = {
        ContactBookId: vm.contactBookId,
        MemberId: vm.memberId,
        TeacherId:
          vm.userRole == "Teacher" || vm.userRole == "Owner"
            ? Number(vm.memberId)
            : null,
        FileName: docName,
        FileUrl: docUrl,
        Message: vm.message,
      };

      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/Message`,
        data
      )
        .then((res) => {
          console.log(res.data);
          vm.messageList.push({
            id: res.data.id,
            contactBookId: data.ContactBookId,
            memberId: data.MemberId,
            teacherId: data.TeacherId,
            fileName: data.FileName,
            fileUrl: data.FileUrl,
            message: data.Message,
            createdDateTime: res.data.createdDateTime,
          });
          vm.message = "";
          vm.toMessageBottom();
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `新增留言失敗！`);
        });
    },
    contactBookDeleteMessage(id) {
      let vm = this;
      //axios
      vm.fetchAPI(
        "delete",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/Message?contactBookMessageId=${id}`
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "刪除成功",
          });
          vm.messageList = vm.messageList.filter((x) => x.id != id);
        })
        .catch((err) => {
          console.log(err);
          vm.notify("error", "刪除失敗！");
        });
    },
    // 聯絡簿 - 工作項目 - 訊息
    initContactTodoMessage() {
      const vm = this;
      var teacherId = 0;
      var studentId = 0;
      if (vm.userRole == "Teacher" || vm.userRole == "Owner")
        teacherId = vm.memberId;
      else teacherId = 0;

      if (vm.userRole == "Student" || vm.userRole == "Parent") {
        if (vm.userRole == "Parent")
          studentId = vm.parentList.find(
            (x) => x.memberId == Number(vm.memberId)
          ).childId;
        else studentId = vm.memberId;
      } else {
        if (vm.studentAssigneeId == null) studentId = 0;
        else studentId = vm.studentAssigneeId;
      }

      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/Message?classRoomId=${vm.classRoomId}&contactTodoId=${vm.contactTodoId}&teacherId=${teacherId}&studentId=${studentId}&getAll=${vm.getAll}`
      )
        .then((res) => {
          console.log(res.data);
          if (res.data.length != 0) {
            vm.messageList = res.data;
          }
          vm.toMessageBottom();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    contactTodoCreateMessage(docName, docUrl) {
      const vm = this;
      //axios
      if (vm.message == "" && docName == "") return;
      const data = {
        ContactTodoId: vm.contactTodoId,
        MemberId:
          vm.userRole == "Teacher" || vm.userRole == "Owner"
            ? vm.studentAssigneeId
            : vm.memberId,
        TeacherId:
          vm.userRole == "Teacher" || vm.userRole == "Owner"
            ? Number(vm.memberId)
            : null,
        FileName: docName,
        FileUrl: docUrl,
        Message: vm.message,
      };

      if (data.MemberId == null) {
        if (vm.selectAllStudent) data.MemberId = 0;
        else data.MemberId = vm.selectStudentId;
      }

      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/Message`,
        data
      )
        .then((res) => {
          console.log(res.data);
          vm.messageList.push({
            id: res.data.id,
            contactTodoId: data.ContactTodoId,
            memberId: data.MemberId,
            teacherId: data.TeacherId,
            fileName: data.FileName,
            fileUrl: data.FileUrl,
            message: data.Message,
            createdDateTime: res.data.createdDateTime,
          });
          vm.message = "";
          vm.toMessageBottom();
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `新增留言失敗！`);
        });
    },
    uploadFileAndSendMessage(param) {
      const vm = this;
      let formData = new FormData();
      formData.append("file", param.file);
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/files/file`, formData)
        .then((res) => {
          console.log(res.data);
          if (vm.type == "ContactTodo")
            vm.contactTodoCreateMessage(param.file.name, res.data);
          else if (vm.type == "ContactBook")
            vm.contactBookCreateMessage(param.file.name, res.data);
        })
        .catch((err) => {
          console.log(err);
          this.notify("error", "檔案上傳失敗！");
        });
    },
    previewFile(fileName, fileUrl) {
      this.axios
        .get(fileUrl, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: response.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    contactTodoDeleteMessage(id) {
      let vm = this;
      //axios
      vm.fetchAPI(
        "delete",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/Message?contactTodoMessageId=${id}`
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "刪除成功",
          });
          vm.messageList = vm.messageList.filter((x) => x.id != id);
        })
        .catch((err) => {
          console.log(err);
          vm.notify("error", "刪除失敗！");
        });
    },
    getMemberInfo(item) {
      const vm = this;
      var id = item.teacherId != null ? item.teacherId : item.memberId;
      var result = vm.memberOptions.find((x) => x.memberId == id);
      var parent = vm.parentList.find((x) => x.memberId == id);
      if (result != undefined) return result;
      else return parent;
    },
    getMemberInfoByTeacher(item) {
      const vm = this;
      var result = vm.memberOptions.find((x) => x.memberId == item.memberId);
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  position: relative;
  padding: 0 0.5rem;
  margin: auto;
  i {
    cursor: pointer;
    font-size: 1.2rem;
    transition: 0.2s;
    &:hover {
      color: #1f1f1f;
    }
  }
  .msg {
    border: 1px solid rgba(201, 201, 201, 0.686);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    width: fit-content;
    background-color: WHITE;
  }
  i.trash {
    font-size: 1rem;
    align-self: center;
  }
  .messageRow {
    flex-direction: row-reverse;
  }
  .datetime {
    font-size: 0.6rem;
  }
}
:deep .el-collapse {
  border: none;
  .el-collapse-item__wrap {
    background-color: #fffdf8;
    border: none;
  }
  .el-collapse-item__header {
    height: 20px;
    border: none;
  }
  .el-collapse-item__header .is-active {
    height: inherit;
  }
  .el-collapse-item__content {
    overflow-y: auto;
    border: none;
    max-height: 25rem;
  }
}
.send_group {
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 0.5rem 0.25rem;
  background-color: #edeff6 !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-right: none;
  border-left: none;
  .selectStudent {
    left: 0;
    z-index: 9;
  }
  .sendMsgInput {
  }
}
.sendMessageBtn {
  width: 30px;
  height: 30px;
  border-radius: 99em;
  color: #2879ff;
  text-align: center;
  i {
    font-size: small;
    padding-right: 0.1rem;
  }
}

:deep .el-input__suffix {
  display: none;
}

:deep .el-collapse-item__content {
  position: relative;
  padding: 0;
}
</style>
