<template>
  <el-skeleton v-if="!isLoaded" :rows="6" animated />
  <el-row v-else :gutter="20">
    <el-col :lg="20" :md="24" class="mx-auto">
      <div class="d-flex justify-content-end flex-column flex-md-row align-items-md-center">
        <div v-if="displayData.length != 0" class="search_bar d-flex mt-md-0 w-100">
          <div class="contactBookDates me-auto">
            <div class="btn-group contactBookPage" role="group" aria-label="Basic example">
              <el-button type="button" class="selectDateBtn btn" v-if="setPermission(classRoomPermission) && $route.name == 'ChargeMeContactBook_ContactBook'">
                <el-dropdown class="" trigger="click">
                  <div class="black-shadow">
                    <span class="el-dropdown-link">
                      <i class="fal fa-plus"></i>
                    </span>
                  </div>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item type="text" @click="addContactBook">一般新增</el-dropdown-item>
                      <el-dropdown-item type="text" @click="getTemplate">使用範本</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </el-button>
              <template v-else>
                <p class="d-flex align-items-start flex-column justify-content-start">
                  <strong class="fw-bold me-2">{{ selectContactBookName }}</strong>
                  <span style="font-size: small"> {{ selectContactBookCreateDate }}</span>
                </p>
              </template>
            </div>
          </div>
          <el-dropdown trigger="click">
            <el-button class="contactBookdateBtn mb-2" circle><i class="fa-regular fa-ellipsis-vertical"></i> </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="messageReply()">留言</el-dropdown-item>
                <el-dropdown-item v-if="setPermission(classRoomPermission)" @click="createTemplate()">建立範本</el-dropdown-item>
                <el-dropdown-item v-if="setPermission(classRoomPermission)" @click="deleteContactBook()">刪除</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button class="contactBookdateBtn ms-2" circle @click="isCalendarModalShow = true">
            <i class="fa-light fa-calendar-days"></i>
          </el-button>
          <el-dropdown v-if="displayData.length > 1" trigger="click">
            <el-button class="contactBookdateBtn ms-2" circle>
              <i class="fa-light fa-list"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="(item, index) in displayData" :key="index" class="selectDateBtn" @click="updateSelectContactInfo(item)">
                  <div class="dataPageInfo">
                    <span>{{ item.createdTime }}</span>
                    <span>{{ item.title }}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button v-if="displayData != null && displayData.length > 1" class="contactBookdateBtn ms-2" circle @click="prevContactBook()">
            <i class="fa-light fa-left"></i>
          </el-button>
          <el-button v-if="displayData != null && displayData.length > 1" class="contactBookdateBtn ms-2" circle @click="nextContactBook()">
            <i class="fa-light fa-right"></i>
          </el-button>
        </div>
      </div>
      <template v-if="displayData.length == 0 && setPermission(classRoomPermission) && isLoaded">
        <el-card
          class="m-1"
          :body-style="{
            padding: '40px 0',
            width: '100%',
          }"
          shadow="hover"
          @click="addContactBook"
        >
          <i class="fad fa-plus-circle" style="--fa-primary-color: #ffffff; --fa-secondary-color: #f7e6b2; --fa-secondary-opacity: 1; font-size: 40px !important"></i>
          <p class="mt-3 fw-bold" style="color: rgb(81, 72, 50)">新增聯絡簿</p>
        </el-card>
      </template>
      <template v-if="$route.name == 'ChargeMeContactBook_ContactBook'">
        <ContactBook @updateCurrentContactName="updateCurrentContactName" :contactBookName="selectContactBookName" :contactBookCreateDate="selectContactBookCreateDate"> </ContactBook>
      </template>
      <template v-if="$route.name == 'ChargeMeContactBook_ContactBookView' || $route.name == 'ChargeMeContactBook_ContactBookViewById'">
        <ContactBookView> </ContactBookView>
      </template>
    </el-col>
  </el-row>

  <div class="showContactBookMessage">
    <el-drawer title="留言檢視" :direction="direction" size="100%" modal-class="drawer_tabs" v-model="isMessageShow" v-if="$store.getters.mobileSize">
      <ClassRoomContactMessage @close="isMessageShow = false" type="ContactBook" collapse="active" :memberOptions="messageMemberOptions" :contactBookId="messageContactBookId" :memberId="c_currentUser.id" />
    </el-drawer>

    <el-dialog v-else title="留言檢視" width="60%" v-model="isMessageShow">
      <ClassRoomContactMessage type="ContactBook" collapse="active" :memberOptions="messageMemberOptions" :contactBookId="messageContactBookId" :memberId="c_currentUser.id" />
    </el-dialog>
  </div>

  <div class="showCalendar">
    <el-dialog v-if="$store.getters.mobileSize" title="日曆檢視" width="100%" fullscreen="true" v-model="isCalendarModalShow">
      <CalendarContactBook :contactBookData="contactBookData" @updateSelectContactInfoFromCalendar="updateSelectContactInfoFromCalendar" />
    </el-dialog>

    <el-dialog v-else title="日曆檢視" width="40%" v-model="isCalendarModalShow">
      <CalendarContactBook :contactBookData="contactBookData" @updateSelectContactInfoFromCalendar="updateSelectContactInfoFromCalendar" />
    </el-dialog>
  </div>

  <el-dialog v-model="dialogContactBookTemplate" title="範本" width="80%">
    <el-collapse v-model="activeTemplateName" accordion>
      <el-collapse-item v-for="i in contactBookTemplate" :key="i" :title="i.name" :name="i.id">
        <div v-for="(j, index) in i.classContactTodoJson" :key="j" class="ps-1 mb-1 border-bottom">
          <el-form-item class="mb-0" :label="'項目_' + (index + 1)">
            <label>{{ j.title }}</label>
          </el-form-item>
          <el-form-item class="mb-0" label="描述">
            <p v-html="j.content"></p>
          </el-form-item>
          <el-form-item class="mb-0" label="檔案">
            <span v-for="(fileList, index) in j.fileList" :key="fileList.id">
              <el-button size="small" color="#dddddd">{{ fileList.name }}</el-button>
              <el-divider v-if="j.fileList.length != index + 1" direction="vertical" />
            </span>
          </el-form-item>
          <el-form-item class="mb-0" label="連結">
            <span v-for="(linkList, index) in j.linkList" :key="linkList.id">
              <el-link type="primary" :href="linkList.url">{{ linkList.title }}</el-link>
              <el-divider v-if="j.linkList.length != index + 1" direction="vertical" />
            </span>
          </el-form-item>
        </div>
        <div class="mt-1">
          <div class="d-flex">
            <el-input v-model="newTemplateName" placeholder="輸入新的範本名稱" clearable style="width: 200px" size="small" />
            <el-button class="ms-1" size="small" type="info" @click="editTemplateName(i.id)">更改名稱</el-button>
          </div>
          <div class="mt-2 text-end">
            <el-button class="ms-auto" size="small" type="warning" @click="delteContactBookByTemplate(i.id)">刪除</el-button>
            <el-button class="ms-1" size="small" type="primary" @click="addContactBookByTemplate(i.id)">使用</el-button>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </el-dialog>
</template>

<script>
const dayjs = require("dayjs");
var weekday = require("dayjs/plugin/weekday");
var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekday);
dayjs.extend(weekOfYear);

import ClassRoomContactMessage from "../ClassRoomContactMessage.vue";
import ContactBook from "../ContactBook/ContactBook.vue";
import ContactBookView from "../ContactBook/ContactBookView.vue";
import CalendarContactBook from "../ContactBook/CalendarContactBook.vue";

export default {
  name: "ClassRoomContactBook",
  components: {
    ClassRoomContactMessage,
    ContactBook,
    ContactBookView,
    CalendarContactBook,
  },
  props: ["classRoomPermission"],
  watch: {
    search(newVal) {
      if (newVal == "") this.backSearchStatus();
    },
    $route() {
      this.initCurrentContactBook();
    },
  },
  computed: {
    displayData() {
      const vm = this;
      return vm.contactBookData.contactBookList;
    },
    c_currentUser() {
      return this.currentUser();
    },
  },
  data() {
    return {
      selectContactBookCreateDate: "",
      selectContactBookName: "",
      selectContactBook: "",
      newTemplateName: "",
      dialogContactBookTemplate: false,
      activeTemplateName: "",
      contactBookTemplate: [],
      isMessageShow: false,
      messageContactBookId: 0,
      messageMemberOptions: [],
      memberOptions: [],
      page: 1,
      pageSize: 5,
      pageTotal: 0,
      search: "",
      searchPage: 0,
      searchFixRangePage: 4,
      showMoreDataButton: true,
      showSearchAllButton: true,
      contactBookData: {
        classRoomId: 0,
        classRoomName: "",
        startDate: null,
        endDate: null,
        contactBookList: [],
        backgroundColor: "",
        role: "",
      },
      backupContactBookListData: [],
      isLoaded: false,
      isCalendarModalShow: false,
    };
  },
  created() {
    const vm = this;
    vm.init();
  },

  methods: {
    init() {
      const vm = this;
      vm.initContactBookData();

      vm.initMemberData();
    },

    changeSetting(value) {
      this.$refs.childRefContactBook.changeSetting(value);
    },

    initMemberData() {
      const vm = this;

      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoom/Member/${vm.$route.params.id}`, {}, false)
        .then(res => {
          vm.memberOptions = [];
          vm.messageMemberOptions = res.data;

          res.data.forEach(x => {
            vm.memberOptions.push({
              value: vm.getMemberName(x.memberId, x.name),
              memberId: x.memberId,
              name: vm.getMemberName(x.memberId, x.name),
              nickName: x.nickName,
              avatar: x.avatar,
              role: x.role,
              email: x.email,
            });
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    initCurrentContactBook() {
      let vm = this;
      //如果沒有 就不要找
      if (vm.$route.params.contactBookId == null || vm.$route.params.contactBookId == undefined) {
        return;
      }
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/RouteName/${vm.$route.params.contactBookId}`, {}, false)
        .then(res => {
          console.log("initCurrentContactBook", res.data);
          vm.selectContactBook = res.data;
          vm.selectContactBookCreateDate = res.data.contactBookCreateDate;
          vm.selectContactBookName = res.data.contactBookName;

          vm.$emit("changeContactBookName", res.data.contactBookName);
        })
        .catch(err => {
          console.log(err);
        });
    },
    initContactBookData() {
      const vm = this;
      vm.isLoaded = false;

      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/${vm.$route.params.id}/${vm.searchPage * vm.pageSize}/${vm.pageSize * vm.searchFixRangePage}`, {}, false)
        .then(res => {
          var item = res.data;
          if (item.role == "") {
            item.role = vm.$route.params.role;
          }
          if (item.contactBookList.length != vm.pageSize * vm.searchFixRangePage) {
            vm.showMoreDataButton = false;
          }
          if (vm.searchPage == 0) {
            vm.contactBookData = {
              classRoomId: item.classRoomId,
              classRoomName: item.classRoomName,
              startDate: item.startDate == null ? null : new Date(item.startDate),
              endDate: item.endDate == null ? null : new Date(item.endDate),
              backgroundColor: item.backgroundColor,
              role: item.role,
              contactBookList: item.contactBookList,
            };
          } else {
            vm.contactBookData.contactBookList = [...vm.contactBookData.contactBookList, ...item.contactBookList];
          }

          vm.contactBookData.contactBookList.forEach(x => {
            x.weekOfYear = `[${dayjs(x.createdTime.split(" ")).week()}週]`;
          });
          vm.pageTotal = vm.contactBookData.contactBookList.length;
          if (vm.contactBookData.contactBookList.length == 0) {
            return;
          }

          let cid = vm.contactBookData.contactBookList[0].contactBookId;

          //不是老師的話 要轉view
          if (item.role.toLowerCase() != "teacher") {
            //否則一律到 聯絡簿檢視
            vm.$router.push({
              name: "ChargeMeContactBook_ContactBookView",
              params: {
                contactBookId: cid,
                role: item.role,
                id: vm.$route.params.id,
                todoId: vm.$route.params.todoId,
              },
            });
          } else {
            //是老師的話都
            //若有帶路由直接倒過去
            if (vm.$route.name == "ChargeMeContactBook_ContactBookViewById") {
              vm.$router.push({
                name: vm.$route.name,
                params: {
                  contactBookId: cid,
                  role: item.role,
                  id: vm.$route.params.id,
                  todoId: vm.$route.params.todoId,
                },
              });
            } else if (vm.$route.name == "ChargeMeContactBook_ContactBookView") {
              vm.$router.push({
                name: vm.$route.name,
                params: {
                  contactBookId: cid,
                  role: item.role,
                  id: vm.$route.params.id,
                  todoId: vm.$route.params.todoId,
                },
              });
            } else {
              vm.$router.push({
                name: "ChargeMeContactBook_ContactBook",
                params: {
                  contactBookId: cid,
                  role: item.role,
                  id: vm.$route.params.id,
                  todoId: vm.$route.params.todoId,
                },
              });
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          vm.isLoaded = true;
        });
    },
    updateCurrentContactName(value) {
      let vm = this;
      vm.selectContactBookName = value;
      vm.$emit("changeContactBookName", value);
    },
    prevContactBook() {
      let vm = this;
      const currentIndex = vm.displayData.findIndex(item => item.contactBookId == vm.$route.params.contactBookId);
      if (currentIndex !== -1 && currentIndex >= 1) {
        vm.updateSelectContactInfo(vm.displayData[currentIndex - 1]);
      } else {
        vm.$message({
          type: "info",
          message: "到底了!",
        });
        return null;
      }
    },
    nextContactBook() {
      let vm = this;

      const currentIndex = vm.displayData.findIndex(item => item.contactBookId == vm.$route.params.contactBookId);
      if (currentIndex !== -1 && currentIndex < vm.displayData.length - 1) {
        vm.updateSelectContactInfo(vm.displayData[currentIndex + 1]);
      } else {
        vm.$message({
          type: "info",
          message: "到底了!",
        });
        return null;
      }
    },
    ResetContactInfo() {
      let vm = this;
      vm.$router.push({
        name: "ChargeMeContactBook_ContactBook",
        params: {
          id: vm.$route.params.id,
          contactBookId: vm.contactBookData.contactBookList[0].contactBookId,
        },
      });
      vm.updateCurrentContactName;
    },
    updateSelectContactInfo(item) {
      let vm = this;
      vm.$store.state.workItem.task = null;
      vm.$router.push({
        name: vm.$route.name,
        params: {
          role: vm.$route.params.role,
          id: vm.$route.params.id,
          contactBookId: item.contactBookId,
        },
      });
      vm.selectContactBook = item;
      vm.selectContactBookCreateDate = item.createdTime;
      vm.selectContactBookName = item.title;

      vm.$emit("changeContactBookName", vm.selectContactBookName);
    },
    updateSelectContactInfoFromCalendar(value) {
      this.updateSelectContactInfo(value);
      this.isCalendarModalShow = false;
    },
    updateSelectContactBookId(item) {
      let vm = this;
      vm.$store.state.workItem.task = null;
      vm.$router.push({
        name: vm.$route.params.name,
        params: {
          role: vm.contactBookData.role,
          id: vm.$route.params.id,
          contactBookId: item.contactBookId,
        },
      });
    },
    editTemplateName(id) {
      const vm = this;
      vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/Template/${id}/${vm.newTemplateName}`).then(res => {
        if (res.data.status != 0) {
          this.$message({
            type: "error",
            message: "系統異常",
          });
        } else {
          vm.contactBookTemplate.find(x => x.id == id).name = vm.newTemplateName;
          this.$message({
            type: "success",
            message: "更新成功",
          });
          vm.newTemplateName = "";
        }
      });
    },
    delteContactBookByTemplate(id) {
      const vm = this;
      vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/Template/${id}`).then(res => {
        if (res.data.status != 0) {
          this.$message({
            type: "error",
            message: "系統異常",
          });
        } else {
          vm.contactBookTemplate = vm.contactBookTemplate.filter(x => x.id != id);
          this.$message({
            type: "success",
            message: "刪除成功",
          });
        }
        vm.dialogContactBookTemplate = false;
      });
    },
    async addContactBookByTemplate(id) {
      const vm = this;
      var isMembership = await vm.isMembershipExpired();
      // 確認會籍
      if (!isMembership) {
        // vm.notify("error", `會籍到期，請購買會籍後方可新增聯絡簿！`);
        vm.$confirm("親愛的會員，您的會籍已到期，需要升級為付費會員才能繼續使用，若原本即為付費會員，也請續約繳費，以延續付費功能！", "會籍到期", {
          confirmButtonText: "付費",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/ChargeMeContactBook/payment");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
            return;
          });
      } else {
        vm.$prompt("請輸入聯絡簿名稱", "新增聯絡簿", {
          confirmButtonText: "新增",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            //axios
            vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBookByTemplate/${vm.$route.params.id}/${id}?name=${value}`)
              .then(res => {
                var item = res.data;

                let newContactBook = {
                  contactBookId: item.contactBookId,
                  title: item.title,
                  createdTime: item.createdTime,
                  editedTime: item.editedTime,
                  weekOfYear: `[${dayjs(item.createdTime.split(" ")).week()}週]`,
                };

                vm.contactBookData.contactBookList.unshift(newContactBook);
                vm.selectContactBook = item;
                vm.selectContactBookCreateDate = item.createdTime;
                vm.selectContactBookName = item.title;
                vm.$emit("changeContactBookName", item.title);

                vm.$router.push({
                  name: "ChargeMeContactBook_ContactBook",
                  params: {
                    contactBookId: item.contactBookId,
                    id: vm.$route.params.id,
                  },
                });

                if (vm.showMoreDataButton) {
                  vm.contactBookData.contactBookList.splice(vm.pageTotal, 1);
                }

                vm.$message({
                  type: "success",
                  message: "新增成功!",
                });
              })
              .catch(err => {
                console.log(err.response.data);
                vm.notify("error", `新增失敗！`);
              });
            vm.dialogContactBookTemplate = false;
          })
          .catch(() => {
            vm.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    getTemplate() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/Template`).then(res => {
        if (res.data.length == 0) {
          this.$message({
            type: "info",
            message: "尚無建立範本",
          });
          return false;
        }
        vm.contactBookTemplate = res.data;
        vm.dialogContactBookTemplate = true;
      });
    },
    createTemplate() {
      const vm = this;
      vm.fetchAPI("POST", `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/Template/${vm.$route.params.contactBookId}`)
        .then(res => {
          if (res.data.status != 0) {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          } else {
            this.$message({
              type: "success",
              message: "新增成功",
            });
          }
        })
        .catch(err => {
          console.log(err);
          vm.notify("error", "刪除失敗！");
        });
    },
    sendMessage(id) {
      this.$store.state.isTalk = true;
      this.$store.commit("OpenMessageWin");
      this.$store.commit("CreateMessageGroup", id);
    },
    messageReply() {
      this.messageContactBookId = this.$route.params.contactBookId;
      this.isMessageShow = true;
    },
    backSearchStatus() {
      const vm = this;
      vm.showSearchAllButton = true;
      if (vm.contactBookData.contactBookList.length % (vm.pageSize * vm.searchFixRangePage) == 0) {
        vm.showMoreDataButton = true;
      }
      vm.search = "";
      vm.contactBookData.contactBookList = vm.backupContactBookListData;
      vm.contactBookData.contactBookList.forEach(x => {
        x.weekOfYear = `[${dayjs(x.createdTime.split(" ")).week()}週]`;
      });
      vm.pageTotal = vm.contactBookData.contactBookList.length;
    },
    async showMoreData() {
      const vm = this;
      vm.searchPage += vm.searchFixRangePage;
      await vm.initContactBookData();
    },
    async addContactBook() {
      const vm = this;
      var isMembership = await vm.isMembershipExpired();
      // 確認會籍
      if (!isMembership) {
        // vm.notify("error", `會籍到期，請購買會籍後方可新增聯絡簿！`);
        vm.$confirm("親愛的會員，您的會籍已到期，需要升級為付費會員才能繼續使用，若原本即為付費會員，也請續約繳費，以延續付費功能！", "會籍到期", {
          confirmButtonText: "付費",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/ChargeMeContactBook/payment");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
            return;
          });
      } else {
        vm.$prompt("請輸入聯絡簿名稱", "新增聯絡簿", {
          confirmButtonText: "新增",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            //axios
            vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/${vm.$route.params.id}?name=${value}`).then(res => {
              var item = res.data;

              let newContactBook = {
                contactBookId: item.contactBookId,
                title: item.title,
                createdTime: item.createdTime,
                editedTime: item.editedTime,
                weekOfYear: `[${dayjs(item.createdTime.split(" ")).week()}週]`,
              };

              vm.contactBookData.contactBookList.unshift(newContactBook);
              vm.selectContactBook = item;
              vm.selectContactBookCreateDate = item.createdTime;
              vm.selectContactBookName = item.title;
              vm.$emit("changeContactBookName", item.title);

              vm.$router.push({
                name: "ChargeMeContactBook_ContactBook",
                params: {
                  contactBookId: item.contactBookId,
                  id: vm.$route.params.id,
                },
              });

              if (vm.showMoreDataButton) {
                vm.contactBookData.contactBookList.splice(vm.pageTotal, 1);
              }

              vm.$message({
                type: "success",
                message: "新增成功!",
              });
            });
          })
          .catch(() => {
            vm.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    editContactBook(o) {
      const vm = this;
      this.$prompt(`請輸入名稱`, "修改名稱", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        inputValue: o.title,
      })
        .then(({ value }) => {
          //axios
          if (value == "" || value == null) {
            vm.notify("error", `聯絡簿名稱不可為空白！`);
            return;
          }
          const data = {
            contactBookId: o.contactBookId,
            contactBookName: value,
          };
          vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook`, data)
            .then(() => {
              var contactBook = vm.contactBookData.contactBookList.find(x => x.contactBookId == o.contactBookId);
              vm.$emit("changeContactBookName", value);
              vm.$refs.childRefContactBook.initData();
              contactBook.title = value;
              vm.$message({
                type: "success",
                message: `修改完成！`,
              });
            })
            .catch(err => {
              console.log(err);
              vm.notify("error", `修改失敗！`);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    deleteContactBook() {
      const vm = this;
      vm.$confirm("將從系統刪除聯絡簿, 是否繼續?", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            contactBookId: vm.$route.params.contactBookId,
          };
          vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook`, data)
            .then(() => {
              this.$message({
                type: "success",
                message: "刪除成功",
              });

              vm.contactBookData.contactBookList = vm.contactBookData.contactBookList.filter(item => item.contactBookId != data.contactBookId);

              if (vm.contactBookData.contactBookList.length > 0) {
                vm.updateSelectContactInfo(vm.contactBookData.contactBookList[0]);
              } else {
                this.$router.push(`/ChargeMeContactBook/ClassRoomCenter/ClassRoom/${vm.$route.params.id}`);
                vm.$emit("changeContactBookName", "");
              }
            })
            .catch(err => {
              console.log(err);
              vm.notify("error", "刪除失敗！");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contactBookPage {
  border-radius: 0 0 !important;
  button {
    font-size: xx-small;
  }
}
.contactBookdateBtn {
  height: 25px;
  width: 25px;
  text-align: center;
  border: 1px solid #77724b;
}
.search_bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.search_bar i {
  cursor: pointer;
}
.el-dropdown-link {
  position: relative;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 99em;
  text-align: center;
  line-height: 1.7;
  align-items: center;
}
.fa-times {
  line-height: 1.8;
}
.el-dropdown-link:hover {
  background: rgba(0, 0, 0, 0.3);
}
.fa-ellipsis-v:hover {
  color: #e0e0e0;
}
:deep .selectDateBtn.is-active {
  position: relative;
  background-color: white;
  color: #77724b;
}
:deep .el-tabs__item.is-active {
  color: $main_color;
}
:deep .el-tabs__active-bar {
  background-color: $main_color;
}
:deep .el-dialog__header {
  margin-right: 0;
}
.selectDateBtn {
  border-radius: 8px 8px 0 0;
  border-bottom: none;
  background-color: #f5f7fa;
  padding: 0.25rem;
  .dataPageInfo {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    font-size: x-small !important;
  }
}
</style>
