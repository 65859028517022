<template>
  <el-row :gutter="20" class="mb-3">
    <el-col :lg="20" :md="24" class="mx-auto mt-2">
      <el-card class="box-card mx-2" style="border-radius: 16px">
        <div class="clearfix">
          <div class="titleArea d-flex justify-content-between">
            <h3 class="className">
              <div class="fa-alarm-clockArea">
                <i class="fal fa-school"></i>
              </div>
              <span>教師/教室</span>
              <span class="ms-2">( 建立日期： {{ getDateFormat(classData.createdDateTime) }} )</span>
            </h3>
            <div v-if="setPermission(classRoomPermission)">
              <div v-if="!canClassInfoEdit">
                <el-button class="titleEditBtn" link>
                  <i class="fa-regular fa-pen-to-square" @click="changeToEdit(0)"></i>
                </el-button>
              </div>
              <div v-else>
                <i class="fa-regular fa-circle-xmark" @click="changeToOrigin(0)"></i>
                <i class="fa-regular fa-circle-check ms-2" @click="editCompleted(0)"></i>
              </div>
            </div>
          </div>
          <div class="otherInfo p-2 mt-2">
            <div class="info_item">
              <span class="me-2">課程代碼：</span>
              <span class="text">{{ classData.classRoomId }}</span>
            </div>
            <div class="info_item">
              <span class="me-2">科目老師名稱：</span>
              <span class="text">{{ classData.teacherName }}</span>
            </div>
            <div class="info_item">
              <span class="me-2">實體教室名稱：</span>
              <el-input v-if="canClassInfoEdit" v-model="classData.onsiteClassRoomName" placeholder="" :readonly="!canClassInfoEdit" :class="{ disActive: !canClassInfoEdit }" />
              <span class="text" v-else>{{ classData.onsiteClassRoomName }}</span>
            </div>
            <div class="info_item">
              <span class="me-2">實體教室地址：</span>
              <el-input v-if="canClassInfoEdit" v-model="classData.onsiteAddress" placeholder="" :readonly="!canClassInfoEdit" :class="{ disActive: !canClassInfoEdit }" />
              <span class="text" v-else>{{ classData.onsiteAddress }}</span>
            </div>
            <div class="info_item link">
              <span class="me-2">線上課程連結：</span>
              <el-input v-if="canClassInfoEdit" v-model="classData.onlineLink" placeholder="" :readonly="!canClassInfoEdit" :class="{ disActive: !canClassInfoEdit }" />
              <el-link v-else-if="classData.onlineLink != ''" :href="classData.onlineLink" target="_blank" type="primary">{{ classData.onlineLink }}</el-link>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box-card mx-2 mt-3" style="border-radius: 16px">
        <div class="clearfix">
          <div class="titleArea d-flex justify-content-between">
            <h3 class="className">
              <div class="fa-alarm-clockArea">
                <i class="fal fa-alarm-clock"></i>
              </div>
              <span>開課時間</span>
              <span class="ms-2" v-if="classData.startDate != null">( {{ getDateFormat(classData.startDate) }} ~ {{ getDateFormat(classData.endDate) }} )</span>
            </h3>
            <div v-if="setPermission(classRoomPermission)">
              <el-button class="titleEditBtn" link v-if="!canClassTimeEdit" @click="changeToEdit(1)">
                <i class="fa-regular fa-pen-to-square"></i>
              </el-button>
              <div class="d-flex" v-else>
                <i class="fa-solid fa-trash-can me-3" @click="deleteAllClassTime()"></i>
                <i class="fa-regular fa-circle-xmark" @click="changeToOriginClassTime()"></i>
              </div>
            </div>
          </div>
          <div class="otherInfo p-2 d-flex">
            <div
              class="classTime"
              :class="{
                active: classData.classTime.length != 0 || canClassTimeEdit,
              }"
            >
              <div v-if="canClassTimeEdit" class="mb-2">
                <div class="mb-1 d-flex startEndDate align-items-center w-100">
                  <div class="d-flex w-100 align-items-center me-3 mb-2">
                    <p style="width: 5rem; font-size: 0.9rem">開始日：</p>
                    <el-date-picker @change="dateChange" class="w-100 mb-0" v-model="classData.startDate" type="date" placeholder="選擇開始日期" />
                  </div>
                  <div class="d-flex w-100 align-items-center mb-2">
                    <p style="width: 5rem; font-size: 0.9rem">結束日：</p>
                    <el-date-picker @change="dateChange" class="w-100 mb-0" v-model="classData.endDate" type="date" placeholder="選擇結束日期" />
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="d-flex w-100">
                    <el-checkbox :disabled="!canDatePick" class="me-2" v-model="classData.isRepeat">重複</el-checkbox>
                    <el-select :disabled="!canDatePick" v-if="classData.isRepeat" class="mb-2 w-100" :persistent="false" v-model="classData.weekValue" multiple placeholder="選擇開課星期">
                      <el-option v-for="(item, index) in $store.state.common.weekOptions" :key="item.value" :label="item.label" :value="index + 1" />
                    </el-select>
                    <el-date-picker class="w-100 mb-2" v-else :disabledDate="disabledSingleDateFun" v-model="classData.singleDate" type="date" placeholder="選擇日期" />
                    <div v-if="!$store.getters.mobileSize" class="d-flex w-100 ms-2">
                      <el-time-select :disabled="!canDatePick" v-model="classData.startTime" class="mb-2 mb-md-0 me-md-2 w-75" placeholder="開始時間" start="00:00" :step="$store.state.classRoomTimeSelectStep" end="23:45" :clearable="false" @change="classDataStartDateChange" />
                      <el-time-select :disabled="!canDatePick" v-model="classData.endTime" :min-time="classData.startTime" class="mb-2 mb-md-0 w-75" placeholder="結束時間" :start="$store.state.classRoomTimeSelectStep" :step="$store.state.classRoomTimeSelectStep" end="24:00" :clearable="false" />
                      <el-button :disabled="!canDatePick" class="ms-md-2 w-100" @click="addWeekTime()" color="#ffd964">新增開課時間</el-button>
                    </div>
                  </div>
                </div>
                <div v-if="$store.getters.mobileSize" class="d-flex justify-content-between flex-column flex-md-row">
                  <div class="d-flex flex-column flex-md-row">
                    <el-time-select :disabled="!canDatePick" v-model="classData.startTime" class="mb-2 mb-md-0 me-md-2 w-100" placeholder="開始時間" start="00:00" :step="$store.state.classRoomTimeSelectStep" end="23:45" :clearable="false" @change="classDataStartDateChange" />
                    <el-time-select :disabled="!canDatePick" v-model="classData.endTime" :min-time="classData.startTime" class="mb-2 mb-md-0 w-100" placeholder="結束時間" :start="$store.state.classRoomTimeSelectStep" :step="$store.state.classRoomTimeSelectStep" end="24:00" :clearable="false" />
                    <el-button :disabled="classData.startDate == null" class="ms-md-2 w-100" @click="addWeekTime()" color="#ffd964">新增開課時間</el-button>
                  </div>
                </div>
              </div>
              <div v-if="!canClassTimeEdit" class="weekTimes">
                <el-collapse v-model="classTimeCollapse">
                  <el-collapse-item v-for="(tags, key, index) in displayDataClassTime" :key="key" :title="key" :name="index">
                    <el-popover :disabled="tag.remark == ''" v-for="tag in tags" :key="tag" placement="bottom-start" title="備註" :width="200" trigger="click" :content="tag.remark">
                      <template #reference>
                        <el-tag :class="{ change: tag.isChanged }" type="info" color="#b8b8b8" class="mx-1 mb-2" effect="dark">{{ getClassTimeTagShowText(tag) }}</el-tag>
                      </template>
                    </el-popover>
                  </el-collapse-item>
                </el-collapse>
                <div class="d-flex justify-content-end mt-2" v-if="classData.allClassTimeShowCount > classData.showLength">
                  <el-button circle v-if="classData.allClassTimeShowCount > classData.showLength && !classData.isShowMoreData" @click="classData.isShowMoreData = true">
                    <i class="fa-sharp fa-solid fa-chevron-down"></i>
                  </el-button>
                  <el-button circle v-else @click="classData.isShowMoreData = false">
                    <i class="fa-sharp fa-solid fa-chevron-up"></i>
                  </el-button>
                </div>
              </div>
              <div v-else class="weekTimes" :class="{ active: canClassTimeEdit }">
                <el-collapse v-model="classTimeEditCollapse">
                  <el-collapse-item v-for="(tags, key, index) in displayDataClassTime" :key="key" :title="key" :name="index">
                    <el-tag :class="{ change: tag.isChanged }" v-for="tag in tags" type="info" :key="tag" color="#b8b8b8" class="mx-1 mb-2" effect="dark" closable @click="showTagEditDialog(tag)" @close="handleClose(tag)">{{ getClassTimeTagShowText(tag) }}</el-tag>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
            <span class="mt-2" style="font-size: small" v-if="classData.classTime.length == 0 && !canClassTimeEdit">尚未設定...</span>
          </div>
        </div>
      </el-card>
      <el-card class="box-card mx-2 mt-3" style="border-radius: 16px">
        <div class="clearfix">
          <div class="titleArea d-flex justify-content-between">
            <h3 class="className">
              <div class="fa-alarm-clockArea">
                <i class="fal fa-bell"></i>
              </div>
              <span>公告欄</span>
            </h3>
            <div>
              <el-button class="titleEditBtn" link v-if="setPermission(classRoomPermission) && !addBillboard && !editBillboard" circle @click="addBillboard = true"><i class="fa-regular fa-plus"></i></el-button>
              <el-button class="ms-2 titleEditBtn" link v-if="setPermission(classRoomPermission) && !addBillboard && !editBillboard" circle @click="openEditBillboard()"><i class="fa-regular fa-pen-to-square"></i></el-button>
              <el-button class="ms-2 titleEditBtn" link v-if="setPermission(classRoomPermission) && !addBillboard && !editBillboard" circle @click="deleteBillboard(classData.announcementList[billboardIndex])"><i class="fa-regular fa-trash"></i></el-button>
            </div>
          </div>
          <div class="otherInfo p-2" style="font-size: xx-small">
            <div v-if="addBillboard">
              <el-form-item prop="">
                <Editor style="z-index: 1" :value="billboardEditor" @input="getEditorData" />
                <div class="w-100 pt-2 d-flex justify-content-end">
                  <el-button size="small" @click="addBillboard = false">取消</el-button>
                  <el-button type="primary" size="small" @click="addBillboardFunc()">儲存</el-button>
                </div>
              </el-form-item>
            </div>
            <div v-else-if="editBillboard">
              <el-form-item prop="">
                <Editor style="z-index: 1" :value="editBillboardEditor" @input="getEditEditorData" />
                <div class="w-100 pt-2 d-flex justify-content-end">
                  <el-button size="small" @click="editBillboard = false">取消</el-button>
                  <el-button type="primary" size="small" @click="editBillboardFunc()">修改</el-button>
                </div>
              </el-form-item>
            </div>
            <div v-else>
              <div v-if="classData.announcementList.length != 0">
                <div class="text-end mt-1 d-flex justify-content-between align-items-center">
                  <div>
                    <span><i class="fad fa-clock me-2"></i></span>
                    <span>{{ classData.announcementList[billboardIndex].createdTime }}</span>
                  </div>
                  <div class="text-center">
                    <el-button @click="changeBillboardIndex(true)" size="small" circle><i class="fal fa-angle-left"></i></el-button>
                    <el-button @click="changeBillboardIndex(false)" size="small" circle><i class="fal fa-angle-right"></i></el-button>
                  </div>
                </div>
                <div class="mt-2 p-1 text-start" v-html="classData.announcementList[billboardIndex].content"></div>
              </div>
              <div class="mt-2" v-else>
                <span>目前尚無公告...</span>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </el-col>
  </el-row>

  <el-dialog v-model="classData.isShowTagEditDialog" title="編輯備註" :width="!$store.getters.mobileSize ? '30%' : '80%'">
    <div class="d-flex">
      <el-date-picker :disabled="true" class="w-100 mb-2" :disabledDate="disabledSingleDateFun" v-model="editClassTimeTag.date" type="date" placeholder="選擇日期" />
    </div>
    <div class="d-flex mb-2">
      <el-time-select :disabled="true" v-model="editClassTimeTag.startTime" :max-time="editClassTimeTag.endTime" class="w-100 me-2" placeholder="開始時間" start="00:00" :step="$store.state.classRoomTimeSelectStep" end="23:30" />
      <el-time-select :disabled="true" v-model="editClassTimeTag.endTime" :min-time="editClassTimeTag.startTime" class="w-100" placeholder="結束時間" start="00:30" :step="$store.state.classRoomTimeSelectStep" end="24:00" />
    </div>
    <el-input v-model="editClassTimeTag.remark" placeholder="請輸入備註" type="textarea" :autosize="{ minRows: 6 }" />
    <el-button class="mt-2" @click="editWeekTime()">完成</el-button>
  </el-dialog>
</template>

<script>
import Editor from "../../../Common/Editor.vue";

const dayjs = require("dayjs");
var weekday = require("dayjs/plugin/weekday");
var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export default {
  name: "ClassRoomInfo",
  components: { Editor },
  props: ["classRoomPermission"],
  data() {
    return {
      canClassTimeEdit: false,
      canClassInfoEdit: false,
      billboardIndex: 0,
      addBillboard: false,
      editBillboard: false,
      billboardEditor: "",
      editBillboardEditor: "",
      backupClassInfoData: {},
      editClassTimeTag: {},
      classData: {
        classRoomId: 0,
        classRoomName: "",
        onsiteAddress: "",
        onsiteClassRoomName: "",
        onlineLink: "",
        weekValue: [],
        startDate: null,
        endDate: null,
        startTime: "",
        endTime: "",
        classTime: [],
        isShowMoreData: false,
        isShowTagEditDialog: false,
        showLength: 2,
        allClassTimeShowCount: 0,
        teacherName: "",
        createdDateTime: "",
        announcementList: [],
        isRepeat: true,
        singleDate: null,
      },
    };
  },
  created() {
    const vm = this;
    vm.initData();
  },
  watch: {
    "classData.startTime": function (newVal, oldVal) {
      var [oldStartHour, oldStartMinute] = oldVal.split(":").map(Number);
      var [newStartHour, newStartMinute] = newVal.split(":").map(Number);
      var [endHour, endMinute] = this.classData.endTime.split(":").map(Number);

      var oldStartTimeInMinutes = oldStartHour * 60 + oldStartMinute;
      var newStartTimeInMinutes = newStartHour * 60 + newStartMinute;
      var endTimeInMinutes = endHour * 60 + endMinute;

      if (newStartTimeInMinutes >= endTimeInMinutes) {
        var timeDifference = newStartTimeInMinutes - oldStartTimeInMinutes;
        var temp = dayjs("00:00:00", "HH:mm:ss").minute(endHour * 60 + endMinute + timeDifference);
        if (temp.$D == dayjs().$D) this.classData.endTime = temp.format("HH:mm");
        else this.classData.endTime = "00:00";
      }
    },
  },
  computed: {
    classTimeEditCollapse() {
      return Array.from({ length: this.classData.allClassTimeShowCount }, (_, index) => index);
    },
    classTimeCollapse() {
      return Array.from({ length: Object.keys(this.displayDataClassTime).length }, (_, index) => index);
    },
    displayDataClassTime() {
      const vm = this;
      if (vm.classData.startDate == null) return [];
      var groupedData = vm.classData.classTime.reduce(function (result, current) {
        var mon = dayjs(current.date).weekday(1);
        var sun = dayjs(current.date).weekday(7);
        if (dayjs(current.date).day() == 0) {
          mon = dayjs(current.date).weekday(1 - 7);
          sun = dayjs(current.date).weekday(7 - 7);
        }
        var temp = `${mon.$y}/${mon.$M + 1}/${mon.$D} ~ ${sun.$y}/${sun.$M + 1}/${sun.$D}`;
        if (!Array.isArray(result[temp])) result[temp] = [];
        result[temp].push(current);
        return result;
      }, {});
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      vm.classData.allClassTimeShowCount = Object.keys(groupedData).length;
      if (vm.canClassTimeEdit) return groupedData;
      if (!vm.classData.isShowMoreData) {
        var keys = Object.keys(groupedData);
        var result = {};
        for (var i = 0; i < Math.min(vm.classData.showLength, keys.length); i++) {
          var key = keys[i];
          result[key] = groupedData[key];
        }
        return result;
      }
      return groupedData;
    },
    canDatePick() {
      return this.classData.startDate != null && this.classData.endDate != null && (dayjs(this.classData.startDate).isBefore(dayjs(this.classData.endDate)) || dayjs(this.classData.startDate).isSame(dayjs(this.classData.endDate)));
    },
  },
  methods: {
    initData() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoom/Info?classRoomId=${vm.$route.params.id}`)
        .then(res => {
          var item = res.data;
          vm.classData = {
            classRoomId: item.classRoomId,
            classRoomName: item.classRoomName,
            onsiteAddress: item.onsiteAddress,
            onsiteClassRoomName: item.onsiteClassRoomName,
            onlineLink: item.onlineLink,
            weekValue: [],
            startTime: "",
            endTime: "",
            classTime: [],
            startDate: item.startDate == null ? null : new Date(item.startDate),
            endDate: item.endDate == null ? null : new Date(item.endDate),
            teacherName: item.teacherName,
            createdDateTime: item.createdDateTime,
            announcementList: item.announcementList,
            isRepeat: true,
            singleDate: null,
            isShowMoreData: false,
            showLength: 2,
            allClassTimeShowCount: 0,
          };

          if (item.announcementList.length > 1) vm.billboardIndex = item.announcementList.length - 1;
          vm.loadClassTime();
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {});
    },
    loadClassTime() {
      const vm = this;
      vm.classData.classTime = [];
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoom/ClassTime/${vm.$route.params.id}`, {}, false)
        .then(res => {
          Object.assign(vm.classData.classTime, res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    getDateFormat(x) {
      return dayjs(x).format("YYYY/MM/DD");
    },
    editCompleted(x) {
      const vm = this;
      let data = {
        ClassRoomId: vm.classData.classRoomId,
        OnsiteAddress: vm.classData.onsiteAddress,
        OnsiteClassRoomName: vm.classData.onsiteClassRoomName,
        OnlineLink: vm.classData.onlineLink,
        StartDate: vm.classData.startDate == null ? null : dayjs(vm.classData.startDate).add(8, "hour"),
        EndDate: vm.classData.endDate == null ? null : dayjs(vm.classData.endDate).add(8, "hour"),
      };
      vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/ClassRoom/Info`, data)
        .then(res => {
          console.log(res.data);
          vm.$message({
            type: "success",
            message: `更新完成！`,
          });
        })
        .catch(err => {
          console.log(err);
          Object.assign(vm.classData, vm.backupClassInfoData);
          vm.notify("error", `更新失敗！`);
        })
        .finally(() => {
          if (x == 0) this.canClassInfoEdit = false;
          else if (x == 2) vm.loadClassTime();
        });
    },
    changeToEdit(x) {
      Object.assign(this.backupClassInfoData, this.classData);
      if (x == 0) this.canClassInfoEdit = true;
      else if (x == 1) {
        this.classData.startTime = this.closeTime(dayjs().format("HH:mm"));
        this.classData.endTime = this.closeTime(dayjs().add(1, "hour").format("HH:mm"));
        this.canClassTimeEdit = true;
      }
    },
    changeToOrigin(x) {
      Object.assign(this.classData, this.backupClassInfoData);
      if (x == 0) this.canClassInfoEdit = false;
      else if (x == 1) this.canClassTimeEdit = false;
    },
    closeTime(time) {
      var hour = time.split(":")[0];
      var min = time.split(":")[1];
      if (min == "30" || min == "00") return time;
      else if (min < "30") min = "30";
      else {
        min = "00";
        hour = (Number(hour) + 1).toString().padStart(2, "0");
        if (hour == "24") hour = "00";
      }
      return `${hour}:${min}`;
    },
    getClassTimeTagShowText(item) {
      var week = ["日", "一", "二", "三", "四", "五", "六"];
      var temp = dayjs(item.date).format("YYYY/MM/DD");
      return `${temp.split(" ")[0]} (${week[dayjs(item.date).day()]}) ${item.startTime}~${item.endTime}`;
    },
    dateChange() {
      const vm = this;
      if (this.classData.startDate != null && this.classData.endDate != null && !(dayjs(this.classData.startDate).isBefore(dayjs(this.classData.endDate)) || dayjs(this.classData.startDate).isSame(dayjs(this.classData.endDate)))) {
        vm.notify("warning", `請確認日期區間是否正確！`);
      }

      var reloadClassTime = 2;
      vm.editCompleted(reloadClassTime);
    },
    calendarChange(dates) {
      const vm = this;
      let hasSelectDate = dates != null && dates.length > 0;
      vm.classData.startDate = hasSelectDate ? dates[0] : null;
      vm.classData.endDate = hasSelectDate ? dates[1] : null;
    },
    disabledStartEndDateFun(time) {
      const vm = this;
      if (vm.classData.startDate != null && vm.classData.endDate == null) {
        if (dayjs(vm.classData.startDate).format("YYYY") == "1901") return;
        return time.getTime() < vm.classData.startDate.getTime() || time.getTime() > vm.classData.startDate.getTime() + 183 * 8.64e7;
      }
    },
    addBillboardFunc() {
      const vm = this;
      //axios
      const data = {
        classRoomId: vm.$route.params.id,
        content: vm.billboardEditor,
      };
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/Announcement`, data)
        .then(res => {
          var item = res.data;
          vm.classData.announcementList.push({
            id: item.announcementId,
            content: item.content,
            createdTime: item.createdTime,
          });
          vm.$message({
            type: "success",
            message: "新增成功!",
          });
          vm.addBillboard = false;
          vm.billboardEditor = "";
          vm.$nextTick(() => {
            vm.billboardIndex = vm.classData.announcementList.length - 1;
            if (this.classData.announcementList.length == 1) this.billboardIndex = 0;
          });
        })
        .catch(() => {
          vm.notify("error", `新增失敗！`);
        });
    },
    openEditBillboard() {
      this.editBillboard = true;
      this.editBillboardEditor = this.classData.announcementList[this.billboardIndex].content;
    },
    editBillboardFunc() {
      const vm = this;
      var item = vm.classData.announcementList[vm.billboardIndex];
      //axios
      const data = {
        announcementId: item.announcementId,
        content: vm.editBillboardEditor,
      };
      vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/Announcement`, data)
        .then(() => {
          item.content = vm.editBillboardEditor;
          vm.$message({
            type: "success",
            message: "修改成功!",
          });
          vm.editBillboard = false;
          vm.editBillboardEditor = "";
        })
        .catch(() => {
          vm.notify("error", `修改失敗！`);
        });
    },
    deleteBillboard(item) {
      const vm = this;
      vm.$confirm("是否刪除本則公告?", "刪除本則公告", {
        confirmButtonText: "確認",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            AnnouncementId: item.announcementId,
          };
          vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/Announcement`, data)
            .then(() => {
              vm.classData.announcementList = vm.classData.announcementList.filter(x => x.id != item.id);
              vm.$message({
                type: "success",
                message: "刪除成功!",
              });
            })
            .catch(() => {
              vm.notify("error", `刪除失敗！`);
            });
        })
        .catch(() => {});
    },
    getEditorData(input) {
      this.billboardEditor = input;
    },
    getEditEditorData(input) {
      this.editBillboardEditor = input;
    },
    changeBillboardIndex(isAdd) {
      if (isAdd) this.billboardIndex += 1;
      else this.billboardIndex -= 1;

      if (this.billboardIndex < 0) this.billboardIndex = this.classData.announcementList.length - 1;
      else if (this.billboardIndex == this.classData.announcementList.length) this.billboardIndex = 0;
    },
    disabledSingleDateFun(time) {
      const vm = this;
      if (vm.classData.startDate != null) {
        if (dayjs(vm.classData.startDate).format("YYYY") == "1901") return;
        return new Date(dayjs(time)).getTime() < vm.classData.startDate.getTime() || new Date(dayjs(time)).getTime() > vm.classData.endDate.getTime();
      }
    },
    editWeekTime() {
      const vm = this;
      let data = {
        ClassTimeId: vm.editClassTimeTag.id,
        Date: vm.editClassTimeTag.date,
        StartTime: vm.editClassTimeTag.startTime,
        EndTime: vm.editClassTimeTag.endTime,
        Remark: vm.editClassTimeTag.remark,
      };
      vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/ClassRoom/ClassTime`, data)
        .then(res => {
          console.log(res.data);
          var temp = vm.classData.classTime.find(x => x.id == data.ClassTimeId);
          temp.date = data.Date;
          temp.startTime = data.StartTime;
          temp.endTime = data.EndTime;
          temp.remark = data.Remark;
          if (data.Remark != "") temp.isChanged = true;
          else temp.isChanged = false;
          vm.classData.isShowTagEditDialog = false;
          vm.editClassTimeTag = {};
          vm.$message({
            type: "success",
            message: `更新完成！`,
          });
        })
        .catch(err => {
          console.log(err);
          vm.notify("error", `更新失敗！`);
        });
    },
    addWeekTime() {
      const vm = this;
      var classTime = {};
      var classRoomList = {
        date: [],
        startTime: "",
        endTime: "",
      };
      console.log(vm.classData.startTime);
      if (vm.classData.startDate == null || vm.classData.endDate == null) {
        vm.notify("warning", "請先選擇好開始日期與結束日期！");
        return;
      }
      console.log(vm.classData.singleDate);
      if (vm.classData.startTime == "" || vm.classData.endTime == "" || (vm.classData.isRepeat && vm.classData.weekValue.length == 0) || (!vm.classData.isRepeat && vm.classData.singleDate == null)) {
        vm.notify("warning", "請先選擇好日期與時間！");
        return;
      }

      if (vm.classData.isRepeat) {
        var dates = vm.getDateRangeResult(vm.classData.weekValue);
        dates.forEach(x => {
          var findDate = vm.classData.classTime.find(y => new Date(dayjs(y.date).add(8, "hour")).toString() == new Date(x).toString() && vm.classData.startTime == y.startTime && vm.classData.endTime == y.endTime);
          if (findDate == undefined) classRoomList.date.push(x);
        });
        classRoomList.startTime = vm.classData.startTime;
        classRoomList.endTime = vm.classData.endTime;
      } else {
        var singleDate = new Date(dayjs(vm.classData.singleDate).add(8, "hour"));
        var findDate = vm.classData.classTime.find(y => new Date(dayjs(y.date).add(8, "hour")).toString() == singleDate.toString() && vm.classData.startTime == y.startTime && vm.classData.endTime == y.endTime);
        if (findDate == undefined) {
          classTime = {
            Date: singleDate,
            StartTime: vm.classData.startTime,
            EndTime: vm.classData.endTime,
          };
        } else return;
      }

      let data = {
        ClassRoomId: vm.classData.classRoomId,
        ClassTime: classTime,
        ClassTimeList: classRoomList,
        IsRepeat: vm.classData.isRepeat,
      };
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/ClassRoom/ClassTime`, data)
        .then(res => {
          console.log(res.data);
          res.data.forEach(x => {
            x.date = x.date.slice(0, -1);
            vm.classData.classTime.push(x);
          });
          vm.classData.classTime.sort(function (a, b) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            if (a.startTime < b.startTime) return -1;
            if (a.startTime > b.startTime) return 1;
            if (a.endTime < b.endTime) return -1;
            if (a.endTime > b.endTime) return 1;
          });
          vm.$message({
            type: "success",
            message: `新增完成！`,
          });
          console.log(vm.classData.classTime);
        })
        .catch(err => {
          console.log(err);
          Object.assign(vm.classData, vm.backupClassInfoData);
          vm.notify("error", `新增失敗！`);
        });
    },
    getDateRangeResult(weekDay) {
      const vm = this;
      var newArray = weekDay.map(x => {
        if (x == 7) {
          return 0;
        } else {
          return x;
        }
      });
      var resultDates = [];
      var currentDate = new Date(vm.classData.startDate.getTime());
      while (currentDate <= vm.classData.endDate) {
        var dayOfWeek = currentDate.getDay();
        if (newArray.includes(dayOfWeek)) {
          resultDates.push(dayjs(currentDate).add(8, "hour"));
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      console.log(resultDates);
      return resultDates;
    },
    handleClose(tag) {
      const vm = this;
      let data = {
        classRoomId: vm.classData.classRoomId,
        classTimeIdList: [tag.id],
        isDeleteAll: false,
      };
      vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/ClassRoom/ClassTime`, data)
        .then(res => {
          console.log(res);
          vm.classData.classTime = vm.classData.classTime.filter(x => x.id != tag.id);
          vm.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          vm.notify("error", `删除失敗！`);
        });
    },
    showTagEditDialog(tag) {
      this.editClassTimeTag = tag;
      this.classData.isShowTagEditDialog = true;
    },
    changeToOriginClassTime() {
      const vm = this;
      vm.canClassTimeEdit = false;
      vm.classData.weekValue = [];
      vm.classData.singleDate = null;
    },
    deleteAllClassTime() {
      const vm = this;
      vm.$confirm("是否刪除所有開課時間?", "警告-刪除所有開課時間", {
        confirmButtonText: "確認",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            classRoomId: vm.classData.classRoomId,
            classTimeIdList: null,
            isDeleteAll: true,
          };
          vm.fetchAPI("delete", `${process.env.VUE_APP_URL_API}/ClassRoom/ClassTime`, data)
            .then(res => {
              console.log(res);
              vm.classData.classTime = [];
              vm.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              vm.notify("error", `删除失敗！`);
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.titleArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  .className {
    display: flex;
    align-items: center;
    .fa-alarm-clockArea {
      margin-right: 0.5rem;

      height: 36px;
      width: 36px;
      line-height: 36px;
      border-radius: 99em;
      background-color: $main_color;
      text-align: center;
      color: white;
    }
    text-align: left;
    font-weight: 500;
    letter-spacing: -0.05em;
  }
  i {
    cursor: pointer;
    font-size: 1.1rem;
  }
  .editButtonGroup {
    button {
      width: 0.5rem;
    }
  }
}
.titleEditBtn {
  background-color: #96beab !important;
  border-radius: 99em;
  height: 25px;
  width: 25px;
  text-align: center;

  i {
    color: white;
    font-size: small !important;
  }
}
.otherInfo {
  text-align: start;
  .classTeacher {
    text-align: left;
    margin-bottom: 1rem;
  }
}
.otherInfo .startEndDate :deep .el-input__wrapper {
  width: auto;
}
.info_item {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  font-size: small;
  span {
    white-space: nowrap;
  }
  .text,
  .el-link {
    font-size: 0.8rem;
    text-align: start;
  }
}
.weekTimes {
  padding-top: 0.5rem;
  :deep .el-tag {
    border: none !important;
    min-width: 8.5rem !important;
    background-color: #398d69 !important;
    cursor: default;
    &.change {
      background: #ddbc59 !important;
      cursor: pointer !important;
    }
  }
  &.active {
    :deep .el-tag {
      cursor: pointer;
    }
  }
}
.classTime.active {
  width: 100%;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
:deep .el-collapse {
  border-top: none;
  .el-collapse-item {
    flex-grow: 1;
    .el-collapse-item__arrow {
      margin-left: 1rem;
    }
    .el-collapse-item {
      padding: 0.5rem 0.25rem;
    }
  }
}
:deep .el-input.disActive {
  border:none .el-input__inner {
    cursor: default;
    margin: 0;
  }
  .el-input__wrapper {
    box-shadow: none;
    padding: 0;
    border: none;
    height: 0 !important;
  }
}
@media screen and (max-width: 768px) {
  :deep .el-collapse {
    .el-collapse-item {
      .el-collapse-item__content {
        padding-bottom: 0;
      }
      .el-collapse-item__header,
      .el-collapse-item__wrap {
        border: none;
      }
    }
  }
  .otherInfo {
    .classTime .notice {
      margin: 0.8rem 0 0.5rem 0;
      margin-left: 0 !important;
    }
    .startEndDate {
      flex-direction: column !important;
      align-items: flex-start !important;
      :deep .el-input__wrapper {
        width: 100%;
      }
    }
  }
}
</style>
