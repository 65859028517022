<template>
  <div class="d-flex flex-column text-start">
    <div class="my-3 ms-3">
      <div class="d-flex flex-column inform_part">
        <p class="mb-2">Line通知剩餘點數：{{ memberInfo.lineBotPoint }} 點</p>
        <p v-if="!$store.getters.mobileSize">&nbsp;/&nbsp;</p>
        <p class="mb-2">Line呼叫次數：{{ memberInfo.allLineBotRequestCount }} 次(總) ; {{ memberInfo.lineBotRequestCount }}次(本課)</p>
      </div>
    </div>
    <div class="contactBook_setting mb-3">
      <p class="mb-2">聯絡簿設定</p>
      <el-collapse v-model="contactBookSettingActiveName" class="defaultSetting" accordion>
        <el-collapse-item title="開啟老師完成勾選框" name="0">
          <el-radio-group :disabled="!isPrimaryTeacher" v-model="classRoomSetting.isContactTodoCompleteCheckedSettingOn" @change="changeClassRoomSetting()">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="inform_setting">
      <p class="mb-2">通知設定</p>
      <el-collapse v-model="informSettingActiveName" class="defaultSetting" accordion>
        <el-collapse-item title="通知方式" name="0">
          <div class="mx-3 inform_group">
            <el-checkbox-group :disabled="!isPrimaryTeacher" v-model="informSetting.informMethod" @change="changeInformSetting()">
              <el-checkbox label="Line">Line</el-checkbox>
              <el-checkbox label="Email">Email</el-checkbox>
              <el-checkbox label="App">App(官方機器人)</el-checkbox>
            </el-checkbox-group>
          </div>
        </el-collapse-item>
        <el-collapse-item title="(即時) 新作業通知" name="1">
          <div class="mx-3 inform_group">
            <el-checkbox-group :disabled="!isPrimaryTeacher" v-model="informSetting.informNewTask" @change="changeInformSetting()">
              <el-checkbox label="Student">學生</el-checkbox>
              <el-checkbox label="Parent">家長</el-checkbox>
              <el-checkbox label="Teacher">老師</el-checkbox>
            </el-checkbox-group>
          </div>
        </el-collapse-item>
        <el-collapse-item title="(定時) 聯絡簿尚未簽名通知" name="2">
          <div class="mx-3 inform_group d-flex">
            <div>
              <el-checkbox-group :disabled="!isPrimaryTeacher" v-model="informSetting.informContactBookUnsigned" @change="changeInformSetting()">
                <el-checkbox label="Student">學生</el-checkbox>
                <el-checkbox label="Parent">家長</el-checkbox>
                <el-checkbox label="Teacher">老師</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="frequency">
              <el-button class="mb-2" :disabled="!isPrimaryTeacher" @click="informNow('informContactBookUnsigned')" type="primary">即時通知</el-button>
              <div class="d-flex flex-column justify-content-start align-items-start flex-lg-row">
                <div class="w-100 d-flex align-items-center mb-2 me-lg-2">
                  <span>每</span>
                  <el-select :disabled="!isPrimaryTeacher" @change="changeFrequency('informContactBookUnsigned')" v-model="informSettingFrequency.informContactBookUnsigned.timeFrequency" class="ms-2 w-100" placeholder="時間頻率">
                    <el-option v-for="item in informSettingFrequencySelect.timeFrequency" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </div>
                <div class="w-100 d-flex align-items-center me-lg-2" v-if="informSettingFrequency.informContactBookUnsigned.timeFrequency == 'month'">
                  <el-input :disabled="!isPrimaryTeacher" @change="changeFrequency('informContactBookUnsigned')" class="w-100 text-end me-2 mb-2 mb-lg-0" v-model="informSettingFrequency.informContactBookUnsigned.monthDay" @input="informSettingFrequency.informContactBookUnsigned.monthDay = turnMonthNumber(informSettingFrequency.informContactBookUnsigned.monthDay)"></el-input>
                  <span>號</span>
                </div>
                <el-select :disabled="!isPrimaryTeacher" @change="changeFrequency('informContactBookUnsigned')" v-if="informSettingFrequency.informContactBookUnsigned.timeFrequency == 'week'" v-model="informSettingFrequency.informContactBookUnsigned.weekDay" class="mb-2 w-100 me-lg-2" placeholder="星期">
                  <el-option v-for="item in informSettingFrequencySelect.week" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-time-select :disabled="!isPrimaryTeacher" @change="changeFrequency('informContactBookUnsigned')" class="w-100" v-model="informSettingFrequency.informContactBookUnsigned.time" placeholder="時間" start="00:00" :step="$store.state.classRoomTimeSelectStep" end="23:30" />
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="(定時) 逾期作業通知" name="3">
          <div class="mx-3 inform_group d-flex">
            <div>
              <el-checkbox-group :disabled="!isPrimaryTeacher" v-model="informSetting.informDueTask" @change="changeInformSetting()">
                <el-checkbox label="Student">學生</el-checkbox>
                <el-checkbox label="Parent">家長</el-checkbox>
                <el-checkbox label="Teacher">老師</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="frequency">
              <el-button class="mb-2" :disabled="!isPrimaryTeacher" @click="informNow('informDueTask')" type="primary">即時通知</el-button>
              <div class="d-flex flex-column justify-content-start align-items-start flex-lg-row">
                <div class="w-100 d-flex align-items-center mb-2 me-lg-2">
                  <span>每</span>
                  <el-select :disabled="!isPrimaryTeacher" @change="changeFrequency('informDueTask')" v-model="informSettingFrequency.informDueTask.timeFrequency" class="ms-2 w-100" placeholder="時間頻率">
                    <el-option v-for="item in informSettingFrequencySelect.timeFrequency" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </div>
                <div class="w-100 d-flex align-items-center me-lg-2" v-if="informSettingFrequency.informDueTask.timeFrequency == 'month'">
                  <el-input :disabled="!isPrimaryTeacher" @change="changeFrequency('informDueTask')" class="w-100 text-end me-2 mb-2 mb-lg-0" v-model="informSettingFrequency.informDueTask.monthDay" @input="informSettingFrequency.informDueTask.monthDay = turnMonthNumber(informSettingFrequency.informDueTask.monthDay)"></el-input>
                  <span>號</span>
                </div>
                <el-select :disabled="!isPrimaryTeacher" @change="changeFrequency('informDueTask')" v-if="informSettingFrequency.informDueTask.timeFrequency == 'week'" v-model="informSettingFrequency.informDueTask.weekDay" class="mb-2 w-100 me-lg-2" placeholder="星期">
                  <el-option v-for="item in informSettingFrequencySelect.week" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-time-select :disabled="!isPrimaryTeacher" @change="changeFrequency('informDueTask')" class="w-100" v-model="informSettingFrequency.informDueTask.time" placeholder="時間" start="00:00" :step="$store.state.classRoomTimeSelectStep" end="23:30" />
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="(定時) 繳費通知" name="4">
          <div class="mx-3 inform_group d-flex">
            <div>
              <el-checkbox-group :disabled="!isPrimaryTeacher" v-model="informSetting.informPayment" @change="changeInformSetting()">
                <el-checkbox label="Student">學生</el-checkbox>
                <el-checkbox label="Parent">家長</el-checkbox>
                <el-checkbox label="Teacher">老師</el-checkbox>
              </el-checkbox-group>
              <div class="mt-2">
                <span>小於</span>
                <el-input :disabled="!isPrimaryTeacher" @change="changeInformSetting()" class="w-50 mx-2" v-model="informSetting.informPaymentClassCount" @input="informSetting.informPaymentClassCount = turnNumberAllowEmpty(informSetting.informPaymentClassCount, minPaymentClassCount)"></el-input>
                <span>堂課程通知</span>
              </div>
            </div>
            <div class="frequency">
              <el-button class="mb-2" :disabled="!isPrimaryTeacher" @click="informNow('informPayment')" type="primary">即時通知</el-button>
              <div class="d-flex flex-column justify-content-start align-items-start flex-lg-row">
                <div class="w-100 d-flex align-items-center mb-2 me-lg-2">
                  <span>每</span>
                  <el-select :disabled="!isPrimaryTeacher" @change="changeFrequency('informPayment')" v-model="informSettingFrequency.informPayment.timeFrequency" class="ms-2 w-100" placeholder="時間頻率">
                    <el-option v-for="item in informSettingFrequencySelect.timeFrequency" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </div>
                <div class="w-100 d-flex align-items-center me-lg-2" v-if="informSettingFrequency.informPayment.timeFrequency == 'month'">
                  <el-input :disabled="!isPrimaryTeacher" @change="changeFrequency('informPayment')" class="w-100 text-end me-2 mb-2 mb-lg-0" v-model="informSettingFrequency.informPayment.monthDay" @input="informSettingFrequency.informPayment.monthDay = turnMonthNumber(informSettingFrequency.informPayment.monthDay)"></el-input>
                  <span>號</span>
                </div>
                <el-select :disabled="!isPrimaryTeacher" @change="changeFrequency('informPayment')" v-if="informSettingFrequency.informPayment.timeFrequency == 'week'" v-model="informSettingFrequency.informPayment.weekDay" class="mb-2 w-100 me-lg-2" placeholder="星期">
                  <el-option v-for="item in informSettingFrequencySelect.week" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-time-select :disabled="!isPrimaryTeacher" @change="changeFrequency('informPayment')" class="w-100" v-model="informSettingFrequency.informPayment.time" placeholder="時間" start="00:00" :step="$store.state.classRoomTimeSelectStep" end="23:30" />
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="(定時) 欠費通知" name="5">
          <div class="mx-3 inform_group d-flex">
            <div>
              <el-checkbox-group :disabled="!isPrimaryTeacher" v-model="informSetting.informOwePayment" @change="changeInformSetting()">
                <el-checkbox label="Student">學生</el-checkbox>
                <el-checkbox label="Parent">家長</el-checkbox>
                <el-checkbox label="Teacher">老師</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="frequency">
              <el-button class="mb-2" :disabled="!isPrimaryTeacher" @click="informNow('informOwePayment')" type="primary">即時通知</el-button>
              <div class="d-flex flex-column justify-content-start align-items-start flex-lg-row">
                <div class="w-100 d-flex align-items-center mb-2 me-lg-2">
                  <span>每</span>
                  <el-select :disabled="!isPrimaryTeacher" @change="changeFrequency('informOwePayment')" v-model="informSettingFrequency.informOwePayment.timeFrequency" class="ms-2 w-100" placeholder="時間頻率">
                    <el-option v-for="item in informSettingFrequencySelect.timeFrequency" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </div>
                <div class="w-100 d-flex align-items-center me-lg-2" v-if="informSettingFrequency.informOwePayment.timeFrequency == 'month'">
                  <el-input :disabled="!isPrimaryTeacher" @change="changeFrequency('informOwePayment')" class="w-100 text-end me-2 mb-2 mb-lg-0" v-model="informSettingFrequency.informOwePayment.monthDay" @input="informSettingFrequency.informOwePayment.monthDay = turnMonthNumber(informSettingFrequency.informOwePayment.monthDay)"></el-input>
                  <span>號</span>
                </div>
                <el-select :disabled="!isPrimaryTeacher" @change="changeFrequency('informOwePayment')" v-if="informSettingFrequency.informOwePayment.timeFrequency == 'week'" v-model="informSettingFrequency.informOwePayment.weekDay" class="mb-2 w-100 me-lg-2" placeholder="星期">
                  <el-option v-for="item in informSettingFrequencySelect.week" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-time-select :disabled="!isPrimaryTeacher" @change="changeFrequency('informOwePayment')" class="w-100" v-model="informSettingFrequency.informOwePayment.time" placeholder="時間" start="00:00" :step="$store.state.classRoomTimeSelectStep" end="23:30" />
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="(定時) 上課提醒通知" name="6">
          <div class="mx-3 inform_group d-flex">
            <div>
              <el-checkbox-group :disabled="!isPrimaryTeacher" v-model="informSetting.informComingClass" @change="changeInformSetting()">
                <el-checkbox label="Student">學生</el-checkbox>
                <el-checkbox label="Parent">家長</el-checkbox>
                <el-checkbox label="Teacher">老師</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="frequency">
              <div class="d-flex flex-column justify-content-start align-items-start flex-lg-row">
                <div class="w-100 d-flex align-items-center mb-2 me-lg-2">
                  <div style="white-space: nowrap">提前</div>
                  <div class="ms-2 w-100">
                    <el-input-number class="w-100" :step-strictly="true" :disabled="!isPrimaryTeacher" @change="changeFrequency('informComingClass')" v-if="informSettingFrequency.informComingClass.timeFrequency == 'minute'" v-model="informSettingFrequency.informComingClass.forwardMinute" :step="Number($store.state.classRoomTimeSelectStep.split(':')[1])" :min="Number($store.state.classRoomTimeSelectStep.split(':')[1])" :max="10080" controls-position="right" />
                    <el-input-number class="w-100" :step-strictly="true" :disabled="!isPrimaryTeacher" @change="changeFrequency('informComingClass')" v-else-if="informSettingFrequency.informComingClass.timeFrequency == 'hour'" v-model="informSettingFrequency.informComingClass.forwardHour" :precision="2" :step="0.25" :min="1" :max="168" controls-position="right" />
                    <el-input-number class="w-100" :step-strictly="true" :disabled="!isPrimaryTeacher" @change="changeFrequency('informComingClass')" v-else-if="informSettingFrequency.informComingClass.timeFrequency == 'day'" v-model="informSettingFrequency.informComingClass.forwardDay" :step="1" :min="1" :max="7" controls-position="right" />
                  </div>
                </div>
                <el-select :disabled="!isPrimaryTeacher" @change="changeFrequency('informComingClass')" v-model="informSettingFrequency.informComingClass.timeFrequency" class="ms-lg-2 w-100" placeholder="時間頻率">
                  <el-option v-for="item in informSettingFrequencySelect.timeFrequency_Forward" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClassRoomSetting",
  props: ["isPrimaryTeacher"],
  data() {
    return {
      minPaymentClassCount: 1,
      informSettingActiveName: "0",
      contactBookSettingActiveName: "0",
      memberInfo: {
        lineBotRequestCount: 0,
        allLineBotRequestCount: 0,
        lineBotPoint: 0,
      },
      classRoomSetting: {
        isContactTodoCompleteCheckedSettingOn: false,
      },
      informSetting: {
        informPaymentClassCount: 1,
        informContactBookUnsigned: [],
        informNewTask: [],
        informDueTask: [],
        informPayment: [],
        informOwePayment: [],
        informComingClass: [],
      },
      informSettingFrequency: {
        informContactBookUnsigned: {},
        informNewTask: {},
        informDueTask: {},
        informPayment: {},
        informOwePayment: {},
        informComingClass: {},
      },
      informSettingFrequencySelect: {
        timeFrequency: [
          {
            label: "天",
            value: "day",
          },
          {
            label: "週",
            value: "week",
          },
          {
            label: "月",
            value: "month",
          },
        ],
        timeFrequency_Forward: [
          {
            label: "分鐘",
            value: "minute",
          },
          {
            label: "小時",
            value: "hour",
          },
          {
            label: "天",
            value: "day",
          },
        ],
        day: [],
        week: [
          {
            label: "星期一",
            value: 1,
          },
          {
            label: "星期二",
            value: 2,
          },
          {
            label: "星期三",
            value: 3,
          },
          {
            label: "星期四",
            value: 4,
          },
          {
            label: "星期五",
            value: 5,
          },
          {
            label: "星期六",
            value: 6,
          },
          {
            label: "星期日",
            value: 0,
          },
        ],
        month: [],
      },
    };
  },
  computed: {
    classRoomId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    const vm = this;
    vm.initMemberInfo();
  },
  methods: {
    initMemberInfo() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/ClassRoom/AllClassRoomSetting?classroomId=${vm.classRoomId}`)
        .then(res => {
          var item = res.data;
          vm.memberInfo = {
            lineBotRequestCount: item.lineBotRequestCount,
            allLineBotRequestCount: item.allLineBotRequestCount,
            lineBotPoint: item.lineBotPoint,
          };
          vm.classRoomSetting.isContactTodoCompleteCheckedSettingOn = item.isContactTodoCompleteCheckedSettingOn;
          vm.informSetting = {
            informMethod: item.userInformSetting.informMethod,
            informContactBookUnsigned: item.userInformSetting.informContactBookUnsigned.user,
            informNewTask: item.userInformSetting.informNewTask.user,
            informDueTask: item.userInformSetting.informDueTask.user,
            informPayment: item.userInformSetting.informPayment.user,
            informOwePayment: item.userInformSetting.informOwePayment.user,
            informComingClass: item.userInformSetting.informComingClass.user,
            informPaymentClassCount: item.informPaymentClassCount,
          };
          var classRoomInformFrequencyTemp = {
            type: "",
            timeFrequency: "",
            time: "",
            weekDay: 1,
            monthDay: 1,
            forwardMinute: null,
            forwardHour: null,
            forwardDay: null,
          };
          vm.informSettingFrequency = {
            informContactBookUnsigned: item.userInformSetting.informContactBookUnsigned.classRoomInformFrequency == null ? classRoomInformFrequencyTemp : item.userInformSetting.informContactBookUnsigned.classRoomInformFrequency,
            informNewTask: item.userInformSetting.informNewTask.classRoomInformFrequency == null ? classRoomInformFrequencyTemp : item.userInformSetting.informNewTask.classRoomInformFrequency,
            informDueTask: item.userInformSetting.informDueTask.classRoomInformFrequency == null ? classRoomInformFrequencyTemp : item.userInformSetting.informDueTask.classRoomInformFrequency,
            informPayment: item.userInformSetting.informPayment.classRoomInformFrequency == null ? classRoomInformFrequencyTemp : item.userInformSetting.informPayment.classRoomInformFrequency,
            informOwePayment: item.userInformSetting.informOwePayment.classRoomInformFrequency == null ? classRoomInformFrequencyTemp : item.userInformSetting.informOwePayment.classRoomInformFrequency,
            informComingClass: item.userInformSetting.informComingClass.classRoomInformFrequency == null ? classRoomInformFrequencyTemp : item.userInformSetting.informComingClass.classRoomInformFrequency,
          };
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {});
    },
    changeFrequency(type) {
      const vm = this;
      let data = {
        Id: vm.informSettingFrequency[type].id,
        TimeFrequency: vm.informSettingFrequency[type].timeFrequency,
        Time: vm.informSettingFrequency[type].time,
        WeekDay: vm.informSettingFrequency[type].weekDay,
        MonthDay: vm.informSettingFrequency[type].monthDay,
        ForwardMinute: null,
        ForwardHour: null,
        ForwardDay: null,
      };

      if (type == "informComingClass") {
        if (data.TimeFrequency == "minute") {
          if (vm.informSettingFrequency.informComingClass.forwardMinute == null) vm.informSettingFrequency.informComingClass.forwardMinute = 15;
          data.ForwardMinute = vm.informSettingFrequency.informComingClass.forwardMinute;
        } else if (data.TimeFrequency == "hour") {
          if (vm.informSettingFrequency.informComingClass.forwardHour == null) vm.informSettingFrequency.informComingClass.forwardHour = 1;
          data.ForwardHour = vm.informSettingFrequency.informComingClass.forwardHour;
        } else if (data.TimeFrequency == "day") {
          if (vm.informSettingFrequency.informComingClass.forwardDay == null) vm.informSettingFrequency.informComingClass.forwardDay = 1;
          data.ForwardDay = vm.informSettingFrequency.informComingClass.forwardDay;
        }
      }
      console.log(data);
      vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/ClassRoom/InformFrequency`, data)
        .then(res => {
          console.log(res.data);
          vm.$message({
            type: "success",
            message: `更新完成！`,
          });
        })
        .catch(err => {
          console.log(err);
          vm.notify("error", `更新失敗！`);
        })
        .finally(() => {});
    },
    informNow(type) {
      const vm = this;
      let data = {
        ClassRoomId: vm.classRoomId,
        Type: type,
      };

      vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/ClassRoom/InformNow`, data)
        .then(res => {
          console.log(res.data);
          vm.memberInfo.allLineBotRequestCount += 1;
          vm.memberInfo.lineBotRequestCount += 1;
          vm.$message({
            type: "success",
            message: `即時通知完成！`,
          });
        })
        .catch(err => {
          console.log(err);
          vm.notify("error", `即時通知失敗！`);
        })
        .finally(() => {});
    },
    changeInformSetting() {
      const vm = this;
      let data = {
        ClassRoomId: vm.classRoomId,
        InformMethod: vm.informSetting.informMethod,
        InformContactBookUnsigned: vm.informSetting.informContactBookUnsigned,
        InformNewTask: vm.informSetting.informNewTask,
        InformDueTask: vm.informSetting.informDueTask,
        InformPayment: vm.informSetting.informPayment,
        InformOwePayment: vm.informSetting.informOwePayment,
        InformComingClass: vm.informSetting.informComingClass,
        InformPaymentClassCount: vm.informSetting.informPaymentClassCount == "" ? vm.minPaymentClassCount : vm.informSetting.informPaymentClassCount,
      };

      vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/ClassRoom/InformSetting`, data)
        .then(res => {
          console.log(res.data);
          vm.$message({
            type: "success",
            message: `更新完成！`,
          });
        })
        .catch(err => {
          console.log(err);
          vm.notify("error", `更新失敗！`);
        })
        .finally(() => {});
    },
    changeClassRoomSetting() {
      const vm = this;
      let data = {
        ClassRoomId: vm.classRoomId,
        IsContactTodoCompleteCheckedSettingOn: vm.classRoomSetting.isContactTodoCompleteCheckedSettingOn,
      };

      vm.fetchAPI("put", `${process.env.VUE_APP_URL_API}/ClassRoom/ClassRoomSetting`, data)
        .then(res => {
          console.log(res.data);
          vm.$emit("changeIsContactTodoCompleteCheckedSettingOn", data.IsContactTodoCompleteCheckedSettingOn);
          vm.$message({
            type: "success",
            message: `更新完成！`,
          });
        })
        .catch(err => {
          console.log(err);
          vm.notify("error", `更新失敗！`);
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.inform_part {
  margin-bottom: 1.5rem;
  font-weight: bold;
}
.contactBook_setting,
.inform_setting {
  border: 1px solid #d5d5d5;
  border-radius: 1rem;
  padding: 1.5rem;
}
.inform_group {
  justify-content: space-between;
  border-bottom: 1px solid #acacac;
  &:last-child {
    border: none !important;
  }
}
.frequency {
  box-shadow: 1px 1px 3px 1px $main_gray;
  border-radius: 8px;
  padding: 1rem;
  margin-right: 1rem;
  margin-top: 0;
  margin-bottom: 1rem;
  width: 60%;
}
:deep .el-collapse.defaultSetting {
  border: none;
  .el-checkbox-group {
    display: flex;
    justify-content: flex-start;
    .el-checkbox {
      width: fit-content;
    }
  }
  .el-collapse-item__content {
    padding-bottom: 0rem !important;
  }
}
@media screen and (max-width: 768px) {
  .inform_group {
    display: block !important;
  }
  .frequency {
    margin-top: 1rem;
    margin-left: 0rem;
    width: 100%;
  }
  .inform_part {
    margin-bottom: 1.5rem;
    font-weight: bold;
  }
}

@media screen and (min-width: 768px) {
  .inform_part {
    flex-direction: row !important;
    justify-content: flex-end;
  }
}
</style>
