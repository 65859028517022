<template>
  <el-container class="main">
    <div class="container" style="padding: 0">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="d-flex">
            <div class="contactBookTitle text-start">
              <p class="" v-if="!editingContactName" >
                <span>{{ contactBookName }}</span>
                <el-button 
                 @click="editContactName"
                 link
                >
               <i class="fal fa-marker ms-2"></i>

            </el-button>
              </p>
              
              <p v-else>
                <el-input
                  v-model="newContactBookName"
                  class="mb-2"
                  size="large"
                  placeholder="請輸入聯絡簿名稱"
                />
                <br />
                <el-button @click="editingContactName = false">
                  <i class="far fa-times"></i>
                </el-button>
                <el-button type="success" @click="saveNewContactBookName()"
                  ><i class="far fa-check"></i
                ></el-button>
              </p>
              <p class="contactBookCreateDate mt-1">
                {{ contactBookCreateDate }}
              </p>
            </div>
          </div>
        </el-col>

        <el-col :lg="10" :md="24">
          <MiddleBar
            ref="childRefMiddleBar"
            :middleBarParams="middleBarParams"
            :allStudentCount="allStudentCount"
            :allDoneCount="allDoneCount"
            class="middlebar"
            v-if="
              
                $store.getters.el_mobileSize||
              !$store.getters.el_mobileSize
            "
          />
        </el-col>
        <el-col
          :lg="14"
          :md="24"
          v-if="
            $store.state.workItem.task != null && !$store.getters.el_mobileSize
          "
        >
          <MainBar
            @rerenderMiddleBar="rerenderMiddleBar"
            class="mainbar"
            v-if="
              $store.state.workItem.isMiddleBarShow &&
              $store.state.workItem.isMainBarShow
            "
          />
        </el-col>
      </el-row>
    </div>

    <el-dialog
      v-model="taskDialogShow"
      fullscreen="true"
      z-index="99"
      @closed="$store.state.workItem.task = null"
    >
      <MainBar @rerenderMiddleBar="rerenderMiddleBar" class="mainbar" />
    </el-dialog>
  </el-container>
</template>

<script>
import MiddleBar from "./MiddleBar.vue";
import MainBar from "./MainBar.vue";
import Storage from "../../../../models/storage";

export default {
  name: "ContactBook",
  data() {
    return {
      newContactBookName: "",
      editingContactName: false,
      classRoomId: "",
      classRoomName: "",
    
      middleBarParams: 0,
      allDoneCount: 0,
    };
  },
  props:[
  "contactBookName","contactBookCreateDate"
  ],
  beforeRouteLeave() {
    this.$store.state.workItem = {
      data: null,
      node: null,
      task: null,
      isMiddleBarShow: true,
      isMainBarShow: true,
      tableData: [],
      studentOptions: [],
    };
  },
  components: {
    MiddleBar,
    MainBar,
  },
  watch: {
    // $route: function () {
    //   let vm = this;
    // },
  },
  async mounted() {
    //const vm = this;
    // await vm.initData();
  },
  computed: {
    taskDialogShow() {
      return (
        this.$store.state.workItem.task != null &&
        this.$store.getters.el_mobileSize
      );
    },
    breadCrumb() {
      if (Storage.get("breadcrumb_from").first == undefined)
        return "ClassRoomCenter";
      return Storage.get("breadcrumb_from").first;
    },
  },
  methods: {
    changeSetting(value) {
      this.$refs.childRefMiddleBar.changeSetting(value);
    },
  
    saveNewContactBookName() {
      let vm = this;
      let value = vm.newContactBookName;
      if (value == "" || value == null) {
        vm.notify("error", `聯絡簿名稱不可為空白！`);
        return;
      }
      const data = {
        contactBookId: vm.$route.params.contactBookId,
        contactBookName: value,
      };
      vm.fetchAPI(
        "put",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook`,
        data
      )
        .then(() => {
          // vm.contactBookName = vm.newContactBookName;
          vm.editingContactName = false;
          this.$emit("updateCurrentContactName", vm.newContactBookName);
          vm.$message({
            type: "success",
            message: `修改完成！`,
          });
        })
        .catch((err) => {
          console.log(err);
          vm.notify("error", `修改失敗！`);
        });
    },
    editContactName() {
      let vm = this;
      vm.editingContactName = true;
      vm.newContactBookName = vm.contactBookName;
    },
    rerenderMiddleBar(id, allDoneCount, allStudentCount) {
      this.middleBarParams = id;
      this.allDoneCount = allDoneCount;
      this.allStudentCount = allStudentCount;
    },
    initData() {
      const vm = this;
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/RouteName/${vm.$route.params.contactBookId}`,
        {},
        false
      )
        .then((res) => {
          console.log(res.data);
          vm.classRoomId = res.data.classRoomId;
          vm.classRoomName = res.data.classRoomName;
     
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contactBookTitle {
  display: inline-block;
  font-size: x-large;
  padding: 0.5rem 0;
  margin-right: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  .contactBookCreateDate {
    font-size: small;
  }
}
.main {
  flex-direction: column;
}
.teacher_admin {
  padding: 0 1.5rem;
  height: 90%;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  .sidebar {
    width: 10%;
  }
  .right_space {
    margin-left: 0.5rem;
    .el-breadcrumb {
      margin-bottom: 0.5rem;
    }
    .content {
      display: flex;
      .middlebar {
        width: 20%;
      }
      .mainbar {
        width: 80%;
      }
    }
  }
}
@media screen and (min-width: 768px) {
}
</style>
