<template>
  <div class="middle_space">
    <div class="top">
      <div class="multi_func">
        <span>{{ cardData[0].tasks.length }}則作業</span>

        <div
          class="btn_func mt-2"
          v-if="assigneeListId.filter((x) => x != 0).length != 0"
        >
          <el-button size="small" class="px-2" @click="toContactBookView()"
            >學生視角</el-button
          >
        </div>
      </div>
    </div>
    <div class="card_space">
      <div class="card_top my-1">
        <el-input
          class="me-1"
          v-if="isSearch"
          v-model="search"
          placeholder="搜尋關鍵字"
        />
        <el-button class="ms-auto" circle size="small">
          <i class="fal fa-search" @click="isSearch = !isSearch"></i>
        </el-button>
      </div>
      <el-alert
        v-if="displayData.length == 0"
        class="my-2"
        title="※請在下方新增聯絡事項"
        type="info"
        show-icon
      >
      </el-alert>
      <div class="cards" v-for="column in cardData" :key="column">
        <VueDraggableNext
          @end="dragEnd()"
          :list="displayData"
          :animation="200"
          ghost-class="ghost-card"
          group="tasks"
          handle=".drag-handle"
        >
          <TaskCard
            class="todoCard"
            @click="showMain(task)"
            :class="{ 'drag-handle': !$store.getters.mobileSize }"
            v-for="task in displayData"
            :key="task.id"
            :task="task"
            :isContactTodoCompleteCheckedSettingOn="
              isContactTodoCompleteCheckedSettingOn
            "
            @contextmenu="onContextMenu($event)"
            @deleteTask="deleteTask"
            @addTaskTemplate="addTaskTemplate"
            @renameTask="renameTask"
            @settingTask="settingTask"
            @orderUp="orderUp"
            @orderDown="orderDown"
          ></TaskCard>
        </VueDraggableNext>
      </div>

      <div class="add_card mt-1">
        <template v-if="studentOptions.length == 0">
          <p class="mb-2 text-start">尚無發送對象</p>
        </template>
        <template v-else-if="studentOptions.length == assigneeListId.length">
          <div class="d-flex flex-wrap mb-2 align-items-center">
            <p class="me-2 text-start">發送給:</p>
            <el-tag size="mini"> 所有學生 </el-tag>
          </div>
        </template>
        <template v-else>
          <div class="d-flex flex-wrap mb-2 align-items-center">
            <p class="me-2 text-start">發送給:</p>
            <el-tag
              size="mini"
              class="me-1"
              v-for="item in studentOptions.filter((x) =>
                assigneeListId.includes(x.value)
              )"
              :key="item.value"
            >
              {{ item.label }}
            </el-tag>
          </div>
        </template>

        <div
          v-if="openAddTool"
          class="mb-2 btn_group d-flex align-items-center"
        >
          <el-button class="me-2" @click="getTemplate()">使用範本</el-button>
          <el-select
            v-model="assigneeListId"
            multiple
            collapse-tags
            placeholder="無指定對象"
          >
            <el-option
              v-for="item in studentOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="textarea">
          <el-button
            v-if="openAddTool"
            class="addTaskBtn me-1"
            @click="openAddTool = !openAddTool"
          >
            <i class="fa-light fa-times px-2"></i>
          </el-button>
          <el-button
            v-else
            class="addTaskBtn me-1"
            @click="openAddTool = !openAddTool"
          >
            <i class="fa-light fa-chevron-down px-2"></i>
          </el-button>
          <el-button
            class="addTaskBtn ms-0 me-1"
            @click="necessary = !necessary"
          >
            <i
              v-if="necessary == false"
              class="fa-regular fa-bookmark px-2"
              style="color: #96beab"
            ></i>
            <i v-else class="fas fa-bookmark px-2" style="color: #96beab"></i>
          </el-button>
          <el-input
            @keydown.enter="addTask"
            placeholder="輸入聯絡事項..."
            v-model="addTaskInput"
            :rows="3"
          ></el-input>

          <el-button class="addTaskBtn ms-1" @click="addTask">
            <i class="fas fa-plus"></i>
          </el-button>
        </div>
        <!-- <div class="bg-white my-1 p-1">
          <i v-if="true" class="fas fa-bookmark" style="color: #96beab"></i>
          <span class="ms-1">: 需繳交檔案</span>
        </div> -->
      </div>
    </div>
  </div>

  <el-dialog v-model="dialogContactTodoTemplate" title="範本" width="80%">
    <el-collapse v-model="activeTemplateName" accordion>
      <el-collapse-item
        v-for="i in contactTodotemplate"
        :key="i"
        :title="i.name"
        :name="i.id"
      >
        <div class="ps-1 mb-1 border-bottom">
          <el-form-item class="mb-0" label="主題">
            <label>{{ i.classContactTodoJson.title }}</label>
          </el-form-item>
          <el-form-item class="mb-0" label="描述">
            <p v-html="i.classContactTodoJson.content"></p>
          </el-form-item>
          <el-form-item class="mb-0" label="檔案">
            <span
              v-for="(fileList, index) in i.classContactTodoJson.fileList"
              :key="fileList.id"
            >
              <el-button size="small" color="#dddddd">{{
                fileList.name
              }}</el-button>
              <el-divider
                v-if="i.classContactTodoJson.fileList.length != index + 1"
                direction="vertical"
              />
            </span>
          </el-form-item>
          <el-form-item class="mb-0" label="連結">
            <span
              v-for="(linkList, index) in i.classContactTodoJson.linkList"
              :key="linkList.id"
            >
              <el-link type="primary" :href="linkList.url">{{
                linkList.title
              }}</el-link>
              <el-divider
                v-if="i.classContactTodoJson.linkList.length != index + 1"
                direction="vertical"
              />
            </span>
          </el-form-item>
        </div>
        <div class="mt-1">
          <div class="d-flex">
            <el-input
              v-model="newTemplateName"
              placeholder="輸入新的範本名稱"
              clearable
              style="width: 200px"
              size="small"
            />
            <el-button
              class="ms-1"
              size="small"
              type="info"
              @click="editTemplateName(i.id)"
              >更改名稱</el-button
            >
          </div>
          <div class="mt-2 text-end">
            <el-button
              class="ms-auto"
              size="small"
              type="warning"
              @click="delteContactTodoTemplate(i.id)"
              >刪除</el-button
            >
            <el-button
              class="ms-1"
              size="small"
              type="primary"
              @click="addContactTodoByTemplate(i.id)"
              >使用</el-button
            >
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </el-dialog>

  <ContextMenu ref="ContextMenu" />
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
import TaskCard from "./TaskCard.vue";
import ContextMenu from "./SidebarContextMenu.vue";

export default {
  name: "App",
  props: ["middleBarParams", "allDoneCount", "allStudentCount"],
  components: {
    TaskCard,
    VueDraggableNext,
    ContextMenu,
  },
  async created() {
    await this.initContactTodoData();
    await this.initStudentData();
    // await this.setStudent();
  },
  data() {
    return {
      isSearch: false,
      isContactTodoCompleteCheckedSettingOn: false,
      openAddTool: false,
      dialogContactTodoTemplate: false,
      contactTodotemplate: {},
      newTemplateName: "",
      contactBookName: "",
      addTaskInput: "",
      search: "",
      displayData: [],
      cardData: [
        {
          tasks: [],
        },
      ],
      selectAllStudent: true,
      assigneeListId: [],
      necessary: false,
    };
  },
  computed: {
    studentOptions() {
      return this.$store.state.workItem.studentOptions;
    },
  },
  watch: {
    "$route.params.contactBookId": function (value) {
      let vm = this;
      if (value != null || value != undefined) {
        vm.initStudentData();
        vm.initContactTodoData();
      }
    },
    allDoneCount(newVal) {
      if (this.middleBarParams != 0) {
        this.cardData[0].tasks.find(
          (x) => x.id == this.middleBarParams
        ).assigneeIsDoneCount = newVal;
      }
    },
    allStudentCount(newVal) {
      if (this.middleBarParams != 0) {
        this.cardData[0].tasks.find(
          (x) => x.id == this.middleBarParams
        ).assigneeCount = newVal;
      }
    },
    search() {
      this.changeData();
    },
  },
  methods: {
    changeSetting(value) {
      this.isContactTodoCompleteCheckedSettingOn = value;
    },
    //用範本新增聯絡簿
    delteContactTodoTemplate(id) {
      const vm = this;
      vm.fetchAPI(
        "delete",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodoTemplate/${id}`
      ).then((res) => {
        if (res.data.status != 0) {
          this.$message({
            type: "error",
            message: "系統異常",
          });
        } else {
          vm.contactTodotemplate = vm.contactTodotemplate.filter(
            (x) => x.id != id
          );
          this.$message({
            type: "success",
            message: "刪除成功",
          });
        }
        vm.dialogContactTodoTemplate = false;
      });
    },
    addContactTodoByTemplate(id) {
      const vm = this;
      //axios

      const data = {
        ContactBookId: vm.$route.params.contactBookId,
        Title: "",
        AssigneeList: vm.assigneeListId.filter((x) => x != 0),
        TodoTemplateId: id,
      };
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodoByTemplate`,
        data
      )
        .then((res) => {
          // console.log(res.data);
          var assigneeListTemp = [];
          vm.assigneeListId.forEach((x, i) => {
            if (x == 0) return;
            assigneeListTemp.push({
              id: res.data.assigneeIdList[i],
              memberId: x,
              fileCount: 0,
              messageCount: 0,
              isDone: false,
              finishDateTime: "",
            });
          });

          // vm.dialogContactTodoTemplate = false;
          var assigneeListIdFilter = vm.assigneeListId.filter((x) => x != 0);
          var assigneeCount =
            assigneeListIdFilter == undefined ? 0 : assigneeListIdFilter.length;

          vm.cardData[0].tasks.push({
            id: res.data.contactTodoId,
            title: res.data.taskTitle,
            startDate: res.data.startDate,
            assigneeListId: vm.assigneeListId,
            assigneeList: assigneeListTemp,
            assigneeCount: assigneeCount,
            assigneeIsDoneCount: 0,
            isInformDueTask: true,
            linkList: [],
            fileList: [],
            content: "",
          });
          vm.dialogContactTodoTemplate = false;
          vm.notify("success", `新增完成！`);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `新增失敗！`);
        });
    },
    editTemplateName(id) {
      const vm = this;

      vm.fetchAPI(
        "put",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodoTemplate/${id}/${vm.newTemplateName}`
      ).then((res) => {
        if (res.data.status != 0) {
          this.$message({
            type: "error",
            message: "系統異常",
          });
        } else {
          vm.contactTodotemplate.find((x) => x.id == id).name =
            vm.newTemplateName;
          this.$message({
            type: "success",
            message: "更新成功",
          });
          vm.newTemplateName = "";
        }
      });
    },
    getTemplate() {
      const vm = this;

      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodoTemplate`
      ).then((res) => {
        vm.dialogContactTodoTemplate = true;
        console.log(res.data);
        if (res.data.length == 0) {
          this.$message({
            type: "info",
            message: "尚無建立範本",
          });
          return false;
        }
        vm.contactTodotemplate = res.data;
      });
    },
    // 學生
    initStudentData() {
      const vm = this;
      vm.assigneeListId = [];
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/Member/${vm.$route.params.id}`,
        {},
        false
      )
        .then((res) => {
          res.data.forEach((x) => {
            if (x.role == "Student") {
              vm.assigneeListId.push(x.memberId);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setStudent() {
      const vm = this;
      if (vm.selectAllStudent)
        vm.assigneeListId = vm.studentOptions.map((x) => x.value);
      else vm.assigneeListId = [];
    },
    changeData() {
      const vm = this;
      vm.displayData = vm.cardData[0].tasks.filter(
        (data) =>
          !vm.search ||
          data.id.toString().toLowerCase().includes(vm.search.toLowerCase()) ||
          data.title.toString().toLowerCase().includes(vm.search.toLowerCase())
      );
    },
    toContactBookView() {
      const vm = this;
      vm.$router.push({
        name: "ChargeMeContactBook_ContactBookView",
        params: {
          id: vm.$route.params.id,
          role: "Teacher",
          contactBookId: vm.$route.params.contactBookId,
        },
      });
    },
    // 聯絡簿 - 待辦事項
    initContactTodoData() {
      const vm = this;
      this.$store.commit("setIsLoading", true);
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/${vm.$route.params.contactBookId}`,
        {},
        false
      )
        .then((res) => {
          if (res.data.length != 0) {
            vm.cardData[0].tasks = [];
            res.data.contactTodoList.forEach((x) => {
              vm.cardData[0].tasks.push({
                id: x.id,
                title: x.title,
                order: x.order,
                content: x.content,
                checked: x.checked,
                startDate: x.startDate,
                dueDate: x.dueDate,
                createdUser: x.createdUser,
                linkList: x.linkList,
                fileList: x.fileList,
                isInformDueTask: x.isInformDueTask,
                assigneeList: x.assigneeList,
                assigneeListId: x.assigneeListId,
                assigneeCount: x.assigneeCount,
                assigneeIsDoneCount: x.assigneeIsDoneCount,
                isNecessary:x.isNecessary
              });
            });
            console.log(vm.cardData[0].tasks);
            vm.displayData = vm.cardData[0].tasks;
            vm.contactBookName = res.data.contactBookName;
            vm.isContactTodoCompleteCheckedSettingOn =
              res.data.isContactTodoCompleteCheckedSettingOn;
          }
          this.$store.commit("setIsLoading", false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showMain(task) {
      this.$store.state.workItem.task = task;
      this.$store.state.workItem.isMainBarShow = true;
      if (
        this.$store.state.workItem.task.assigneeListId.length ==
          this.studentOptions.length - 1 &&
        !this.$store.state.workItem.task.assigneeListId.includes(0)
      ) {
        this.$store.state.workItem.task.assigneeListId.push(0);
        this.$store.state.workItem.task.assigneeListId.sort();
      }
      this.setAssignee();
    },
    setAssignee() {
      var vm = this;
      vm.$store.state.workItem.tableData = [];
      vm.$store.state.workItem.task.assigneeListId.forEach(function (x) {
        if (x == 0) return;
        var temp = vm.$store.state.workItem.task.assigneeList.find(
          (y) => y.memberId == x
        );
        if (temp == undefined) {
          vm.$store.state.workItem.task.assigneeList.push({
            memberId: x,
            isDone: false,
          });
        }

        var student = vm.studentOptions.find((y) => y.value == x);
        vm.$store.state.workItem.tableData.push({
          memberId: x,
          avatar: student.avatar,
          finishDate: temp.finishDateTime,
          fileCount: temp.fileCount,
          messageCount: temp.messageCount,
          name: student.label,
          isDone: temp == undefined ? false : temp.isDone,
          title: vm.$store.state.workItem.task.title,
        });
      });
      vm.$store.state.workItem.task.assigneeList =
        vm.$store.state.workItem.task.assigneeList.filter((x) =>
          vm.$store.state.workItem.task.assigneeListId.includes(x.memberId)
        );
    },
    onContextMenu($event) {
      this.$refs.ContextMenu.onButtonClick($event, null);
    },
    addTask() {
      const vm = this;
      //axios
      let taskTitle = vm.addTaskInput;
      if (taskTitle == "") return;
      const data = {
        ContactBookId: vm.$route.params.contactBookId,
        Title: taskTitle,
        AssigneeList: vm.assigneeListId.filter((x) => x != 0),
        IsNecessary :vm.necessary
      };
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo`,
        data
      )
        .then((res) => {
          var assigneeListTemp = [];
          vm.assigneeListId.forEach((x, i) => {
            if (x == 0) return;
            assigneeListTemp.push({
              id: res.data.assigneeIdList[i],
              memberId: x,
              fileCount: 0,
              messageCount: 0,
              isDone: false,
              finishDateTime: "",
            });
          });

          var assigneeListIdFilter = vm.assigneeListId.filter((x) => x != 0);
          var assigneeCount =
            assigneeListIdFilter == undefined ? 0 : assigneeListIdFilter.length;

          vm.cardData[0].tasks.push({
            id: res.data.contactTodoId,
            title: taskTitle,
            startDate: res.data.startDate,
            assigneeListId: vm.assigneeListId,
            assigneeList: assigneeListTemp,
            assigneeCount: assigneeCount,
            assigneeIsDoneCount: 0,
            isInformDueTask: true,
            linkList: [],
            fileList: [],
            content: "",
            isNecessary :vm.necessary
          });
          vm.addTaskInput = "";
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `新增失敗！`);
        });
    },
    deleteTask(id) {
      const vm = this;
      vm.$confirm("將從系統刪除作業, 是否繼續?", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          vm.fetchAPI(
            "delete",
            `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/${id}`
          )
            .then(() => {
              vm.cardData[0].tasks = vm.cardData[0].tasks.filter(
                (item) => item.id != id
              );
              vm.changeData();
              vm.$message({
                type: "success",
                message: "刪除成功",
              });
            })
            .catch((err) => {
              console.log(err);
              vm.notify("error", "刪除失敗！");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    settingTask(taskModel) {
      const vm = this;
      var temp = vm.cardData[0].tasks.find((x) => x.id == taskModel.id);
      const data = {
        ContactTodoId: taskModel.id,
        Title: taskModel.title,
        Content: temp.content,
        Assignee: temp.assigneeList,
        StartDate: temp.startDate,
        DueDate: temp.dueDate,
        IsInformDueTask: !temp.isInformDueTask,
      };
      vm.fetchAPI(
        "put",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo`,
        data
      )
        .then((res) => {
          console.log(res.data);
          var foundIndex = vm.cardData[0].tasks.findIndex(
            (x) => x.id == taskModel.id
          );
          vm.cardData[0].tasks[foundIndex].isInformDueTask =
            data.IsInformDueTask;
          vm.notify("success", `修改成功！`);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `修改失敗！`);
        });
    },
    addTaskTemplate(id) {
      const vm = this;
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodoTemplate/${vm.$route.params.contactBookId}/${id}`
      )
        .then((res) => {
          console.log(res.data);
          vm.notify("success", `建立成功！`);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `失敗！`);
        });
    },
    renameTask(taskModel) {
      const vm = this;
      this.$prompt(`請輸入名稱`, "修改名稱", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        inputValue: taskModel.title,
        inputType: "textarea", // 設置輸入框類型為 textarea
      })
        .then(({ value }) => {
          if (value == "" || value == null) {
            vm.notify("error", `名稱不可為空白！`);
            return;
          }
          //axios
          var temp = vm.cardData[0].tasks.find((x) => x.id == taskModel.id);
          const data = {
            ContactTodoId: taskModel.id,
            Title: value,
            Content: temp.content,
            Assignee: temp.assigneeList,
            StartDate: temp.startDate,
            DueDate: temp.dueDate,
            IsInformDueTask: temp.isInformDueTask,
          };
          vm.fetchAPI(
            "put",
            `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo`,
            data
          )
            .then((res) => {
              console.log(res.data);
              var foundIndex = vm.cardData[0].tasks.findIndex(
                (x) => x.id == taskModel.id
              );
              vm.cardData[0].tasks[foundIndex].title = value;
              vm.notify("success", `修改成功！`);
            })
            .catch((err) => {
              console.log(err.response.data);
              vm.notify("error", `修改失敗！`);
            });
        })
        .catch(() => {
          vm.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    dragEnd() {
      const vm = this;
      var temp = [];
      vm.cardData[0].tasks.forEach((item) => {
        temp.push(item.id);
      });
      // console.log(vm.cardData[0].tasks);
      const data = {
        ContactBookId: vm.$route.params.contactBookId,
        ContactTodoIdList: temp,
      };
      vm.fetchAPI(
        "put",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/Order`,
        data,
        false
      )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `變更順序失敗！`);
        });
    },
    orderUp(id) {
      const vm = this;
      var temp = [];

      var index = vm.cardData[0].tasks.findIndex((x) => x.id == id);
      if (index == 0) return;
      var previous = vm.cardData[0].tasks[index - 1];
      vm.cardData[0].tasks[index - 1] = vm.cardData[0].tasks[index];
      vm.cardData[0].tasks[index] = previous;
      vm.cardData[0].tasks.forEach((item) => {
        temp.push(item.id);
      });

      const data = {
        ContactBookId: vm.$route.params.contactBookId,
        ContactTodoIdList: temp,
      };
      vm.fetchAPI(
        "put",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/Order`,
        data
      )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `變更順序失敗！`);
        });
    },
    orderDown(id) {
      const vm = this;
      var temp = [];

      var index = vm.cardData[0].tasks.findIndex((x) => x.id == id);
      if (index == vm.cardData[0].tasks.length - 1) return;
      var next = vm.cardData[0].tasks[index + 1];
      vm.cardData[0].tasks[index + 1] = vm.cardData[0].tasks[index];
      vm.cardData[0].tasks[index] = next;
      vm.cardData[0].tasks.forEach((item) => {
        temp.push(item.id);
      });

      const data = {
        ContactBookId: vm.$route.params.contactBookId,
        ContactTodoIdList: temp,
      };
      vm.fetchAPI(
        "put",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/Order`,
        data
      )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `變更順序失敗！`);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.addTaskBtn {
  width: 30px;
  height: 30px;
  // border-radius: 99em;
  color: #2879ff;
  text-align: center;
  i {
    font-size: small;
    padding-right: 0.1rem;
  }
}
.middle_space {
  .top {
    padding: 0;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-size: 1.2rem;
        font-weight: 700;
      }
      display: flex;
    }
    .multi_func {
      position: absolute;
      display: flex;
      flex-direction: column;
      margin-top: 0.5rem;
      align-items: flex-end;
      justify-content: space-between;
      top: 0rem;
      right: 1rem;
      span {
        font-size: 0.9rem;
      }
    }
  }
  .card_space {
    .card_top {
      display: flex;
      align-items: center;
      .add_btn {
        margin-left: 1rem;
      }
    }
    .cards {
      .column-width {
        min-width: 320px;
        width: 320px;
      }
      .ghost-card {
        opacity: 0.5;
        background: #f7fafc;
        border: 1px solid #4299e1;
      }
    }
    .add_card {
      padding: 0.4rem 0.4rem;
      border: 1px solid #77724b;
      background-color: $main_color;
      //
      opacity: 0.9;
      text-align: start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        i {
          margin-right: 0.5rem;
        }
      }
      .textarea {
        display: flex;
        align-items: center;
      }
    }
  }
  .btn_func {
  }
}
.todoCard:last-child {
  border: none;
}
</style>
