<template>
  <el-row :gutter="20" class="my-3">
    <el-col :lg="20" :md="24" class="mx-auto">
      <el-card class="box-card mx-2 mb-3" style="border-radius: 16px">
        <div
          class="d-flex pb-2 justify-content-between align-items-center"
          style="font-size: larger; font-weight: 600"
        >
          <h3>建立者</h3>
        </div>
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item text-start d-flex align-items-center d-flex justify-content-between px-1"
          >
            <div class="d-flex align-items-center">
              <el-avatar size="medium" :src="members.ownerAvatar"></el-avatar>
              <div>
                <p class="ms-3 memberName">{{ members.ownerName }}</p>
                <p class="ms-3 memberEmail">
                  {{ members.ownerEmail }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </el-card>
      <el-card class="box-card mx-2 mb-3" style="border-radius: 16px">
        <div
          class="d-flex pb-2 justify-content-between align-items-center position-relative title"
          style="font-size: larger; font-weight: 600"
        >
          <h3>老師</h3>
          <div
            v-if="members.queryTeacher"
            class="text-end position-absolute addMemberArea"
            style="right: 0; top: 1px"
          >
            <el-autocomplete
              :disabled="members.isReadonlySelectedTeacher"
              class="inline-input"
              v-model="members.stateOfTeacher"
              :fetch-suggestions="querySearch"
              placeholder="請輸入帳號"
              :trigger-on-focus="false"
              @select="handleSelectTeacher"
            >
              <template v-slot="{ item }">
                <div class="w-100 d-flex align-items-center py-2">
                  <el-avatar size="small" :src="item.avatar" />
                  <div class="ms-2">
                    <div style="line-height: normal">
                      <span class="d-block">{{ item.name }}</span>
                      <span>{{ item.email }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </el-autocomplete>
            <el-button
              size="small"
              class="memberBtn ms-1"
              @click="cancelAutoComplete('Teacher')"
              ><i class="fas fa-times"></i
            ></el-button>
            <el-button
              size="small"
              class="memberBtn ms-1"
              @click="setRole('Teacher')"
              :disabled="members.selectedTeacher == null"
              ><i class="fas fa-check"></i
            ></el-button>
          </div>
          <div v-else>
            <el-button v-if="members.canAddAllMember" circle class="memberBtn">
              <i
                class="fas fa-user-plus"
                @click="members.queryTeacher = true"
              ></i>
            </el-button>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <li
            v-for="item in members.teachers"
            :key="item"
            class="list-group-item text-start d-flex align-items-center justify-content-between px-1"
          >
            <div class="d-flex align-items-center">
              <input type="hidden" :value="item.id" />
              <i
                v-if="item.isPrimaryTeacher"
                class="fa-sharp fa-solid fa-star star"
              ></i>
              <el-avatar size="medium" :src="item.avatar"></el-avatar>
              <div>
                <p class="ms-3 memberName">{{ item.name }}</p>
                <p class="ms-3 memberEmail">{{ item.email }}</p>
              </div>
            </div>
            <div>
              <el-button
                v-if="
                  members.canAddAllMember &&
                  !item.isPrimaryTeacher &&
                  isPrimaryTeacher
                "
                circle
                class="memberBtn"
                @click="changePrimaryTeacher(item)"
                ><i class="fa-sharp fa-solid fa-star"></i
              ></el-button>
              <el-button
                v-if="members.canAddAllMember && !item.isPrimaryTeacher"
                circle
                class="memberBtn"
                @click="removeMember(item, 'Teacher')"
                ><i class="fa-regular fa-trash-can"></i
              ></el-button>
            </div>
          </li>
        </ul>
      </el-card>
      <el-card class="box-card mx-2 mb-3" style="border-radius: 16px">
        <div
          class="d-flex pb-2 justify-content-between align-items-center position-relative title"
          style="font-size: larger; font-weight: 600"
        >
          <h3>學生</h3>
          <div
            v-if="members.queryStudent"
            class="text-end position-absolute addMemberArea"
            style="right: 0rem; top: 1px"
          >
            <el-autocomplete
              :disabled="members.isReadonlySelectedStudent"
              class="inline-input"
              v-model="members.stateOfStudent"
              :fetch-suggestions="querySearch"
              placeholder="請輸入帳號"
              :trigger-on-focus="false"
              @select="handleSelectStudent"
            >
              <template v-slot="{ item }">
                <div class="w-100 d-flex align-items-center py-2">
                  <el-avatar size="small" :src="item.avatar" />
                  <div class="ms-2">
                    <div style="line-height: normal">
                      <span class="d-block">{{ item.name }}</span>
                      <span>{{ item.email }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </el-autocomplete>
            <el-button
              size="small"
              class="memberBtn ms-1"
              @click="cancelAutoComplete('Student')"
              ><i class="fas fa-times"></i
            ></el-button>
            <el-button
              size="small"
              class="memberBtn ms-1"
              @click="setRole('Student')"
              :disabled="members.selectedStudent == null"
              ><i class="fas fa-check"></i
            ></el-button>
          </div>
          <div v-else>
            <el-button circle class="memberBtn" v-if="members.canAddAllMember"
              ><i
                class="fas fa-user-plus"
                @click="members.queryStudent = true"
              ></i
            ></el-button>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <li
            v-for="item in members.students"
            :key="item"
            class="list-group-item text-start d-flex flex-column align-items-start justify-content-between px-1"
          >
            <div class="d-flex align-items-center w-100 py-1">
              <input type="hidden" :value="item.id" />
              <el-avatar size="medium" :src="item.avatar"></el-avatar>
              <div>
                <p class="ms-3 memberName">{{ item.name }}</p>
                <p class="ms-3 memberEmail">{{ item.email }}</p>
              </div>
              <div
                v-if="members.canAddAllMember || item.canParentEdit"
                class="d-flex mt-2 parent_button_group ms-auto"
              >
                <div>
                  <div
                    v-if="item.queryParent"
                    class="text-end position-absolute addMemberArea"
                    style="right: 0rem"
                  >
                    <el-autocomplete
                      :disabled="members.isReadonlySelectedParent"
                      class="inline-input"
                      v-model="item.stateOfParent"
                      :fetch-suggestions="querySearchParent"
                      placeholder="請輸入帳號"
                      :trigger-on-focus="false"
                      @select="handleSelectParent"
                    >
                      <template v-slot="{ item }">
                        <div class="w-100 d-flex align-items-center py-2">
                          <el-avatar size="small" :src="item.avatar" />
                          <div class="ms-2">
                            <div style="line-height: normal">
                              <span class="d-block memberName">{{
                                item.name
                              }}</span>
                              <span class="memberMail">{{ item.email }}</span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-autocomplete>
                    <el-button
                      size="small"
                      class="memberBtn ms-1"
                      @click="cancelAutoComplete('Parent', item)"
                      ><i class="fas fa-times"></i
                    ></el-button>
                    <el-button
                      size="small"
                      class="memberBtn ms-1"
                      @click="addParent(item)"
                      :disabled="members.selectedParent == null"
                      ><i class="fas fa-check"></i
                    ></el-button>
                  </div>
                  <el-button
                    v-else
                    circle
                    class="memberBtn addParentBtn"
                    @click.prevent="item.queryParent = true"
                    ><i class="fas fa-user-plus me-1"></i>加家長</el-button
                  >
                </div>
                <el-button
                  class="ms-2 memberBtn"
                  v-if="members.canAddAllMember || !item.canParentEdit"
                  circle
                  @click="removeMember(item, 'Student')"
                  ><i class="fa-regular fa-trash-can"></i
                ></el-button>
              </div>
            </div>
            <ul class="list-group list-group-flush w-100">
              <li
                v-for="x in item.parents"
                :key="x"
                class="list-group-item text-start d-flex flex-column align-items-start justify-content-between pe-0 ms-2"
              >
                <div class="d-flex align-items-center w-100">
                  <input type="hidden" :value="x.id" />
                  <el-avatar size="medium" :src="x.avatar"></el-avatar>
                  <div>
                    <p class="ms-3 parent_info memberName">
                      <el-tag
                        v-if="x.isInvitee"
                        type="primary"
                        :key="tag"
                        effect="dark"
                        size="small"
                        class="me-1"
                        >邀請中</el-tag
                      >
                      <el-tag
                        type="info"
                        :key="tag"
                        color="#b8b8b8"
                        effect="dark"
                        size="small"
                        class="me-1"
                        >家長</el-tag
                      >
                      <span> {{ x.name }}</span>
                    </p>
                    <p class="ms-3 parent_info memberEmail">{{ x.email }}</p>
                  </div>
                  <el-button
                    v-if="members.canAddAllMember || item.canParentEdit"
                    class="ms-auto memberBtn"
                    circle
                    @click="removeParent(x, item)"
                    ><i class="fa-regular fa-trash-can"></i
                  ></el-button>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </el-card>
      <el-card class="box-card mx-2 mb-3" style="border-radius: 16px">
        <div
          class="d-flex pb-2 justify-content-between align-items-center title"
          style="font-size: larger; font-weight: 600"
        >
          <h3>申請者</h3>
        </div>
        <ul class="list-group list-group-flush">
          <li
            v-for="item in members.applicants"
            :key="item"
            class="list-group-item text-start d-flex align-items-center justify-content-between px-1"
          >
            <div class="d-flex align-items-center">
              <input type="hidden" :value="item.id" />
              <el-avatar size="medium" :src="item.avatar"></el-avatar>
              <div>
                <p class="ms-3 memberName">{{ item.name }}</p>
                <p class="ms-3 memberEmail">{{ item.email }}</p>
              </div>
            </div>
            <div>
              <el-button
                color="#ffd964"
                @click="hanldeApplicantConfirm(item)"
                :disabled="members.selectedApplicant == null"
                >確認</el-button
              >
              <el-button plain @click="removeMember(item, 'Applicant')"
                >刪除</el-button
              >
            </div>
          </li>
        </ul>
      </el-card>
      <el-card class="box-card mx-2 mb-3" style="border-radius: 16px">
        <div
          class="d-flex pb-2 justify-content-between align-items-center position-relative title"
          style="font-size: larger; font-weight: 600"
        >
          <h3 style="text-align: start">
            受邀者
            <p v-if="$store.getters.mobileSize" class="mt-2">
              (尚未註冊帳號之學生與家長)
            </p>
            <span v-else>(尚未註冊帳號之學生與家長)</span>
          </h3>
          <div
            v-if="members.queryInvitee"
            class="text-end position-absolute addMemberArea"
            style="right: 0rem; top: 1px"
          >
            <el-input
              class="inline-input me-1"
              style="width: 10rem"
              v-model="members.stateOfInviteeName"
              placeholder="名字"
            />
            <el-autocomplete
              :disabled="members.isReadonlySelectedInvitee"
              class="inline-input"
              v-model="members.stateOfInvitee"
              :fetch-suggestions="querySearch"
              placeholder="Email"
              :trigger-on-focus="false"
              @select="handleSelectInvitee"
            >
              <template v-slot="{ item }">
                <div class="w-100 d-flex align-items-center py-2">
                  <el-avatar size="small" :src="item.avatar" />
                  <div class="ms-2">
                    <div style="line-height: normal">
                      <span class="d-block">{{ item.name }}</span>
                      <span>{{ item.email }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </el-autocomplete>
            <el-button
              size="small"
              class="memberBtn ms-1"
              @click="cancelInviteeInput()"
              ><i class="fas fa-times"></i
            ></el-button>
            <el-button size="small" class="memberBtn ms-1" @click="setInvitee()"
              ><i class="fas fa-check"></i
            ></el-button>
          </div>
          <div v-else>
            <el-button circle class="memberBtn" v-if="members.canAddAllMember"
              ><i
                class="fas fa-user-plus"
                @click="members.queryInvitee = true"
              ></i
            ></el-button>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <li
            v-for="item in members.invitees"
            :key="item"
            class="list-group-item text-start d-flex flex-column align-items-start justify-content-between px-1"
          >
            <div class="d-flex align-items-center w-100 py-1">
              <input type="hidden" :value="item.id" />
              <el-avatar size="medium" :src="inviteeAvatar"></el-avatar>
              <div>
                <p class="ms-3 parent_info memberName">
                  <el-tag
                    v-if="item.isInvitee"
                    type="primary"
                    :key="tag"
                    effect="dark"
                    size="small"
                    class="me-1"
                    >邀請中</el-tag
                  >
                  <span v-if="item.name != ''"> {{ item.name }}</span>
                </p>
                <p class="ms-3 memberEmail">{{ item.email }}</p>
              </div>
              <div
                v-if="members.canAddAllMember"
                class="d-flex mt-2 parent_button_group ms-auto"
              >
                <div>
                  <div
                    v-if="item.queryInviteeParent"
                    class="text-end position-absolute addMemberArea"
                    style="right: 0rem"
                  >
                    <el-input
                      class="inline-input me-1"
                      style="width: 10rem"
                      v-model="item.stateOfInviteeParentName"
                      placeholder="名字"
                    />
                    <el-autocomplete
                      :disabled="members.isReadonlySelectedInviteeParent"
                      class="inline-input"
                      v-model="item.stateOfInviteeParent"
                      :fetch-suggestions="querySearchParent"
                      placeholder="Email"
                      :trigger-on-focus="false"
                      @select="handleSelectInviteeParent"
                    >
                      <template v-slot="{ item }">
                        <div class="w-100 d-flex align-items-center py-2">
                          <el-avatar size="small" :src="item.avatar" />
                          <div class="ms-2">
                            <div style="line-height: normal">
                              <span class="d-block memberName">{{
                                item.name
                              }}</span>
                              <span class="memberMail">{{ item.email }}</span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-autocomplete>
                    <el-button
                      size="small"
                      class="memberBtn ms-1"
                      @click="cancelInviteeParentInput(item)"
                      ><i class="fas fa-times"></i
                    ></el-button>
                    <el-button
                      size="small"
                      class="memberBtn ms-1"
                      @click="setInviteeParent(item)"
                      ><i class="fas fa-check"></i
                    ></el-button>
                  </div>
                  <el-button
                    v-else
                    circle
                    class="memberBtn addParentBtn"
                    @click.prevent="item.queryInviteeParent = true"
                    ><i class="fas fa-user-plus me-1"></i>加家長</el-button
                  >
                </div>
                <el-button
                  class="ms-2 memberBtn"
                  v-if="members.canAddAllMember"
                  circle
                  @click="removeMember(item, 'Invitee')"
                  ><i class="fa-regular fa-trash-can"></i
                ></el-button>
              </div>
            </div>
            <ul class="list-group list-group-flush w-100">
              <li
                v-for="x in item.parents"
                :key="x"
                class="list-group-item text-start d-flex flex-column align-items-start justify-content-between pe-0 ms-2"
              >
                <div class="d-flex align-items-center w-100">
                  <input type="hidden" :value="x.id" />
                  <el-avatar
                    size="medium"
                    :src="x.avatar == '' ? inviteeAvatar : x.avatar"
                  ></el-avatar>
                  <div>
                    <p class="ms-3 parent_info memberName">
                      <el-tag
                        v-if="x.isInvitee"
                        type="primary"
                        :key="tag"
                        effect="dark"
                        size="small"
                        class="me-1"
                        >邀請中</el-tag
                      >
                      <el-tag
                        type="info"
                        :key="tag"
                        color="#b8b8b8"
                        effect="dark"
                        size="small"
                        class="me-1"
                        >家長</el-tag
                      >
                      <span v-if="x.name != ''"> {{ x.name }}</span>
                    </p>
                    <p class="ms-3 parent_info memberEmail">{{ x.email }}</p>
                  </div>
                  <el-button
                    v-if="members.canAddAllMember"
                    class="ms-auto memberBtn"
                    circle
                    @click="removeParent(x, item, 'InviteeParent')"
                    ><i class="fa-regular fa-trash-can"></i
                  ></el-button>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "ClassRoomMember",
  components: {},
  props: ["isPrimaryTeacher"],
  data() {
    return {
      members: {
        memberData: [],
        stateOfTeacher: "",
        stateOfStudent: "",
        stateOfParent: "",
        stateOfInvitee: "",
        stateOfInviteeParent: "",
        stateOfInviteeName: "",
        stateOfInviteeParentName: "",
        queryTeacher: false,
        queryStudent: false,
        queryParent: false,
        queryInvitee: false,
        queryInviteeParent: false,
        selectedApplicant: {
          memberId: "",
          name: "",
          avatar: "",
          email: "",
        },
        selectedTeacher: null,
        selectedStudent: null,
        selectedParent: null,
        selectedInvitee: null,
        selectedInviteeParent: null,
        isReadonlySelectedTeacher: false,
        isReadonlySelectedStudent: false,
        isReadonlySelectedParent: false,
        isReadonlySelectedInvitee: false,
        isReadonlySelectedInviteeParent: false,
        teachers: [],
        students: [],
        parents: [],
        invitees: [],
        inviteeParents: [],
        applicants: [],
        ownerAvatar: "",
        ownerId: "",
        ownerName: "",
        ownerEmail: "",
        canAddAllMember: false,
        canParentEdit: false,
        canStudentEdit: false,
      },
      inviteeAvatar:
        "https://chargemestorage.blob.core.windows.net/shared-image/male.png",
    };
  },
  mounted() {},
  watch: {
    "$store.state.classRoomTabActiveName": function () {
      // const vm = this;
      // if (newVal == "third") {
      // }
    },
  },
  computed: {
    classRoomId() {
      return this.$route.params.id;
    },
    studentOptions() {
      return this.$store.state.workItem.studentOptions;
    },
  },
  created() {
    const vm = this;
    vm.initMemberData();
    vm.loadAllMember();
  },
  methods: {
    // Invitee
    cancelInviteeInput() {
      if (this.members.selectedInvitee != null) {
        this.members.selectedInvitee = null;
        this.members.isReadonlySelectedInvitee = false;
      } else this.members.queryInvitee = false;
      this.members.stateOfInvitee = "";
      this.members.stateOfInviteeName = "";
    },
    cancelInviteeParentInput(item) {
      if (this.members.selectedInviteeParent != null) {
        this.members.selectedInviteeParent = null;
        this.members.isReadonlySelectedInviteeParent = false;
      } else item.queryInviteeParent = false;
      item.stateOfInviteeParent = "";
      item.stateOfInviteeParentName = "";
    },
    async setInvitee() {
      const vm = this;
      var email =
        vm.members.selectedInvitee == null
          ? vm.members.stateOfInvitee
          : vm.members.selectedInvitee.email;
      const data = {
        ClassRoomId: vm.classRoomId,
        Email: email,
        Avatar: vm.inviteeAvatar,
        Name: vm.members.stateOfInviteeName,
      };
      if (data.Email == "") {
        vm.notify("warning", `請輸入受邀者Email！`);
        return;
      }
      if (vm.members.invitees.find((x) => x.email == data.Email) != undefined) {
        vm.notify("error", `已邀請並寄出Email，請勿重複邀請！`);
        return;
      }
      if (
        vm.members.inviteeParents.find((x) => x.email == data.Email) !=
        undefined
      ) {
        vm.notify("error", `已邀請此家長，請勿重複邀請成為學生！`);
        return;
      }

      //axios
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/ClassRoom/Member/Invitee`,
        data
      )
        .then((res) => {
          if (res.data.isInvitee) {
            vm.members.selectedInvitee = {
              memberId: res.data.memberId,
              name: vm.getMemberName(
                res.data.memberId,
                vm.members.stateOfInviteeName
              ),
              avatar: vm.inviteeAvatar,
              email: email,
              isInvitee: true,
            };
            vm.setRole("Invitee");
          } else {
            vm.members.selectedStudent = vm.members.memberData.find(
              (x) => x.memberId == res.data.memberId
            );
            vm.setRole("Student");
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `邀請失敗，${err.response.data.Msg}!`);
        });
    },
    async setInviteeParent(student) {
      const vm = this;
      var email =
        vm.members.selectedInviteeParent == null
          ? student.stateOfInviteeParent
          : vm.members.selectedInviteeParent.email;
      const data = {
        ClassRoomId: vm.classRoomId,
        Email: email,
        Avatar: vm.inviteeAvatar,
        Name: student.stateOfInviteeParentName,
      };
      if (data.Email == "") {
        vm.notify("warning", `請輸入受邀者Email！`);
        return;
      }
      if (vm.members.invitees.find((x) => x.email == data.Email) != undefined) {
        vm.notify("error", `已邀請此學生，請勿重複邀請成為家長！`);
        return;
      }
      if (
        vm.members.students.find((x) => x.email == data.Email) != undefined ||
        vm.members.teachers.find((x) => x.email == data.Email) != undefined
      ) {
        vm.notify("error", `無法新增，此成員已新增過腳色！`);
        return;
      }

      //axios
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/ClassRoom/Member/Invitee`,
        data
      )
        .then((res) => {
          if (res.data.isInvitee) {
            vm.members.selectedInviteeParent = {
              memberId: res.data.memberId,
              name: vm.getMemberName(
                res.data.memberId,
                student.stateOfInviteeParentName
              ),
              avatar: vm.inviteeAvatar,
              email: email,
              isInvitee: true,
            };
            vm.addParent(student, "InviteeParent");
          } else {
            vm.members.selectedInviteeParent = vm.members.memberData.find(
              (x) => x.memberId == res.data.memberId
            );
            vm.addParent(student, "InviteeParent");
          }
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `邀請失敗，${err.response.data.Msg}!`);
        });
    },
    //
    loadAllMember() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Member/all`)
        .then((res) => {
          res.data.forEach((x) => {
            vm.members.memberData.push({
              value: vm.getMemberName(x.memberId, x.name),
              memberId: x.memberId,
              name: vm.getMemberName(x.memberId, x.name),
              nickName: x.nickName,
              avatar: x.avatar,
              role: x.role,
              email: x.email,
              isInvitee: x.isInvitee,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initMemberData() {
      const vm = this;
      (vm.members = {
        memberData: [],
        stateOfTeacher: "",
        stateOfStudent: "",
        stateOfParent: "",
        stateOfInvitee: "",
        stateOfInviteeParent: "",
        stateOfInviteeName: "",
        stateOfInviteeParentName: "",
        queryTeacher: false,
        queryStudent: false,
        queryParent: false,
        queryInvitee: false,
        queryInviteeParent: false,
        selectedApplicant: {
          memberId: "",
          name: "",
          avatar: "",
          email: "",
        },
        selectedTeacher: null,
        selectedStudent: null,
        selectedParent: null,
        selectedInvitee: null,
        selectedInviteeParent: null,
        isReadonlySelectedTeacher: false,
        isReadonlySelectedStudent: false,
        isReadonlySelectedParent: false,
        isReadonlySelectedInvitee: false,
        isReadonlySelectedInviteeParent: false,
        teachers: [],
        students: [],
        parents: [],
        invitees: [],
        inviteeParents: [],
        applicants: [],
        ownerAvatar: "",
        ownerId: "",
        ownerName: "",
        ownerEmail: "",
        canAddAllMember: false,
        canParentEdit: false,
        canStudentEdit: false,
      }),
        vm
          .fetchAPI(
            "get",
            `${process.env.VUE_APP_URL_API}/ClassRoom/Member/${vm.classRoomId}`
          )
          .then((res) => {
            res.data.forEach((x) => {
              x.canParentEdit = false;
              x.stateOfParent = "";
              x.stateOfInviteeParent = "";
              x.stateOfInviteeParentName = "";
              x.queryTeacher = false;
              x.name = vm.getMemberName(x.memberId, x.name);
              if (x.parents != null) {
                if (!x.isInvitee) {
                  x.parents.forEach((y) => {
                    vm.members.parents.push(y.memberId);
                  });
                } else {
                  x.parents.forEach((y) => {
                    vm.members.inviteeParents.push({
                      memberId: y.memberId,
                      email: y.email,
                    });
                  });
                }
              }

              if (x.memberId == vm.currentUser().id) {
                if (x.role == "Owner" || x.role == "Teacher")
                  vm.members.canAddAllMember = true;
                if (x.role == "Student") x.canParentEdit = true;
              }

              if (x.role == "Teacher") {
                vm.members.teachers.push(x);
              } else if (x.role == "Student") {
                vm.members.students.push(x);
              } else if (x.role == "Invitee") {
                vm.members.invitees.push(x);
              } else if (x.role == "Applicant") {
                vm.members.applicants.push(x);
              } else if (x.role == "Owner") {
                vm.members.ownerId = x.memberId;
                vm.members.ownerName = x.name;
                vm.members.ownerAvatar = x.avatar;
                vm.members.ownerEmail = x.email;
              }
            });
            vm.members.teachers.sort((a, b) => {
              return a.isPrimaryTeacher === b.isPrimaryTeacher
                ? 0
                : a.isPrimaryTeacher
                ? -1
                : 1;
            });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    hanldeApplicantConfirm(item) {
      this.handleSelectApplicant(item);
      this.setRole("Applicant");
    },
    handleSelectTeacher(item) {
      this.members.selectedTeacher = item;
      this.members.isReadonlySelectedTeacher = true;
    },
    handleSelectApplicant(item) {
      this.members.selectedApplicant.memberId = item.memberId;
      this.members.selectedApplicant.name = item.name;
      this.members.selectedApplicant.avatar = item.avatar;
      this.members.selectedApplicant.email = item.email;
    },
    handleSelectStudent(item) {
      this.members.selectedStudent = item;
      this.members.isReadonlySelectedStudent = true;
    },
    handleSelectParent(item) {
      this.members.selectedParent = item;
      this.members.isReadonlySelectedParent = true;
    },
    handleSelectInvitee(item) {
      this.members.selectedInvitee = item;
      this.members.isReadonlySelectedInvitee = true;
    },
    handleSelectInviteeParent(item) {
      this.members.selectedInviteeParent = item;
      this.members.isReadonlySelectedInviteeParent = true;
    },
    cancelAutoComplete(role, item = null) {
      if (role == "Teacher") {
        if (this.members.selectedTeacher != null) {
          this.members.selectedTeacher = null;
          this.members.isReadonlySelectedTeacher = false;
        } else this.members.queryTeacher = false;
        this.members.stateOfTeacher = "";
      } else if (role == "Student") {
        if (this.members.selectedStudent != null) {
          this.members.selectedStudent = null;
          this.members.isReadonlySelectedStudent = false;
        } else this.members.queryStudent = false;
        this.members.stateOfStudent = "";
      } else if (role == "Parent") {
        if (this.members.selectedParent != null) {
          this.members.selectedParent = null;
          this.members.isReadonlySelectedParent = false;
        } else item.queryParent = false;
        item.stateOfParent = "";
      }
    },
    changePrimaryTeacher(item) {
      const vm = this;
      this.$confirm(`確定要交換代表老師嗎？`, "警告", {
        confirmButtonText: "確認",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var originTeacher = vm.members.teachers.find(
            (x) => x.isPrimaryTeacher
          );
          let data = {
            ClassRoomId: vm.classRoomId,
            OriginTeacherId: originTeacher.memberId,
            NewTeacherId: item.memberId,
          };
          vm.fetchAPI(
            "put",
            `${process.env.VUE_APP_URL_API}/ClassRoom/Member/PrimaryTeacher`,
            data
          )
            .then(() => {
              originTeacher.isPrimaryTeacher = false;
              var newTeacher = vm.members.teachers.find(
                (x) => x.memberId == item.memberId
              );
              newTeacher.isPrimaryTeacher = true;
              vm.members.teachers.sort((a, b) => {
                return a.isPrimaryTeacher === b.isPrimaryTeacher
                  ? 0
                  : a.isPrimaryTeacher
                  ? -1
                  : 1;
              });
              this.$emit("rerenderClassRoom");
              vm.$message({
                type: "success",
                message: "交換代表老師成功！",
              });
            })
            .catch((err) => {
              console.log(err);
              vm.notify("error", "交換代表老師失敗！");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    setRole(role) {
      const vm = this;
      var memberId = "";
      if (role == "Teacher") {
        memberId = vm.members.selectedTeacher.memberId;
      } else if (role == "Student") {
        memberId = vm.members.selectedStudent.memberId;
      } else if (role == "Applicant") {
        memberId = vm.members.selectedApplicant.memberId;
      } else if (role == "Invitee") {
        memberId = vm.members.selectedInvitee.memberId;
      } else {
        memberId = vm.members.selectedParent.memberId;
      }

      var parentValid = false;
      vm.members.students.forEach((x) => {
        if (x.parents.filter((y) => y.memberId == memberId).length != 0)
          parentValid = true;
      });

      if (
        parentValid ||
        vm.members.teachers.filter((x) => x.memberId == memberId).length != 0 ||
        vm.members.students.filter((x) => x.memberId == memberId).length != 0
      ) {
        vm.notify("error", `無法新增，此成員已新增過角色！`);
        if (role == "Teacher") {
          vm.members.stateOfTeacher = "";
          vm.members.queryTeacher = false;
        } else if (role == "Student") {
          vm.members.stateOfStudent = "";
          vm.members.queryStudent = false;
        }
        return;
      }
      var tempRole = role;
      if (role == "Applicant") role = "Student";

      const data = {
        ClassRoomId: vm.classRoomId,
        MemberId: memberId,
        Role: role,
      };
      //axios
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/ClassRoom/Member`,
        data
      )
        .then(() => {
          if (tempRole == "Teacher") {
            vm.members.teachers.push({
              memberId: vm.members.selectedTeacher.memberId,
              name: vm.members.selectedTeacher.name,
              avatar: vm.members.selectedTeacher.avatar,
              email: vm.members.selectedTeacher.email,
              isPrimaryTeacher: false,
            });
            vm.members.stateOfTeacher = "";
            vm.members.queryTeacher = false;
            vm.members.selectedTeacher = null;
            vm.members.isReadonlySelectedTeacher = false;
          } else if (tempRole == "Student") {
            vm.members.students.push({
              memberId: vm.members.selectedStudent.memberId,
              name: vm.members.selectedStudent.name,
              avatar: vm.members.selectedStudent.avatar,
              email: vm.members.selectedStudent.email,
              parents: [],
            });
            vm.members.stateOfStudent = "";
            vm.members.queryStudent = false;

            vm.$store.state.workItem.studentOptions.push({
              label: vm.getMemberName(
                vm.members.selectedStudent.memberId,
                vm.members.selectedStudent.name
              ),
              value: vm.members.selectedStudent.memberId,
              avatar: vm.members.selectedStudent.avatar,
            });
            vm.members.selectedStudent = null;
            vm.members.isReadonlySelectedStudent = false;

            // 多重製Invitee
            vm.members.stateOfInvitee = "";
            vm.members.stateOfInviteeName = "";
            vm.members.queryInvitee = false;
            vm.members.selectedInvitee = null;
            vm.members.isReadonlySelectedInvitee = false;
          } else if (tempRole == "Invitee") {
            vm.members.invitees.push({
              memberId: vm.members.selectedInvitee.memberId,
              name: vm.members.selectedInvitee.name,
              avatar: vm.members.selectedInvitee.avatar,
              email: vm.members.selectedInvitee.email,
              isInvitee: vm.members.selectedInvitee.isInvitee,
              parents: [],
            });
            vm.members.stateOfInvitee = "";
            vm.members.stateOfInviteeName = "";
            vm.members.queryInvitee = false;
            vm.members.selectedInvitee = null;
            vm.members.isReadonlySelectedInvitee = false;
          } else if (tempRole == "Applicant") {
            vm.members.applicants = vm.members.applicants.filter(
              (x) => x.memberId != vm.members.selectedApplicant.memberId
            );
            vm.members.students.push({
              memberId: vm.members.selectedApplicant.memberId,
              name: vm.members.selectedApplicant.name,
              avatar: vm.members.selectedApplicant.avatar,
              email: vm.members.selectedApplicant.email,
            });
            vm.members.selectedApplicant = null;
          }

          vm.$message({
            type: "success",
            message: "新增成功!",
          });
        })
        .catch((err) => {
          console.log(err);
          vm.notify("error", `新增失敗！`);
        });
    },
    removeMember(item, role) {
      const vm = this;
      let data = {
        classRoomId: vm.classRoomId,
        memberId: item.memberId,
      };

      vm.fetchAPI(
        "delete",
        `${process.env.VUE_APP_URL_API}/ClassRoom/Member`,
        data
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "移除成員成功",
          });
          if (role == "Teacher") {
            vm.members.teachers = vm.members.teachers.filter(
              (x) => x.memberId !== item.memberId
            );
          } else if (role == "Student") {
            vm.members.students = vm.members.students.filter(
              (x) => x.memberId !== item.memberId
            );
            vm.$store.state.workItem.studentOptions =
              vm.$store.state.workItem.studentOptions.filter(
                (x) => x.value !== item.memberId
              );

            var assigneeListId = null;
            if (vm.$store.state.workItem.task != null)
              assigneeListId = vm.$store.state.workItem.task.assigneeListId;

            if (assigneeListId != null || assigneeListId != undefined) {
              assigneeListId = assigneeListId.filter(
                (x) => x !== item.memberId
              );
            }
          } else if (role == "Invitee") {
            vm.members.invitees = vm.members.invitees.filter(
              (x) => x.memberId !== item.memberId
            );
          } else if (role == "Applicant") {
            vm.members.applicants = vm.members.applicants.filter(
              (x) => x.memberId !== item.memberId
            );
            vm.$store.state.workItem.studentOptions =
              vm.$store.state.workItem.studentOptions.filter(
                (x) => x.value !== item.memberId
              );
          } else {
            vm.members.stateOfParent = "";
            vm.members.queryParent = false;
          }
        })
        .catch((err) => {
          console.log(err);
          vm.notify("error", "移除成員失敗！");
        });
    },
    addParent(item, role = "") {
      const vm = this;
      var parentId;
      var parentIdOfStudent;
      if (role == "") {
        parentId = vm.members.selectedParent.memberId;
      } else if (role == "InviteeParent") {
        parentId = vm.members.selectedInviteeParent.memberId;
      }

      parentIdOfStudent = item.parents.map((x) => x.memberId);
      if (parentIdOfStudent.includes(parentId)) {
        vm.notify("error", `無法新增，此成員已新增過腳色！`);
        return;
      }

      const data = {
        ClassRoomId: vm.classRoomId,
        MemberId: item.memberId,
        ParentId: parentId,
      };
      // //axios
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/ClassRoom/Member/Parent`,
        data
      )
        .then(() => {
          if (role == "") {
            var student = vm.members.students.find(
              (x) => x.memberId == item.memberId
            );
            student.parents.push({
              memberId: vm.members.selectedParent.memberId,
              name: vm.members.selectedParent.name,
              avatar: vm.members.selectedParent.avatar,
              email: vm.members.selectedParent.email,
            });
            student.stateOfParent = "";
            student.queryParent = false;

            vm.members.parents.push(vm.members.selectedParent.memberId);
            vm.members.selectedParent = null;
            vm.members.isReadonlySelectedParent = false;
          } else if (role == "InviteeParent") {
            var invitee = vm.members.invitees.find(
              (x) => x.memberId == item.memberId
            );
            invitee.parents.push({
              memberId: vm.members.selectedInviteeParent.memberId,
              name: vm.members.selectedInviteeParent.name,
              avatar: vm.members.selectedInviteeParent.avatar,
              email: vm.members.selectedInviteeParent.email,
              isInvitee: vm.members.selectedInviteeParent.isInvitee,
            });
            invitee.stateOfInviteeParent = "";
            invitee.stateOfInviteeParentName = "";
            invitee.queryInviteeParent = false;

            vm.members.inviteeParents.push({
              memberId: vm.members.selectedInviteeParent.memberId,
              email: vm.members.selectedInviteeParent.email,
            });
            vm.members.selectedInviteeParent = null;
            vm.members.isReadonlySelectedInviteeParent = false;
          }

          vm.$message({
            type: "success",
            message: "新增成功!",
          });
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `新增失敗！`);
        });
    },
    removeParent(parent, student, role = "") {
      const vm = this;
      let data = {
        ClassRoomId: vm.classRoomId,
        MemberId: student.memberId,
        ParentId: parent.memberId,
      };
      vm.fetchAPI(
        "delete",
        `${process.env.VUE_APP_URL_API}/ClassRoom/Member/Parent`,
        data
      )
        .then(() => {
          if (role == "") {
            var stu = vm.members.students.find(
              (x) => x.memberId == student.memberId
            );
            stu.parents = stu.parents.filter(
              (x) => x.memberId !== parent.memberId
            );
            let index = vm.members.parents.findIndex(
              (element) => element == parent.memberId
            );
            if (index !== -1) {
              vm.members.parents.splice(index, 1);
            }
          } else if (role == "InviteeParent") {
            var invitee = vm.members.invitees.find(
              (x) => x.memberId == student.memberId
            );
            invitee.parents = invitee.parents.filter(
              (x) => x.memberId !== parent.memberId
            );
            let index = vm.members.inviteeParents.findIndex(
              (element) => element.memberId == parent.memberId
            );
            if (index !== -1) {
              vm.members.inviteeParents.splice(index, 1);
            }
          }

          this.$message({
            type: "success",
            message: "移除家長成功",
          });
        })
        .catch((err) => {
          console.log(err);
          vm.notify("error", "移除家長失敗！");
        });
    },
    querySearch(queryString, cb) {
      var results = queryString
        ? this.members.memberData.filter(this.createFilter(queryString))
        : this.members.memberData;
      var classTeacher = this.members.teachers.map((y) => y.memberId);
      var classStudent = this.members.students.map((y) => y.memberId);
      var classApplicant = this.members.applicants.map((y) => y.memberId);
      var classInviteeParent = this.members.inviteeParents.map(
        (y) => y.memberId
      );
      var temp = results.filter(
        (x) =>
          !x.isInvitee &&
          !classTeacher.includes(x.memberId) &&
          !classStudent.includes(x.memberId) &&
          !classApplicant.includes(x.memberId) &&
          !this.members.parents.includes(x.memberId) &&
          !classInviteeParent.includes(x.memberId)
      );
      cb(temp);
    },
    querySearchParent(queryString, cb) {
      var results = queryString
        ? this.members.memberData.filter(this.createFilter(queryString))
        : this.members.memberData;
      var classTeacher = this.members.teachers.map((y) => y.memberId);
      var classStudent = this.members.students.map((y) => y.memberId);
      var classApplicant = this.members.applicants.map((y) => y.memberId);
      var temp = results.filter(
        (x) =>
          !x.isInvitee &&
          !classTeacher.includes(x.memberId) &&
          !classStudent.includes(x.memberId) &&
          !classApplicant.includes(x.memberId)
      );
      cb(temp);
    },
    createFilter(queryString) {
      return (r) => {
        return (
          r.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ||
          r.email.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.memberName {
  font-weight: bold;
  font-size: small;
}
.memberEmail {
  padding-top: 0.25rem;
  font-weight: bold;
  font-size: x-small;
  color: rgba(0, 0, 0, 0.5);
}
.addMemberArea {
  background-color: white;
  border: 1px solid #77724b;
  border-radius: 8px;
  padding: 0.25rem;
  z-index: 4;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}
.memberBtn {
  width: 25px;
  height: 25px;
  border-radius: 99em;
  font-size: xx-small;
  color: #77724b;
}
.addParentBtn {
  width: unset;
}
.star {
  margin-right: 0.5rem;
  font-size: 1.5rem;
  color: #e7d50e;
}
@media screen and (max-width: 768px) {
  .parent_button_group {
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }
}
</style>
