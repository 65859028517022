<template>
  <div
    class="card "
    :class="{
      clicked: isClick,
      checked: taskModel.checked && isContactTodoCompleteCheckedSettingOn,
    }"
    @mousedown="isClick = true"
    @mouseout="isClick = false"
    @mouseover="isHover = true"
    @mouseleave="isHover = false"
  >
    <div class="d-flex align-items-start w-100">
      <span class="drag-handle mx-1"  v-if="$store.getters.mobileSize &&  !taskModel.checked"><i class="fa-solid fa-grip-dots-vertical"></i></span>
      <!-- <el-checkbox
        v-model="taskModel.checked"
        class="ms-2 me-2  d-none"
      ></el-checkbox> -->


      <el-button    
       v-if="isContactTodoCompleteCheckedSettingOn"
        @click.prevent.stop="Done()" 
        class=" me-2 py-2" link>
        <i  v-if="taskModel.checked" class="fa-duotone fa-circle-check fa-lg" style="--fa-primary-color: #ff9500; --fa-secondary-color: #ffbf66;"></i>
       
        <i v-else class="fa-regular fa-circle fa-lg "></i>
    </el-button>
    

    <i v-if="taskModel.isNecessary" class="fas fa-bookmark me-2" style="color: #96beab"></i> 
      <div  
        class="title"
        :class="{
          checked: taskModel.checked && isContactTodoCompleteCheckedSettingOn,
        }"
      >
        <p  >{{ taskModel.title }}</p>
        <p class="dueDateArea" v-if="taskModel.dueDate != null">{{ taskModel_DueDate }}</p>
          <div class="mt-2" v-if="showIcon(taskModel) "
      > 
      <el-tag
      v-if="taskModel.content != ''"
      round
      class="hasfileIcon"
      size="small"
      type="success"
    >
      <i class="fa-light fa-circle-info"></i>
    </el-tag>
    <el-tag
    v-if="taskModel.linkList.length != 0"
      round
      class="hasfileIcon"
      size="small"
      type="success"
    >
    <i  class="fa-sharp fa-regular fa-link "></i>
    </el-tag>  
     <el-tag
    v-if="taskModel.fileList.length != 0"
      round
      class="hasfileIcon"
      size="small"
      type="success"
    >
    <i class="fa-light fa-file "></i>
    </el-tag>
     
     
   </div>
      </div>
      <div class="d-flex align-items-center ms-auto">
        <i
          v-if="!taskModel.isInformDueTask"
          class="fa-solid fa-volume-slash"
        ></i>
        <p class="mx-2" style="font-size: x-small">
          <span
            v-if="
              taskModel.assigneeIsDoneCount == taskModel.assigneeCount &&
              taskModel.assigneeCount != 0
            "
            class="text-success fw-bolder"
          >
            {{ taskModel.assigneeIsDoneCount }}/{{ taskModel.assigneeCount }}
          </span>
          <span v-else>
            {{ taskModel.assigneeIsDoneCount }}/{{ taskModel.assigneeCount }}
          </span>
        </p>
        <!-- <div
          v-if="$store.getters.mobileSize"
          class="common up"
          @click.stop="orderUp(taskModel.id)"
        >
          <i class="fal fa-square-up" :class="{ active: isHover }"></i>
        </div>
        <div
          v-if="$store.getters.mobileSize"
          class="common down"
          @click.stop="orderDown(taskModel.id)"
        >
          <i class="fal fa-square-down" :class="{ active: isHover }"></i>
        </div> -->

        <el-dropdown trigger="click">
          <div class="px-2" @click.prevent.stop>
            <i class="far fa-ellipsis-v"></i>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <div class="common rename" @click.stop="renameTask(taskModel)">
                  <i
                    class="fa-regular fa-retweet"
                    :class="{ active: isHover }"
                  ></i>
                  <span>重新命名</span>
                </div></el-dropdown-item
              >
              <el-dropdown-item>
                <div
                  class="common plus"
                  @click.stop="copyTaskName(taskModel.title)"
                >
                  <i class="fal fa-copy" :class="{ active: isHover }"></i>
                  <span>複製標題</span>
                </div></el-dropdown-item
              >
              <el-dropdown-item>
                <div
                  class="common plus"
                  @click.stop="addTaskTemplate(taskModel.id)"
                >
                  <i
                    class="fal fa-plus-square"
                    :class="{ active: isHover }"
                  ></i>
                  <span>設定範本</span>
                </div></el-dropdown-item
              >
              <el-dropdown-item>
                <div class="common trash" @click.stop="settingTask(taskModel)">
                  <i
                    class="fa-solid fa-sliders-up"
                    :class="{ active: isHover }"
                  ></i>
                  <span v-if="!taskModel.isInformDueTask">啟動逾期通知</span>
                  <span v-else>關閉逾期通知</span>
                </div></el-dropdown-item
              >
              <el-dropdown-item>
              <div class="common trash" @click.stop="deleteTask(taskModel.id)">
                <i class="fal fa-trash-alt" :class="{ active: isHover }"></i>
                <span>刪除</span>
              </div></el-dropdown-item
            >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  
  </div>
</template>

<script>
export default {
  props: ["task", "isContactTodoCompleteCheckedSettingOn"],
  data() {
    return {
      dropdown: "",
      isHover: false,
      isClick: false,
      showSetDueDate: false,
    };
  },
  computed: {
    taskModel: {
      get() {
        return this.task;
      },
    },
    taskModel_DueDate:{

      get() {   
        return  this.task.dueDate
    }
  }
  },
  methods: {
    copyTaskName(taskTitle) {
      const taskName = taskTitle;

      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(taskName)
          .then(() => {
            this.notify("success", "名稱已複製到剪貼板！");
          })
          .catch((err) => {
            console.error("複製失敗: ", err);
            this.notify("error", "複製失敗！");
          });
      } else {
        try {
          const textArea = document.createElement("textarea");
          textArea.value = taskName;
          document.body.appendChild(textArea);
          textArea.select();
          document.execCommand("copy");
          document.body.removeChild(textArea);
          this.notify("success", "名稱已複製到剪貼板！");
        } catch (err) {
          console.error("複製失敗: ", err);
          this.notify("error", "複製失敗！");
        }
      }
    },
    notify(type, message) {
      this.$message({
        type: type,
        message: message,
      });
    },
    open() {
      this.$emit("showMain", this.taskModel);
    },
    showIcon(task) {
      if (
        task.linkList.length != 0 ||
        task.fileList.length != 0 ||
        task.content != ""
      ) {
        return true;
      }
      return false;
    },
    orderUp(id) {
      this.$emit("orderUp", id);
    },
    orderDown(id) {
      this.$emit("orderDown", id);
    },
    addTaskTemplate(id) {
      this.$emit("addTaskTemplate", id);
    },
    deleteTask(id) {
      this.$emit("deleteTask", id);
    },
    renameTask(taskModel) {
      this.$emit("renameTask", taskModel);
    },
    settingTask(taskModel) {
      this.$emit("settingTask", taskModel);
    },
    Done() {
      const vm = this;
      vm.taskModel.checked = !vm.taskModel.checked;
      vm.fetchAPI(
        "put",
        `${process.env.VUE_APP_URL_API}/ClassRoom/ContactBook/ContactTodo/Checked?contactTodoId=${vm.taskModel.id}&check=${vm.taskModel.checked}`
      )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `更新失敗！`);
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.card {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  color: #77724b;
  font-weight: 600;
  // flex-direction: row;
  text-align: start;
  background-color: #fff;
  padding: .5rem 0.1rem;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background-color: rgba(219, 219, 219, 0.763);
  }
  &.clicked {
    cursor: grab;
  }
  &.checked {
    .title {
      text-decoration: line-through;

    }
    // background-color: rgba(131, 228, 139, 0.693);
  }
  .title {
    font-size:medium;
    overflow: hidden;
    widows: 60% !important;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
  }
  p.checked {
    text-decoration: line-through;
  }
  .common {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    i {
      transition: 0.2s;
      opacity: 0;
      &:hover {
        color: rgb(0, 0, 0);
        font-size: 900;
      }
      &.active {
        opacity: 100;
      }
    }
  }
}
.hasfileIcon {
  // position: absolute;
  margin-right:.25rem ;
}
.common i {
  width: 1rem !important;
}
.dueDateArea {
  display: block;
  bottom: 0.25rem;
  left: 1rem;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 100;
  font-size: small;
  margin-top: .25rem;
}
@media screen and (max-width: 768px) {
  .card {
    .common {
      i {
        display: none;
      }
    }
  }
}
</style>
