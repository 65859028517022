<template>
  <div class="calendar">
    <datepicker class="dateCalendar" inline="true" @input="dateSelected" :value="selectedDate"></datepicker>
  </div>
  <div class="dataList" :class="{ mobile: $store.getters.mobileSize }">
    <div class="d-flex justify-content-between align-items-center filteredData" @click="updateSelectContactInfoFromCalendar(item)" v-for="item in filteredData" :key="item.contactBookId">
      <div class="d-flex align-items-center">
        <p class="me-2">{{ item.weekOfYear }}</p>
        <p>{{ item.title }}</p>
      </div>
      <p>{{ item.createdTime }}</p>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs3-datepicker";
const dayjs = require("dayjs");
var weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);

export default {
  props: ["contactBookData"],
  components: {
    Datepicker,
  },
  data() {
    return {
      selectedDate: new Date(),
      calendarMonth: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    };
  },
  computed: {
    filteredData() {
      return this.contactBookData.contactBookList.filter(item => {
        // eslint-disable-next-line no-useless-escape
        const dateParts = item.createdTime.split(/[\/\s]/);
        const month = parseInt(dateParts[1], 10);
        const day = parseInt(dateParts[2], 10);

        return month === dayjs(this.selectedDate).month() + 1 && day === dayjs(this.selectedDate).date();
      });
    },
  },
  mounted() {
    const vm = this;
    vm.addCalendarDot();

    // 日曆點點功能
    document.querySelector(".vuejs3-datepicker .prev").addEventListener("click", () => {
      var text = document.querySelector(".vuejs3-datepicker .day__month_btn.up").innerText.split(" ");
      var year = text[1];
      var month = vm.calendarMonth.findIndex(x => x == text[0]) + 1;
      var newDate = new Date(year, month - 1, 1);
      vm.selectedDate = newDate;

      vm.$nextTick(() => {
        vm.addCalendarDot();
      });
    });

    document.querySelector(".vuejs3-datepicker .next").addEventListener("click", () => {
      var text = document.querySelector(".vuejs3-datepicker .day__month_btn.up").innerText.split(" ");
      var year = text[1];
      var month = vm.calendarMonth.findIndex(x => x == text[0]) + 1;
      var newDate = new Date(year, month - 1, 1);
      vm.selectedDate = newDate;

      vm.$nextTick(() => {
        vm.addCalendarDot();
      });
    });
  },
  watch: {
    selectedDate() {
      this.addCalendarDot();
    },
  },
  methods: {
    updateSelectContactInfoFromCalendar(item) {
      this.$emit("updateSelectContactInfoFromCalendar", item);
    },
    addCalendarDot() {
      const numbersArray = this.getDistinctDatesForMonth();
      const spanElements = document.querySelectorAll(".vuejs3-datepicker .cell.day");

      this.$nextTick(() => {
        for (let i = 0; i < spanElements.length; i++) {
          const spanContent = parseInt(spanElements[i].innerText);
          if (numbersArray.includes(spanContent)) {
            spanElements[i].classList.add("matched");
          }
        }
      });
    },
    getDistinctDatesForMonth() {
      const result = new Set();

      this.contactBookData.contactBookList.forEach(item => {
        // eslint-disable-next-line no-useless-escape
        const dateParts = item.createdTime.split(/[\/\s]/);
        const month = parseInt(dateParts[1], 10);
        const day = parseInt(dateParts[2], 10);

        if (month === dayjs(this.selectedDate).month() + 1) {
          result.add(day);
        }
      });

      return Array.from(result);
    },
    dateSelected(newDate) {
      const vm = this;
      vm.selectedDate = newDate;
    },
  },
};
</script>
<style lang="scss" scoped>
:deep .vuejs3-datepicker {
  margin-bottom: 1rem;
  .vuejs3-datepicker__calendar {
    width: 100% !important;
    .vuejs3-datepicker__calendar-topbar {
      background-color: #ffd964;
    }
    .selected {
      background-color: #ffd964;
    }
  }
  .vuejs3-datepicker__calendar-topbar {
    display: none;
  }
  .cell:not(.month):not(.year) {
    height: 2rem;
    line-height: 2rem;
  }
  .vuejs3-datepicker__calendar-actionarea {
    & > div {
      text-align: start;
    }
  }
  .cell.day.matched {
    &:not(.blank)::after {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      background-color: rgb(122, 121, 15);
      border-radius: 50%;
      margin-top: -7px; /* 调整点的垂直位置 */
      margin-left: 50%; /* 调整点的水平位置 */
      transform: translateX(-50%);
    }
  }
}
.calendar {
  padding: 0 1rem;
}
.dataList {
  max-height: 300px;
  overflow-y: auto;
  padding: 0 1rem;
  &.mobile {
    max-height: 500px;
  }
}
.filteredData {
  border: 2px solid #bcbcbc;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  cursor: pointer;
  border-color: #d0af4a;
  &:hover,
  &:focus {
    background-color: #ffe7a6;
  }
  transition: 0.2s;
}
</style>
