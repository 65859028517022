<template>
  <div class="main">
    <div
      v-if="setPermission(classRoomPermission)"
      class="btn_group mb-5 d-flex align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center select">
        <el-select
          v-model="assigneeListId"
          :multiple="true"
          collapse-tags
          placeholder="無指定對象"
        >
          <el-option
            v-for="item in studentOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="d-flex align-items-center">
        <el-button @click="getAllCheckRecord" plain>所有打卡紀錄</el-button>
        <el-button class="ms-2" @click="getAllPayRecord" plain
          >所有繳費紀錄</el-button
        >
        <el-button class="ms-2" @click="payClickAll" plain type="primary"
          >群體繳費</el-button
        >
      </div>
    </div>
    <el-table
      class-name="student_main"
      :border="true"
      max-height="650"
      :data="displayData"
      style="width: 100%"
    >
      <el-table-column
        prop="name"
        label="學員名稱"
        :width="$store.getters.mobileSize ? '65' : '120'"
      />
      <el-table-column
        label="打卡紀錄"
        class-name="check"
        v-if="!$store.getters.mobileSize"
      >
        <template #default="scope">
          <div
            class="student_check"
            :class="{ due: checkItem.isDueTime, owe: checkItem.isOwe }"
            v-for="checkItem in scope.row.classRoomCheckInList"
            :key="checkItem.classRoomCheckInfoId"
          >
            <div>
              <p>{{ checkItem.checkedTime.split(" ")[0] }}</p>
              <span>{{ checkItem.checkedTime.split(" ")[1] }}</span>
            </div>
            <span class="dueTime" v-if="checkItem.isDueTime">(補)</span>
            <span class="owe" v-else-if="checkItem.isOwe">(欠)</span>
          </div>
          <div
            class="student_check check no_content"
            v-if="scope.row.classRoomCheckInList.length == 0"
          >
            尚無打卡紀錄
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="剩餘 / 購買"
        :width="$store.getters.mobileSize ? '65' : '120'"
      >
        <template #default="scope">
          <span
            >{{
              addPayment(scope.row.classRoomPaymentInfoList) -
              scope.row.classRoomCheckInList.length
            }}
            / {{ addPayment(scope.row.classRoomPaymentInfoList) }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="功能區"
        class-name="func"
        :width="$store.getters.mobileSize ? '' : '200'"
      >
        <template v-slot="scope">
          <div class="d-flex mb-2">
            <div class="w-100">
              <el-button
                v-if="
                  addPayment(scope.row.classRoomPaymentInfoList) -
                    scope.row.classRoomCheckInList.length <=
                    0 && setPermission(classRoomPermission)
                "
                class="w-100 checkIn_btn"
                type="danger"
                size="small"
                @click="oweCheckClick(scope.row)"
                >欠費打卡</el-button
              >
              <el-button
                v-else
                class="w-100 checkIn_btn"
                type="primary"
                size="small"
                @click="checkClick(scope.row)"
                >打卡</el-button
              >
            </div>
            <el-button
              v-if="setPermission(classRoomPermission)"
              class="w-100 ms-2 checkIn_btn"
              type="warning"
              size="small"
              @click="payClick(scope.row)"
              >繳費</el-button
            >
          </div>
          <el-dropdown trigger="click" class="w-100">
            <el-button class="w-100 mb-2 checkIn_btn" type="info">
              其他功能<i class="ms-2 fa-sharp fa-solid fa-chevron-down"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-if="setPermission(classRoomPermission)"
                  @click="giftClick(scope.row)"
                  >贈與</el-dropdown-item
                >
                <el-dropdown-item @click="changePayGiftClick(scope.row)"
                  >繳費紀錄</el-dropdown-item
                >
                <el-dropdown-item @click="deleteCheckClick(scope.row)"
                  >打卡紀錄</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-dialog
    title="打卡"
    :width="$store.getters.mobileSize ? '90%' : '30%'"
    v-model="isCheckShow"
  >
    <div class="d-flex justify-content-center flex-column">
      <div
        v-if="setPermission(classRoomPermission)"
        class="d-flex align-items-center justify-content-between"
      >
        <el-checkbox
          class="me-5"
          v-model="checkItem.isOnTime"
          label="準時打卡"
          size="large"
        />
        <el-date-picker
          :disabled="checkItem.isOnTime"
          v-model="checkItem.checkedTime"
          type="datetime"
          placeholder="選擇上課日期與時間"
        />
      </div>
      <el-button
        class="mt-3"
        type="primary"
        @click="createClassRoomCheckIn(false)"
        >確認</el-button
      >
    </div>
  </el-dialog>
  <el-dialog
    title="欠費打卡"
    :width="$store.getters.mobileSize ? '90%' : '30%'"
    v-model="isOweCheckShow"
  >
    <div class="d-flex justify-content-center flex-column">
      <div v-if="setPermission(classRoomPermission)" class="text-start">
        是否要欠費打卡，確認後一樣會打卡成功，下次繳費(或贈與)時會先依據有無欠費打卡，優先補償。
      </div>
      <div class="d-flex">
        <el-button class="mt-3 w-100" type="info" @click="cancelOweCheck()"
          >取消</el-button
        >
        <el-button
          class="mt-3 w-100"
          type="danger"
          @click="createClassRoomCheckIn(true)"
          >確認</el-button
        >
      </div>
    </div>
  </el-dialog>
  <el-dialog
    title="打卡紀錄"
    :width="$store.getters.mobileSize ? '90%' : '35%'"
    v-model="isDeleteCheckShow"
  >
    <el-table :data="deleteCheckItem.classRoomCheckInList" style="width: 100%">
      <el-table-column
        type="expand"
        width="30"
        v-if="$store.getters.mobileSize"
      >
        <template #default="props">
          <div class="my-3 mainContent">
            補卡：
            <span class="content">{{ props.row.isDueTime ? "是" : "否" }}</span>
          </div>
          <div class="my-3 mainContent">
            欠費：
            <span class="content">{{ props.row.isOwe ? "是" : "否" }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="checkedTime"
        label="日期"
        sortable
        :width="$store.getters.mobileSize ? '180' : '200'"
      />
      <el-table-column
        label="補卡"
        sortable
        sort-by="isDueTime"
        :width="$store.getters.mobileSize ? '80' : '100'"
        v-if="!$store.getters.mobileSize"
      >
        <template #default="scope">
          <span>{{ scope.row.isDueTime ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="欠費"
        sortable
        sort-by="isOwe"
        :width="$store.getters.mobileSize ? '80' : '100'"
        v-if="!$store.getters.mobileSize"
      >
        <template #default="scope">
          <span>{{ scope.row.isOwe ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="">
        <template v-slot="scope">
          <i
            v-if="setPermission(classRoomPermission)"
            class="fal fa-trash-alt trash"
            style="cursor: pointer"
            @click="deleteClassRoomCheckIn(scope.row)"
          ></i>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
  <el-dialog
    title="繳費"
    :width="$store.getters.mobileSize ? '90%' : '30%'"
    v-model="isPayShow"
  >
    <div class="d-flex justify-content-center flex-column">
      <div class="d-flex align-items-center mb-2">
        <span>金額：</span>
        <el-input
          class="w-75 flex-grow-1"
          v-model="payItem.payment"
          @input="payItem.payment = turnNumber(payItem.payment)"
        ></el-input>
        <span>&nbsp; /元</span>
      </div>
      <div class="d-flex align-items-center mb-2">
        <span>堂數：</span>
        <el-input
          class="w-75 flex-grow-1"
          v-model="payItem.classRoomNumber"
          @input="payItem.classRoomNumber = turnNumber(payItem.classRoomNumber)"
          placeholder="請輸入繳費堂數"
        />
        <span>&nbsp; /堂</span>
      </div>
      <el-input
        v-model="payItem.remark"
        placeholder="請輸入繳費備註"
        type="textarea"
        :autosize="{ minRows: 5 }"
      />
      <div class="my-2 text-start">※如有欠費打卡紀錄，會優先補償</div>
      <el-button class="mt-3" type="warning" @click="createClassRoomPay()"
        >確認</el-button
      >
    </div>
  </el-dialog>
  <el-dialog
    title="贈與"
    :width="$store.getters.mobileSize ? '90%' : '30%'"
    v-model="isGiftShow"
  >
    <div class="d-flex justify-content-center flex-column">
      <div class="d-flex align-items-center mb-2">
        <span>堂數：</span>
        <el-input
          class="w-75 flex-grow-1"
          v-model="giftItem.classRoomNumber"
          @input="
            giftItem.classRoomNumber = turnNumber(giftItem.classRoomNumber)
          "
          placeholder="請輸入贈與堂數"
        />
        <span>&nbsp; /堂</span>
      </div>
      <el-input
        v-model="giftItem.remark"
        placeholder="請輸入贈與備註"
        type="textarea"
        :autosize="{ minRows: 5 }"
      />
      <div class="my-2 text-start">※如有欠費打卡紀錄，會優先補償</div>
      <el-button class="mt-3" type="success" @click="createClassRoomGift()"
        >確認</el-button
      >
    </div>
  </el-dialog>
  <el-dialog
    title="繳費紀錄"
    :width="$store.getters.mobileSize ? '90%' : '60%'"
    v-model="isChangePayGiftShow"
  >
    <el-table
      :data="changePayGiftItem.classRoomPaymentInfoList"
      style="width: 100%"
    >
      <el-table-column
        type="expand"
        width="30"
        v-if="$store.getters.mobileSize"
      >
        <template #default="props">
          <div class="my-3 mainContent">
            金額：
            <span class="content" v-html="props.row.payment"></span>
          </div>
          <div class="my-3 mainContent">
            堂數：
            <span class="content" v-html="props.row.classRoomNumber"></span>
          </div>
          <div class="my-3 mainContent">
            備註：
            <span class="content" v-html="props.row.remark"></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="狀態"
        :width="$store.getters.mobileSize ? '80' : '100'"
        sortable
        :sort-method="statusSort"
      >
        <template #default="scope">
          <span v-if="scope.row.isGift">贈與</span>
          <span v-else-if="scope.row.isModified">已修改</span>
          <span v-else>正常</span>
        </template>
      </el-table-column>
      <el-table-column
        label="日期"
        :width="$store.getters.mobileSize ? '100' : '200'"
        sortable
      >
        <template #default="scope">
          <span>{{ getDate(scope.row.paymentTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="payment"
        label="金額"
        width="100"
        sortable
        v-if="!$store.getters.mobileSize"
      />
      <el-table-column
        prop="classRoomNumber"
        label="堂數"
        width="100"
        sortable
        v-if="!$store.getters.mobileSize"
      />
      <el-table-column
        prop="remark"
        label="備註"
        width="300"
        sortable
        v-if="!$store.getters.mobileSize"
      />
      <el-table-column label="">
        <template v-slot="scope">
          <i
            v-if="setPermission(classRoomPermission)"
            class="fa-sharp fa-regular fa-pen-to-square me-3"
            style="cursor: pointer"
            @click="editPayGiftClick(scope.row)"
          ></i>
          <i
            v-if="setPermission(classRoomPermission)"
            class="fal fa-trash-alt trash"
            style="cursor: pointer"
            @click="deleteClassRoomPay(scope.row)"
          ></i>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
  <el-dialog
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="false"
    title="修改繳費紀錄"
    :width="$store.getters.mobileSize ? '90%' : '30%'"
    v-model="isEditPayGiftShow"
  >
    <div class="d-flex justify-content-center flex-column">
      <div class="d-flex align-items-center mb-2">
        <span>日期：</span>
        <el-date-picker
          class="mb-2"
          v-model="editPayGiftItem.paymentTime"
          type="datetime"
          placeholder="選擇日期與時間"
        />
      </div>
      <div
        class="d-flex align-items-center mb-2"
        v-if="!editPayGiftItem.isGift"
      >
        <span>金額：</span>
        <el-input
          class="w-75 flex-grow-1"
          v-model="editPayGiftItem.payment"
          @input="editPayGiftItem.payment = turnNumber(editPayGiftItem.payment)"
        ></el-input>
        <span>&nbsp; /元</span>
      </div>
      <div class="d-flex align-items-center mb-2">
        <span>堂數：</span>
        <el-input
          class="w-75 flex-grow-1"
          v-model="editPayGiftItem.classRoomNumber"
          @input="
            editPayGiftItem.classRoomNumber = turnNumber(
              editPayGiftItem.classRoomNumber
            )
          "
          placeholder="請輸入堂數"
        />
        <span>&nbsp; /堂</span>
      </div>
      <el-input
        v-model="editPayGiftItem.remark"
        placeholder="請輸入備註"
        type="textarea"
        :autosize="{ minRows: 5 }"
      />
      <div class="mt-3 d-flex">
        <el-button
          class="w-100"
          type="warning"
          @click="cancelClassRoomPay()"
          plain
          >取消</el-button
        >
        <el-button class="w-100" type="warning" @click="editClassRoomPay()"
          >確認</el-button
        >
      </div>
    </div>
  </el-dialog>
  <el-dialog
    title="所有打卡紀錄"
    :width="$store.getters.mobileSize ? '90%' : '60%'"
    v-model="isAllCheckRecordShow"
  >
    <el-table
      stripe
      max-height="500"
      :data="payCheckData.classRoomCheckInfoList"
      style="width: 100%"
    >
      <el-table-column prop="studentName" label="學生" sortable />
      <el-table-column
        label="補卡"
        sortable
        sort-by="isDueTime"
        :width="$store.getters.mobileSize ? '60' : ''"
      >
        <template #default="scope">
          <span>{{ scope.row.isDueTime ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="欠費"
        sortable
        sort-by="isOwe"
        :width="$store.getters.mobileSize ? '60' : ''"
      >
        <template #default="scope">
          <span>{{ scope.row.isOwe ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="checkedTime" label="日期" sortable />
    </el-table>
  </el-dialog>
  <el-dialog
    title="所有繳費紀錄"
    :width="$store.getters.mobileSize ? '90%' : '60%'"
    v-model="isAllPayRecordShow"
  >
    <el-table
      stripe
      max-height="500"
      :data="payCheckData.classRoomPaymentInfoList"
      style="width: 100%"
    >
      <el-table-column
        type="expand"
        width="30"
        v-if="$store.getters.mobileSize"
      >
        <template #default="props">
          <div class="my-3 mainContent">
            金額：
            <span class="content" v-html="props.row.payment"></span>
          </div>
          <div class="my-3 mainContent">
            堂數：
            <span class="content" v-html="props.row.classRoomNumber"></span>
          </div>
          <div class="my-3 mainContent">
            修改：
            <span class="content">{{
              props.row.isModified ? "是" : "否"
            }}</span>
          </div>
          <div class="my-3 mainContent">
            贈與：
            <span class="content">{{ props.row.isGift ? "是" : "否" }}</span>
          </div>
          <div class="my-3 mainContent">
            備註：
            <span class="content" v-html="props.row.remark"></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="studentName" label="學生" sortable />
      <el-table-column
        prop="payment"
        label="金額"
        width="120"
        sortable
        v-if="!$store.getters.mobileSize"
      />
      <el-table-column
        prop="classRoomNumber"
        label="堂數"
        width="100"
        sortable
        v-if="!$store.getters.mobileSize"
      />
      <el-table-column
        label="修改"
        width="80"
        sortable
        sort-by="isModified"
        v-if="!$store.getters.mobileSize"
      >
        <template #default="scope">
          <span>{{ scope.row.isModified ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="贈與"
        width="80"
        sortable
        sort-by="isGift"
        v-if="!$store.getters.mobileSize"
      >
        <template #default="scope">
          <span>{{ scope.row.isGift ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="備註"
        sortable
        width="200"
        v-if="!$store.getters.mobileSize"
      />
      <el-table-column prop="paymentTime" label="日期" sortable />
    </el-table>
  </el-dialog>
</template>

<script>
import Storage from "../../../../models/storage";
const dayjs = require("dayjs");

export default {
  name: "ClassRoomPayCheck",
  props: ["classRoomPermission"],
  data() {
    return {
      payCheckData: {
        classRoomPaymentInfoList: [],
        classRoomCheckInfoList: [],
        studentList: [],
      },
      classRoomTime: "",
      createPayObject: {
        payment: "",
        classRoomNumber: "",
      },
      checkItem: {
        studentId: "",
        checkedTime: "",
        isOnTime: true,
        isOwe: false,
      },
      payItem: {
        studentId: "",
        payment: 0,
        classRoomNumber: 0,
        isGift: false,
        remark: "",
      },
      giftItem: {
        studentId: "",
        payment: 0,
        classRoomNumber: 0,
        isGift: true,
        remark: "",
      },
      editPayGiftItem: {},
      editPayGiftItem_backup: {},
      changePayGiftItem: {},
      deleteCheckItem: {},
      selectAllStudent: true,
      assigneeListId: [],
      isCheckShow: false,
      isOweCheckShow: false,
      isPayShow: false,
      isGiftShow: false,
      isDeleteCheckShow: false,
      isChangePayGiftShow: false,
      isEditPayGiftShow: false,
      isAllCheckRecordShow: false,
      isAllPayRecordShow: false,
      tempClassRoomNumber: 0,
      isPayAll: false,
    };
  },
  async mounted() {
    const vm = this;

    // 預設全選學生
    this.timeout = setTimeout(() => {
      vm.assigneeListId = vm.studentOptions.map((x) => x.value);
    }, 1500);

    this.timeout = setTimeout(() => {
      const el = document.querySelectorAll(
        ".student_main .el-table__row .check  .cell "
      );
      el.forEach((x) => {
        x.scrollLeft = x.scrollWidth;
      });
    }, 1500);
  },
  computed: {
    displayData() {
      const vm = this;
      let result = vm.payCheckData.studentList.filter((x) =>
        vm.assigneeListId.includes(x.studentId)
      );
      return result;
    },
    studentOptions() {
      return this.$store.state.workItem.studentOptions;
    },
  },
  watch: {
 
    "$store.state.classRoomTabActiveName": function (newVal) {
      if (newVal == "fourth") {
        this.initData();
        this.setStudent();
      }
    },
  },
  created(){
    this.initData();
    this.setStudent();
  },
  methods: {
    setStudent() {
      if (this.selectAllStudent)
        this.assigneeListId = this.studentOptions.map((x) => x.value);
      else this.assigneeListId = [];
    },
    initData() {
      const vm = this;
      var isParent =
        Storage.get("classroom_role").role.toLowerCase() == "parent"
          ? true
          : false;
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/ClassRoom/PayCheckIn/${
          vm.$route.params.id
        }/${
          vm.setPermission(vm.classRoomPermission) ? 0 : vm.currentUser().id
        }?isParent=${isParent}`
      )
        .then((res) => {
          console.log(res.data);
          vm.payCheckData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAllCheckRecord() {
      await this.initData();
      this.isAllCheckRecordShow = true;
    },
    async getAllPayRecord() {
      await this.initData();
      this.isAllPayRecordShow = true;
    },
    addPayment(item) {
      if (item.length == 0) return 0;
      var result = 0;
      item.forEach((x) => {
        result += Number(x.classRoomNumber);
      });
      return result;
    },
    giftClick(item) {
      this.isGiftShow = true;
      this.giftItem.studentId = item.studentId;
    },
    createClassRoomGift() {
      const vm = this;
      if (vm.giftItem.classRoomNumber == 0) {
        vm.notify("error", `堂數不可為零`);
        return;
      }
      const data = {
        ClassRoomId: vm.$route.params.id,
        StudentId: vm.giftItem.studentId,
        Payment: vm.giftItem.payment,
        ClassRoomNumber: vm.giftItem.classRoomNumber,
        IsGift: vm.giftItem.isGift,
        Remark: vm.giftItem.remark,
      };
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/ClassRoom/PayCheckIn/Pay`,
        data
      )
        .then((res) => {
          console.log(res.data);
          vm.payCheckData.classRoomPaymentInfoList.push({
            id: res.data.classRoomPaymentInfoId,
            classRoomId: vm.$route.params.id,
            studentId: data.StudentId,
            paymentTime: res.data.paymentTime,
            payment: data.Payment,
            classRoomNumber: data.ClassRoomNumber,
            isModified: false,
            isGift: data.IsGift,
            remark: data.Remark,
          });
          vm.payCheckData.studentList
            .find((x) => x.studentId == data.StudentId)
            .classRoomPaymentInfoList.push({
              classRoomPaymentInfoId: res.data.classRoomPaymentInfoId,
              paymentTime: res.data.paymentTime,
              payment: data.Payment,
              classRoomNumber: data.ClassRoomNumber,
              isModified: false,
              isGift: data.IsGift,
              remark: data.Remark,
            });
          if (res.data.classRoomCheckInfoIdList.length != 0) {
            var temp = vm.payCheckData.studentList.find(
              (x) => x.studentId == data.StudentId
            ).classRoomCheckInList;
            temp.forEach((y) => {
              if (
                res.data.classRoomCheckInfoIdList.includes(
                  y.classRoomCheckInfoId
                )
              ) {
                y.isOwe = false;
              }
            });
          }
          vm.giftItem = {
            studentId: "",
            payment: 0,
            classRoomNumber: 0,
            isGift: true,
            remark: "",
          };
          vm.isGiftShow = false;
          vm.notify("success", `贈送成功！`);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `贈送失敗！`);
        });
    },
    payClick(item) {
      this.isPayAll = false;
      this.payItem.studentId = item.studentId;
      this.isPayShow = true;
    },
    payClickAll() {
      this.isPayAll = true;
      this.payItem.studentId = 0;
      this.isPayShow = true;
    },
    createClassRoomPay() {
      const vm = this;
      if (vm.payItem.classRoomNumber == 0) {
        vm.notify("error", `堂數不可為零`);
        return;
      }
      const data = {
        ClassRoomId: vm.$route.params.id,
        StudentId: vm.payItem.studentId,
        Payment: vm.payItem.payment,
        ClassRoomNumber: vm.payItem.classRoomNumber,
        IsGift: vm.payItem.isGift,
        Remark: vm.payItem.remark,
        IsPayAll: vm.isPayAll,
      };
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/ClassRoom/PayCheckIn/Pay`,
        data
      )
        .then((res) => {
          console.log(res.data);
          res.data.classRoomPaymentInfoList.forEach((x) => {
            vm.payCheckData.classRoomPaymentInfoList.push({
              id: x.id,
              classRoomId: vm.$route.params.id,
              studentId: x.studentId,
              paymentTime: res.data.paymentTime,
              payment: data.Payment,
              classRoomNumber: data.ClassRoomNumber,
              isModified: false,
              isGift: data.IsGift,
              remark: data.Remark,
            });

            vm.payCheckData.studentList
              .find((y) => y.studentId == x.studentId)
              .classRoomPaymentInfoList.push({
                classRoomPaymentInfoId: x.id,
                paymentTime: res.data.paymentTime,
                payment: data.Payment,
                classRoomNumber: data.ClassRoomNumber,
                isModified: false,
                isGift: data.IsGift,
                remark: data.Remark,
              });
          });

          if (res.data.classRoomCheckInfoIdList.length != 0) {
            vm.payCheckData.studentList.forEach((s) => {
              var temp = s.classRoomCheckInList;
              temp.forEach((y) => {
                if (
                  res.data.classRoomCheckInfoIdList.includes(
                    y.classRoomCheckInfoId
                  )
                ) {
                  y.isOwe = false;
                }
              });
            });
          }

          vm.payItem = {
            studentId: "",
            payment: 0,
            classRoomNumber: 0,
            isGift: false,
            remark: "",
          };
          vm.isPayAll = false;
          vm.isPayShow = false;
          vm.notify("success", `繳費成功！`);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `繳費失敗！`);
        });
    },
    changePayGiftClick(item) {
      this.isChangePayGiftShow = true;
      this.changePayGiftItem = item;
    },
    editPayGiftClick(item) {
      this.isEditPayGiftShow = true;
      this.editPayGiftItem = item;
      Object.assign(this.editPayGiftItem_backup, item);
    },
    editClassRoomPay() {
      const vm = this;
      if (
        vm.editPayGiftItem.paymentTime == "" ||
        vm.editPayGiftItem.paymentTime == null
      ) {
        vm.notify("error", `日期不可為空白`);
        return;
      }

      var hsaOwe = false;
      var tempData = vm.payCheckData.studentList.find(
        (x) => x.studentId == vm.changePayGiftItem.studentId
      ).classRoomCheckInList;
      tempData.forEach((y) => {
        if (y.isOwe) {
          hsaOwe = true;
        }
      });
      vm.changePayGiftItem.classRoomPaymentInfoList.find(
        (x) =>
          x.classRoomPaymentInfoId == vm.editPayGiftItem.classRoomPaymentInfoId
      ).classRoomNumber = vm.editPayGiftItem.classRoomNumber;

      if (
        !hsaOwe &&
        vm.changePayGiftItem.classRoomCheckInList.length >
          vm.addPayment(vm.changePayGiftItem.classRoomPaymentInfoList)
      ) {
        vm.notify("error", `無法變更堂數，請確認打卡堂數低於剩餘堂數！`);
        return;
      }
      if (
        hsaOwe &&
        vm.editPayGiftItem_backup.classRoomNumber >
          vm.editPayGiftItem.classRoomNumber
      ) {
        vm.notify("error", `無法變更堂數，尚有欠費打卡時，無法降低購買堂數！`);
        return;
      }

      const data = {
        ClassRoomId: vm.$route.params.id,
        ClassRoomPaymentInfoId: vm.editPayGiftItem.classRoomPaymentInfoId,
        PaymentTime: dayjs(vm.editPayGiftItem.paymentTime).add(8, "hour"),
        Payment: vm.editPayGiftItem.payment,
        ClassRoomNumber: vm.editPayGiftItem.classRoomNumber,
        IsModified: vm.editPayGiftItem.isGift ? false : true,
        IsGift: vm.editPayGiftItem.isGift,
        Remark: vm.editPayGiftItem.remark,
      };
      vm.fetchAPI(
        "put",
        `${process.env.VUE_APP_URL_API}/ClassRoom/PayCheckIn/Pay`,
        data
      )
        .then((res) => {
          console.log(res.data);

          if (res.data.length != 0) {
            var tempData = vm.payCheckData.studentList.find(
              (x) => x.studentId == vm.changePayGiftItem.studentId
            ).classRoomCheckInList;
            tempData.forEach((y) => {
              if (res.data.includes(y.classRoomCheckInfoId)) {
                y.isOwe = false;
              }
            });
          }

          var temp = vm.payCheckData.studentList.find(
            (x) => x.studentId == vm.changePayGiftItem.studentId
          );
          temp.classRoomPaymentInfoList.sort((a, b) => {
            return dayjs(a.paymentTime) - dayjs(b.paymentTime);
          });
          vm.isEditPayGiftShow = false;
          if (!vm.editPayGiftItem.isGift) vm.editPayGiftItem.isModified = true;
          vm.notify("success", `更新成功！`);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `更新失敗！`);
        });
    },
    cancelClassRoomPay() {
      const vm = this;
      var index = vm.changePayGiftItem.classRoomPaymentInfoList.findIndex(
        (x) =>
          x.classRoomPaymentInfoId ==
          vm.editPayGiftItem_backup.classRoomPaymentInfoId
      );
      Object.assign(
        vm.changePayGiftItem.classRoomPaymentInfoList[index],
        vm.editPayGiftItem_backup
      );
      this.isEditPayGiftShow = false;
    },
    deleteClassRoomPay(item) {
      const vm = this;
      if (
        vm.changePayGiftItem.classRoomCheckInList.length >
        vm.addPayment(vm.changePayGiftItem.classRoomPaymentInfoList) -
          item.classRoomNumber
      ) {
        vm.notify("error", `無法刪除，請確認打卡堂數低於剩餘堂數！`);
        return;
      }
      const data = {
        ClassRoomPaymentInfoId: item.classRoomPaymentInfoId,
      };
      vm.fetchAPI(
        "delete",
        `${process.env.VUE_APP_URL_API}/ClassRoom/PayCheckIn/Pay`,
        data
      )
        .then((res) => {
          console.log(res.data);
          var index = vm.payCheckData.studentList.findIndex(
            (x) => x.studentId == vm.changePayGiftItem.studentId
          );
          vm.payCheckData.studentList[index].classRoomPaymentInfoList =
            vm.payCheckData.studentList[index].classRoomPaymentInfoList.filter(
              (x) => x.classRoomPaymentInfoId != data.ClassRoomPaymentInfoId
            );
          vm.notify("success", `刪除成功！`);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `刪除繳費失敗！`);
        });
    },
    checkClick(item) {
      if (
        this.addPayment(item.classRoomPaymentInfoList) -
          item.classRoomCheckInList.length <=
        0
      ) {
        this.notify("error", `無法打卡，請確認是否還有剩餘堂數！`);
        return;
      }
      this.isCheckShow = true;
      this.checkItem.studentId = item.studentId;
    },
    oweCheckClick(item) {
      if (
        this.addPayment(item.classRoomPaymentInfoList) !=
          item.classRoomCheckInList.length &&
        this.addPayment(item.classRoomPaymentInfoList) -
          item.classRoomCheckInList.length >
          0
      ) {
        this.notify("error", `無法使用欠費打卡功能，尚有剩餘堂數`);
        return;
      }
      this.isOweCheckShow = true;
      this.checkItem.studentId = item.studentId;
    },
    cancelOweCheck() {
      this.isOweCheckShow = false;
      this.checkItem = {
        studentId: "",
        checkedTime: "",
        isOnTime: true,
        isOwe: false,
      };
    },
    createClassRoomCheckIn(isOwe = false) {
      const vm = this;
      if (isOwe == false) {
        if (
          !vm.checkItem.isOnTime &&
          (vm.checkItem.checkedTime == "" || vm.checkItem.checkedTime == null)
        ) {
          vm.notify("warning", `請先選擇打卡時間！`);
          return;
        }
      }
      var day = dayjs();
      const data = {
        ClassRoomId: vm.$route.params.id,
        StudentId: vm.checkItem.studentId,
        CheckedTime: !vm.checkItem.isOnTime
          ? dayjs(vm.checkItem.checkedTime).add(8, "hour")
          : day.add(8, "hour"),
        IsDueTime: !vm.checkItem.isOnTime,
        IsOwe: isOwe,
      };
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/ClassRoom/PayCheckIn/CheckIn`,
        data
      )
        .then((res) => {
          console.log(res.data);
          var temp = vm.payCheckData.studentList.find(
            (x) => x.studentId == data.StudentId
          );
          temp.classRoomCheckInList.push({
            classRoomCheckInfoId: res.data.classRoomCheckInfoId,
            checked: true,
            checkedTime: !vm.checkItem.isOnTime
              ? dayjs(vm.checkItem.checkedTime).format("YYYY/MM/DD HH:mm:ss")
              : day.format("YYYY/MM/DD HH:mm:ss"),
            isDueTime: data.IsDueTime,
            isOwe: data.IsOwe,
          });
          temp.classRoomCheckInList.sort((a, b) => {
            return dayjs(a.checkedTime) - dayjs(b.checkedTime);
          });
          vm.checkItem = {
            studentId: "",
            checkedTime: "",
            isOnTime: true,
            isOwe: false,
          };
          this.timeout = setTimeout(() => {
            const el = document.querySelectorAll(
              ".student_main .el-table__row .check  .cell "
            );
            el.forEach((x) => {
              x.scrollLeft = x.scrollWidth;
            });
          }, 500);
          vm.isCheckShow = false;
          vm.isOweCheckShow = false;
          vm.notify("success", `打卡成功！`);
        })
        .catch((err) => {
          vm.notify("error", `打卡失敗，${err.response.data.Msg}!`);
        });
    },
    deleteCheckClick(item) {
      this.isDeleteCheckShow = true;
      this.deleteCheckItem = item;
    },
    deleteClassRoomCheckIn(item) {
      const vm = this;
      var temp = vm.payCheckData.studentList.find(
        (x) => x.studentId == vm.deleteCheckItem.studentId
      );
      var isOwe = false;
      temp.classRoomCheckInList.forEach((x) => {
        if (x.isOwe && x.classRoomCheckInfoId != item.classRoomCheckInfoId) {
          isOwe = true;
          return;
        }
      });
      if (isOwe) {
        vm.notify("warning", `尚有欠費打卡紀錄，不能刪除一般打卡！`);
        return;
      }
      const data = {
        ClassRoomCheckInfoId: item.classRoomCheckInfoId,
      };
      console.log(item);
      vm.fetchAPI(
        "delete",
        `${process.env.VUE_APP_URL_API}/ClassRoom/PayCheckIn/CheckIn`,
        data
      )
        .then((res) => {
          console.log(res.data);
          var index = vm.payCheckData.studentList.findIndex(
            (x) => x.studentId == vm.deleteCheckItem.studentId
          );
          vm.payCheckData.studentList[index].classRoomCheckInList =
            vm.payCheckData.studentList[index].classRoomCheckInList.filter(
              (x) => x.classRoomCheckInfoId != data.ClassRoomCheckInfoId
            );
          vm.notify("success", `刪除打卡成功！`);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `刪除打卡失敗！`);
        });
    },
    getDate(item) {
      return dayjs(item).format("YYYY/MM/DD HH:mm:ss");
    },
    statusSort(a, b) {
      if (a.isGift) a = 1;
      else if (a.isModified) a = 2;
      else a = 3;

      if (b.isGift) b = 1;
      else if (b.isModified) b = 2;
      else b = 3;

      return a - b;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn_group {
  flex-wrap: wrap;
}
:deep .el-table {
  .checkIn_btn {
    padding: 1rem;
  }
  thead .cell {
    text-align: center !important;
    justify-content: center;
  }
  td {
    text-align: center !important;
  }
  tbody .check {
    .cell {
      display: flex;
      overflow: auto;
      margin: 0.5rem 1rem;
      padding: 0 0 0.5rem;
      text-align: center;
      .student_check {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-width: 8rem;
        margin-right: 1rem;
        padding: 0.7rem 0;
        border-radius: 1rem;
        transition: 0.2s;
        cursor: default;
        background-color: #e8e8e8;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          background-color: #cac8c8e0;
        }
        &.no_content {
          border: none;
          text-align: start;
          background: none;
          min-width: 0;
          padding: 0;
          &:hover {
            background-color: none;
          }
        }
        &.due {
          background-color: #f9d8b7;
          &:hover {
            background-color: #ebc7a2;
          }
        }
        &.owe {
          background-color: #f9b7b7;
          &:hover {
            background-color: #f49e9e;
          }
        }
        .dueTime {
          color: #fe7f00;
          margin-left: 0.5rem;
          font-size: 1rem;
        }
        .owe {
          color: red;
          margin-left: 0.5rem;
          font-size: 1rem;
        }
      }
    }
  }
}
.mainContent {
  margin-left: 2.5rem;
  margin-right: 0.5rem;
  .content {
    padding-top: 0.5rem;
    word-wrap: break-word;
    line-height: 1.5;
  }
}
@media screen and (max-width: 768px) {
  .btn_group {
    margin-bottom: 1rem !important;
    .select {
      margin-bottom: 0.8rem;
    }
  }
}
</style>
